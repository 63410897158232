export default {
  // client config
  domain: process.env.VUE_APP_AUTH0_AUTHORITY_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  // login defaults
  authorizationParams: {
    audience: '',
    redirect_uri: window.location.origin,
    scope: ['openid'],
    screen_hint: 'signin'
  }
}

export const loginOptions = {
  // pass through state to app
  appState: {
    targetURL: window.location.origin
  },
  authorizationParams: {
    // key: value - passed into authorization server
    connection: 'facebook',
    id_token_hint: '',
    login_hint: '',
    prompt: 'select_account',
    redirect_uri: '',
    scope: ['profile', 'email'],
    ui_locales: 'en fr'
  },
  async openUrl(url) {
    window.location.replace(url)
  }
}

export const logoutOptions = {
  clientId: '1234', // auth0 redirect URIs at app (vs account) level
  logoutParams: {
    federated: true,
    returnTo: window.location.origin,
    any_custom_property: 'value'
  },
  async openUrl(url) {
    window.location.replace(url)
  }
}
