<template>
  <v-card>
    <v-card-text class="c-widget d-flex pa-2">
      <div
        class="c-thumbnail c-clickable pa-2"
        @click="viewReference(reference)"
      >
        <v-img
          :aspect-ratio="thumbnailAspectRatio"
          contain
          :src="reference.thumbnail"
        />
      </div>

      <div>
        <v-card-title class="px-2 py-0 text-h6 text-sm-h4 text-left">
          {{ reference.title }}
        </v-card-title>

        <v-card-actions class="c-actions">
          <v-btn color="primary" @click="viewReference(reference)">
            {{ $t('quiz.action.view') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import viewMixin from '@/mixins/viewMixin.js'

export default {
  name: 'ReferenceCard',

  components: {},

  mixins: [viewMixin],

  props: {
    reference: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailAspectRatio: 4 / 3
    }
  },

  methods: {
    viewReference(parent) {
      this.viewMixin_viewReference(parent)
    }
  }
}
</script>

<style lang="css" scoped>
.c-widget {
  background-color: var(--v-background-base);
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 0 2pt var(--v-outline-base);
  border-radius: 16px;
  /* outline: 1px solid var(--v-outline-base); */
}

.c-thumbnail {
  width: 200px;
  min-width: 100px;
  height: fit-content;
  background-color: black;
}

.c-actions {
  display: flex;
  justify-content: flex-start;
}
.c-clickable {
  cursor: pointer;
}
</style>
