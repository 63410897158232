<template>
  <v-list rounded>
    <v-subheader class="c-header">
      {{ $t('model.scenes').toUpperCase() }}
    </v-subheader>
    <v-list-item-group
      v-model="selectedSceneId"
      class="c-list-item-group px-2 pb-2"
      active-class="c-list-item--active"
      mandatory
      @change="$emit('sync', selectedSceneId)"
    >
      <template v-for="(scene, index) in scenes">
        <v-subheader
          v-if="scene.header"
          :key="scene[locale].name"
          class="c-subheader"
          @click.stop
        >
          {{ scene[locale].name.toUpperCase() }}
        </v-subheader>
        <v-divider
          v-else-if="scene.divider"
          :key="index"
          class="c-divider mb-1 mt-3"
          @click.stop
        />
        <v-list-item
          v-else
          :key="scene.id"
          dense
          :value="scene.id"
          @click.stop="$emit('select', scene.id)"
        >
          <v-list-item-icon v-if="scene.icon">
            <v-icon>{{ scene.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ scene[locale].name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'SceneList',

  components: {},

  model: {
    prop: 'id',
    event: 'select'
  },

  props: {
    id: {
      type: String,
      required: true
    },

    scenes: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      selectedSceneId: ''
    }
  },

  computed: {
    firstScene() {
      return this.scenes.find((scene) => scene.id)
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  watch: {
    id: {
      immediate: false,
      handler: function (newId, _oldId) {
        if (newId) this.selectedSceneId = newId
      }
    }
  },

  created: function () {
    this.selectedSceneId = this.id || this.firstScene?.id || ''
  }
}
</script>

<style lang="css" scoped>
.c-header {
  font-weight: 600;
  height: 24px;
}

.c-subheader {
  color: var(--v-accent-base);
  font-style: italic;
  height: 28px;
  margin-bottom: -4px;
}

.c-divider {
}
.c-divider.theme--dark {
  border-color: rgba(255, 255, 255, 0.33);
}
.c-divider.theme--light {
  border-color: rgba(0, 0, 0, 0.25);
}
.c-list-item-group.theme--light {
  background-color: white;
}
.c-list-item-group.theme--dark {
  background-color: black;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
