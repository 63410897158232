<template>
  <v-btn v-if="showStatus" class="c-noclick" fab small>
    <v-icon color="accent">
      {{ online ? 'mdi-wifi' : 'mdi-wifi-off' }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'AppOnlineStatus',

  components: {},

  props: {
    offlineOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    online() {
      return this.$store.state.appStore.online
    },

    showStatus() {
      return (this.online && !this.offlineOnly) || !this.online
    }
  }
}
</script>

<style scoped lang="scss">
.c-noclick {
  pointer-events: none;
}
</style>
