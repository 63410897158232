import CryptoService from '@/services/cryptoService'
import { mapActions } from 'vuex'

const cryptoService = new CryptoService()
const SHARE_PREFIX = 'share-'

export default {
  computed: {
    shareMixin_locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  methods: {
    ...mapActions('i18nStore', ['updateLocale']),

    shareMixin_prepId(id) {
      const newId = id + `?l=${this.shareMixin_locale}`
      return SHARE_PREFIX + cryptoService.encrypt(newId)
    },

    shareMixin_extractId(sharedLink) {
      const parseQuery = (queryString) => {
        let params = {}
        const pairs = queryString?.split('&') || []
        for (const pair of pairs) {
          const param = pair.split('=')
          params[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || '')
        }
        return params
      }

      // decrypt the link
      const link = sharedLink.startsWith(SHARE_PREFIX)
        ? cryptoService.decrypt(sharedLink.replace(SHARE_PREFIX, ''))
        : sharedLink
      const [id, queryString] = link.split('?')

      // process query params
      if (queryString) {
        const params = parseQuery(queryString)
        const locale = params['l']
        if (locale) this.updateLocale({ locale, vm: this })
      }

      // return the decrypted id
      return id
    },

    async shareMixin_shareItem(item) {
      return this.shareMixin_shareEntity({
        id: item.id,
        abstract: item.abstract,
        title: item.title,
        routeName: 'player'
      })
    },

    async shareMixin_sharePost(post) {
      return this.shareMixin_shareEntity({
        id: post.id,
        abstract: post.excerpt,
        title: post.title,
        routeName: 'post'
      })
    },

    async shareMixin_shareChannel(channel) {
      return this.shareMixin_shareEntity({
        id: channel.slug,
        abstract: channel.abstract,
        title: channel.title,
        routeName: 'channel'
      })
    },

    async shareMixin_shareEntity({ id, abstract, title, routeName }) {
      const shareableId = this.shareMixin_prepId(id)
      if (typeof window !== 'undefined' && window.navigator.share) {
        try {
          await navigator.share({
            title: title,
            url: `${window.location.origin}/${routeName}/${shareableId}`,
            text: abstract
          })
        } catch (error) {
          console.error(error)
        }
        return null
      } else {
        return { id, abstract, title, routeName }
      }
    }
  }
}
