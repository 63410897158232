<template>
  <SectionPanel
    :colour="colour"
    :title="title"
    :count="postCount"
    :loading="loading"
  >
    <template #toolbar>
      <LayoutToolbar
        v-model="postLayout"
        :items="posts"
        :filters="postFilters"
        :layouts="layouts"
        :sort="postSortOptionName"
        :sorts="postExtendedSortOptions"
        @results="postResultSet = $event"
        @update:layout="updatePreferences({ favouritePostsLayout: $event })"
        @update:sort="updatePreferences({ favouritePostsSort: $event })"
      />
    </template>
    <CardGrid
      v-if="postLayout === 'grid'"
      :items="loading ? skeletonPosts : postResultSet"
    >
      <template #card="props">
        <DraggableNode
          :active="arePostsDraggable"
          drag-type="post"
          :identifier="props.item.id"
          @drop="movePost({ from: $event.dragId, after: $event.dropId })"
        >
          <PostCardSkeleton v-if="loading" />
          <PostCard
            v-else
            :post="props.item"
            @share="$emit('share', props.item)"
            @view:post="$emit('view:post', props.item)"
          />
        </DraggableNode>
      </template>
    </CardGrid>
    <CardCarousel
      v-else
      title="home.posts"
      :items="loading ? skeletonPosts : postResultSet"
      :card-width="300"
    >
      <template #card="props">
        <PostCardSkeleton v-if="loading" />
        <PostCard
          v-else
          :post="props.item"
          @share="$emit('share', props.item)"
          @view:post="$emit('view:post', props.item)"
        />
      </template>
    </CardCarousel>
  </SectionPanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// post components
import PostCard from '@/components/post/PostCard'
import PostCardSkeleton from '@/components/post/PostCardSkeleton'

// base components
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import DraggableNode from '@/components/base/DraggableNode'
import SectionPanel from '@/components/base/SectionPanel'
import LayoutToolbar from '@/components/base/LayoutToolbar'

import mobileMixin from '@/mixins/mobileMixin.js'
import postMixin from '@/components/post/postMixin.js'
import { dummyPosts } from '@/components/post/dummyPosts.js'

export default {
  name: 'PostPanel',

  components: {
    // post components
    PostCard,
    PostCardSkeleton,
    // base components
    CardCarousel,
    CardGrid,
    DraggableNode,
    LayoutToolbar,
    SectionPanel
  },

  mixins: [mobileMixin, postMixin],

  props: {
    posts: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['carousel']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      postResultSet: [],
      postLayout: 'carousel',
      skeletonPosts: dummyPosts().slice(0, 4)
    }
  },

  computed: {
    ...mapGetters('userStore', ['getPreference']),

    arePostsDraggable() {
      // only allow dragging if all favourites are visible (unfiltered or excluded via search)
      // and the sort order is "as is"
      return (
        this.postResultSet.length === this.posts.length && this.postSortOptionName === 'sortByAsIs'
      )
    },

    postCount() {
      return this.postResultSet.length
    },

    postFilters() {
      return this.postMixin_filters
    },

    postExtendedSortOptions() {
      return [
        ...this.postMixin_sorts,
        {
          text: this.$t('ui.sortOptions.sortByAsIs'),
          value: 'sortByAsIs'
        }
      ]
    },

    postSortOptionName() {
      return this.getPreference('favouritePostsSort')
    }
  },

  created: function () {
    this.postLayout = this.mobileMixin_isReallyMobile
      ? 'carousel'
      : this.getPreference('favouritePostsLayout')
  },

  methods: {
    ...mapActions('userStore', ['updatePreferences', 'movePost'])
  }
}
</script>

<style lang="css" scoped></style>
