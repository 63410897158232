var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { staticClass: "c-post-body" },
    [
      _c("h1", { staticClass: "c-title" }, [_vm._v(_vm._s(_vm.post.title))]),
      _c("PostPrologue", { attrs: { post: _vm.post } }),
      _c("div", { staticClass: "c-excerpt mt-4 mb-6" }, [
        _c("i", [_vm._v(_vm._s(_vm.post.excerpt))]),
      ]),
      _c(
        "v-list",
        { attrs: { "three-line": "", subheader: "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("post.postedOn")) +
                    " " +
                    _vm._s(_vm._f("date")(_vm.post.publishedAt)) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _vm.isBlog
            ? _c(
                "v-row",
                {
                  staticClass: "c-hero text-center mx-0 my-2",
                  attrs: { justify: "center" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      "aspect-ratio": 16 / 9,
                      "max-height": "450px",
                      src: _vm.heroSrc,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item-content",
            { staticClass: "c-content" },
            [
              _c("RichText", {
                staticClass: "c-blocks",
                attrs: { blocks: _vm.postBlocks },
              }),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            { staticClass: "c-author d-flex" },
            [
              _c("PersonBanner", { attrs: { person: _vm.post.author } }),
              _c("RichText", { attrs: { blocks: _vm.bioBlocks } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.post.tags
        ? _c(
            "v-list",
            { attrs: { "three-line": "", subheader: "" } },
            [
              _c("v-subheader", [_vm._v(_vm._s(_vm.$t("post.tags")))]),
              _c("TagList", { attrs: { tags: _vm.post.tags } }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }