var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showInlineAd,
          expression: "showInlineAd",
        },
      ],
      staticClass: "c-inline-ad mx-auto my-4",
    },
    [
      _c("AdSpot2", {
        staticClass: "c-inline-block",
        attrs: { max: 1, stack: "", zone: "inline" },
        on: {
          count: function ($event) {
            return _vm.onCount($event)
          },
        },
        model: {
          value: _vm.showInlineAd,
          callback: function ($$v) {
            _vm.showInlineAd = $$v
          },
          expression: "showInlineAd",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }