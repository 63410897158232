import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const projectId = process.env.VUE_APP_SANITY_PROJECT_ID || '7v37i07r'

const client = sanityClient({
  apiVersion: '2022-03-25',
  dataset: 'production',
  // FIXME: get projectId from tenant via authService
  projectId: projectId,
  useCdn: true
})

const builder = imageUrlBuilder(client)

export function getURL(source) {
  return builder.image(source).url()
}

export function getImage(source) {
  return builder.image(source)
}
