<template>
  <div class="c-app-install">
    <v-btn v-if="showInstallButton" class="ml-2" fab small @click="onInstall">
      <v-icon color="accent"> mdi-tray-arrow-down </v-icon>
    </v-btn>
    <AppInstallPopup
      v-if="showInstallPopup"
      v-model="showInstallPopup"
      :device="iosDeviceType"
      @cancel="showInstallPopup = $event"
    />
  </div>
</template>

<script>
import AppInstallPopup from '@/components/app/AppInstallPopup'
import mobileMixin from '@/mixins/mobileMixin.js'

class InstallError extends Error {
  constructor(message, options = {}) {
    super(message, options)
    this.name = 'InstallError'
  }
}

export default {
  name: 'AppInstall',

  components: {
    AppInstallPopup
  },

  mixins: [mobileMixin],

  data() {
    return {
      // installation
      showInstallButton: false,
      showInstallPopup: false,
      installEvent: null,
      isInstalled: false
    }
  },

  computed: {
    iosDeviceType() {
      if (this.mobileMixin_isiPad) return 'iPad'
      if (this.mobileMixin_isiPhone) return 'iPhone'
      return ''
    },

    isManualInstall() {
      // !(this.mobileMixin__isSafari && this.mobileMixin_isMac)
      const iosVersion = this.mobileMixin_iOSVersion
      console.warn('[AppInstall]: iOS Version=', iosVersion)
      console.warn('[AppInstall]: userAgent=', window.navigator.userAgent)
      if (this.mobileMixin_isiOS) {
        return (
          (this.mobileMixin_isSafari && iosVersion >= 15.0) ||
          (this.mobileMixin_isChromeOrEdge && iosVersion >= 16.4)
        )
      } else {
        return false
      }
    }
  },

  watch: {
    isInstalled: {
      immediate: true,
      handler: function (newInstalled, _oldInstalled) {
        if (newInstalled) this.showInstallButton = false
      }
    }
  },

  mounted: function () {
    // from browser (only supported by Chrome and Edge)
    document.addEventListener('CustomBeforeInstallPrompt', this.onBeforeInstall)
    document.addEventListener('CustomAppInstalled', this.onInstalled)

    // determine whether running as a PWA
    window
      .matchMedia('(display-mode: minimal-ui)')
      .addEventListener('change', this.onDisplayModeChange)
    window
      .matchMedia('(display-mode: standalone)')
      .addEventListener('change', this.onDisplayModeChange)

    this.isInstalled = this.checkInstallation()

    if (this.isManualInstall && !this.isInstalled) this.showInstallButton = true
  },

  beforeDestroy: function () {
    document.removeEventListener('CustomBeforeInstallPrompt', this.onBeforeInstall)
    document.removeEventListener('CustomAppInstalled', this.onInstalled)

    window
      .matchMedia('(display-mode: minimal-ui)')
      .removeEventListener('change', this.onDisplayModeChange)
    window
      .matchMedia('(display-mode: standalone)')
      .removeEventListener('change', this.onDisplayModeChange)
  },

  methods: {
    /* installation detection methods */

    checkInstallation() {
      return (
        window.navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches
      )
    },

    onDisplayModeChange(e) {
      if (e.matches) {
        this.isInstalled = true
        this.showInstallButton = false
      }
    },

    /* installation methods */

    onBeforeInstall(e) {
      console.warn('[AppInstall]: onBeforeInstallPrompt', e)
      this.installEvent = e.detail
      this.showInstallButton = true
    },

    async onInstall(_e) {
      if (this.isManualInstall) {
        this.showInstallPopup = true
      } else {
        try {
          // invoke the deferred install event
          this.installEvent.prompt()

          // retrieve prompt outcome
          const { outcome } = await this.installEvent.userChoice

          if (outcome === 'accepted') {
            // event can only be processed once
            this.installEvent = null
            this.showInstallButton = false
          }
        } catch (error) {
          this.$error(new InstallError(error.message))
        }
      }
    },

    async onInstalled(_e) {
      // installButton.style.display = 'none'
      this.isInstalled = true
      console.warn('[AppInstall]: App installed!')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
