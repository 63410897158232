var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-video-player" }, [
    _c(
      "video",
      {
        staticClass: "c-video",
        attrs: {
          controlsList: "nodownload noremoteplayback",
          controls: "",
          poster: _vm.poster,
        },
      },
      [
        _c("source", { attrs: { src: _vm.url, type: "video/mp4" } }),
        _vm._l(_vm.tracks, function (track) {
          return _c("track", {
            key: track.kind + "." + track.locale,
            attrs: {
              src: track.url,
              kind: track.kind,
              srclang: track.locale,
              label: _vm.locales[track.locale].name,
            },
          })
        }),
        _vm._v(" " + _vm._s(_vm.$t("notice.browser.videoNotSupported")) + " "),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }