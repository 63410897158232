import store from '@/store'
import headers from './headers.json'

export const fetchPersons = async ({ filter }) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const encodedFilter = filter && encodeURIComponent(filter)
  const filterQueryString = filter ? `?filter=${encodedFilter}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/persons${filterQueryString}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[personService]:', error)
    return []
  }
}

export const getPersonHeaders = () => {
  return JSON.parse(JSON.stringify(headers))
}

export const fetchPerson = async (personId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/person/${personId}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[personService]:', error)
    return {}
  }
}
