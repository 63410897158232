var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-stack d-flex flex-column justify-start align-center pb-2",
      class: _vm.stackClass,
    },
    [
      _vm.header
        ? _c(
            "WidgetHeader",
            {
              attrs: { fill: "", rounded: false },
              on: {
                close: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _vm._l(_vm.ads, function (ad, index) {
        return _c(
          "div",
          {
            key: ad.id,
            staticClass: "c-ad-slot",
            class: {
              "mb-4": index < _vm.ads.length - 1,
              "c-cta": ad.adItem?.cta,
            },
          },
          [_vm._t("ad", null, { ad: ad, index: index })],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }