import modules from './modules'
import { hydrateState } from '@/store/persistence/stateManager.js'

export default {
  async restoreState({ state, dispatch }, { vm }) {
    // restore the session state; otherwise rely on other store modules to set the state
    console.info('[store]: Restoring module states...')
    dispatch(`appStore/reportState`)

    const restorePromises = Object.keys(modules).map(async (moduleName) => {
      const moduleStorage = state[moduleName].storage || 'local'
      const moduleState = await hydrateState(moduleName, moduleStorage)
      // console.debug(`[store]: Session state for ${moduleName}=`, moduleState)

      // only update the module store if previous session data exists
      if (Object.keys(moduleState).length > 0) {
        dispatch(`${moduleName}/restoreState`, {
          sessionState: { ...moduleState },
          vm
        })
      }
    })

    await Promise.all(restorePromises)

    console.info('[store]: State restoration actions complete!')
  },

  async reflectOrg({ state, dispatch }, { org, vm }) {
    // set locale (do this first!)
    const locale = org.locales.includes(state.i18nStore.locale)
      ? state.i18nStore.locale
      : org.locales[0]
    dispatch('i18nStore/updateLocale', { locale, vm })

    // set portal
    const activePortalKey = state.tenantStore.portalKey
    const portals = org.portals || []
    await dispatch('portalStore/updatePortals', {
      portals,
      activePortalKey
    })

    // set content (note: content depends on portal!)
    const collections = org.collections
    const taxonomyTitles = org.taxonomyTitles
    dispatch('contentStore/updateCollections', { collections })
    dispatch('contentStore/updateTaxonomyTitles', { taxonomyTitles })

    // set menu (note: menu depends on collections!)
    const menuItems = org.menuItems
    const indexMenuItem = org.menuItems.find((menuItem) => menuItem.index)
    const indexRoute = indexMenuItem ? { name: indexMenuItem } : { name: 'home' }
    dispatch('menuStore/updateMenu', { menuItems })
    dispatch('menuStore/updateIndexRoute', { route: indexRoute })

    // set visual elements
    const assetRoot = org.assetRoot || `/${state.tenantStore.tenantKey}`
    const gtmId = org.gtmId || ''
    const orgInfo = {
      orgLink: org.orgInfo?.orgLink || '',
      orgLogo: org.orgInfo?.orgLogo || '/img/logos/default-logo.png',
      orgName: org.orgInfo?.orgName || '<Missing>',
      about: org.orgInfo?.about || '<Missing>',
      contact: org.orgInfo?.contact || '<Missing>',
      disclaimer: org.orgInfo?.disclaimer || '<Missing>',
      privacy: org.orgInfo?.privacy || '<Missing>',
      tou: org.orgInfo?.tou || '<Missing>'
    }
    dispatch('orgStore/updateOrg', { assetRoot, gtmId, orgInfo })
    dispatch('themeStore/updateBranding', { brandTheme: org.theme, vm: this.$app })
  },

  reflectUser({ dispatch }, { user, vm }) {
    const locale = user.locale
    dispatch('userStore/updateUser', { user })
    if (locale) {
      dispatch('i18nStore/updateLocale', { locale, vm })
    }
  }
}
