<template>
  <a class="c-link" :href="href" target="_blank" rel="noopener noreferrer">
    <v-img
      class="c-image"
      :src="src"
      :alt="alt"
      contain
    />
  </a>
</template>

<script>
import { getURL } from '@/config/sanityConfig'

/* end of FIXME */

export default {
  name: 'ClickableImageBlock',

  components: {},

  props: {
    asset: {
      type: Object,
      required: true
    },

    alt: {
      type: String,
      required: false,
      default: ''
    },

    href: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    src() {
      return getURL(this.asset)
    }
  }
}
</script>

<style lang="css" scoped>
.c-link {
  height: auto;
  width: 100%;
}
.c-image {
  height: auto;
  width: 100%;
}
</style>
