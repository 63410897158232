const random = (a, b) => Math.floor((b - a + 1) * Math.random()) + a

const types = [
  // business
  'event',
  'conference',
  'meeting',
  'travel',

  // personal
  'birthday',
  'holiday',
  'party',
  'pto'
]

// generate a set of random entries
export default (start, end) => {
  const events = []

  const min = new Date(`${start}T00:00:00`)
  const max = new Date(`${end}T23:59:59`)
  const days = (max.getTime() - min.getTime()) / (60 * 60 * 24 * 1000)
  const eventCount = random(days, days + 20)

  for (let i = 0; i < eventCount; i++) {
    const allDay = random(0, 3) === 0
    const firstTimestamp = random(min.getTime(), max.getTime())
    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    const secondTimestamp = random(2, allDay ? 288 : 8) * 900000
    const second = new Date(first.getTime() + secondTimestamp)
    const eventType = types[random(0, types.length - 1)]

    events.push({
      type: eventType,
      name: 'Translate Me',
      start: first,
      end: second,
      timed: !allDay
    })
  }

  return events
}
