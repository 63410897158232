<template>
  <v-dialog
    v-model="showModal"
    content-class="c-modal"
    overlay-opacity="0.75"
    transition="dialog-top-transition"
    width="75%"
    @keydown.esc="closeModal()"
  >
    <v-card class="c-modal-content">
      <iframe id="popup" :src="src" />
    </v-card>
    <v-btn class="c-btn-close" icon @click="closeModal()">
      <v-icon x-large> mdi-close </v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
export default {
  name: 'AdModal',

  components: {},

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    src: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      showModal: this.show
    }
  },

  methods: {
    closeModal() {
      this.showModal = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>

<style lang="css">
.c-modal {
  background: var(--v-background-base);
  height: 75%;
  overscroll-behavior: contain;
  overflow-y: auto; /* enables overscroll setting */
}
.c-modal-content {
  width: 100%;
  height: 100%;
}
</style>
