var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "c-card d-flex flex-column align-center justify-center pa-4",
    },
    [
      _c("PersonAvatar", {
        attrs: {
          clickable: "",
          colour: "secondary",
          person: _vm.person,
          size: _vm.avatarSize,
        },
        on: {
          click: function ($event) {
            _vm.revealBack = true
          },
        },
      }),
      _c("v-card-text", { staticClass: "text-center py-2 pb-0" }, [
        _vm._v(" " + _vm._s(_vm.personName) + " "),
      ]),
      _vm.person.headline
        ? _c("v-card-text", { staticClass: "text-center py-2 pb-0" }, [
            _vm._v(
              " " +
                _vm._s(_vm.person.headline && _vm.person.headline[_vm.locale]) +
                " "
            ),
          ])
        : _vm._e(),
      _c("v-spacer"),
      _vm.showContactInfo
        ? _c(
            "v-card-actions",
            { staticClass: "py-5" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      ;`mailto:${_vm.person.email}`
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "accent", size: "60" } }, [
                    _vm._v(" mdi-email "),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", href: _vm.person.linkedIn } },
                [
                  _c("v-icon", { attrs: { color: "#0072b1", size: "60" } }, [
                    _vm._v(" mdi-linkedin "),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-scroll-x-reverse-transition",
        [
          _c("PersonCardBack", {
            attrs: { person: _vm.person },
            model: {
              value: _vm.revealBack,
              callback: function ($$v) {
                _vm.revealBack = $$v
              },
              expression: "revealBack",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }