import store from '@/store'
import { NotFoundError } from '@/services/errors.js'

export const fetchUserTenants = async (id) => {
  const userId = id.toLowerCase()
  try {
    const response = await fetch(`/api/user/${userId}/tenants`)
    if (response.ok) {
      return response.json()
    } else if (response.status === 404) {
      return new NotFoundError(response.statusText)
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[userService]:', error)
    return []
  }
}

export const fetchUser = async (id) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const userId = id.toLowerCase()
  try {
    console.debug(`[userService]: Fetching ${userId} in tenant ${tenantKey}.`)
    const response = await fetch(`/api/tenant/${tenantKey}/user/${userId}`)
    if (response.ok) {
      const user = await response.json()
      return user
    } else {
      return null
      // throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[userService]: Unable to fetch user.', error)
    throw error
  }
}

export const addUser = async (user) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const userCopy = { ...user }
  userCopy.userId = user.userId.toLowerCase()
  try {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...userCopy })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/user`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[userService]:', error)
    return {
      message: error,
      added: 0,
      id: -1
    }
  }
}

export const updateUser = async (user) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const userCopy = { ...user }
  userCopy.userId = user.userId.toLowerCase()
  try {
    const request = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...userCopy })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/user`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[userService]:', error)
    return {
      message: error,
      updated: 0,
      user: {}
    }
  }
}

export const verifyUser = async (id) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const userId = id.toLowerCase()
  try {
    console.debug(`[userService]: Verifying ${userId} in tenant ${tenantKey}.`)
    const response = await fetch(`/api/tenant/${tenantKey}/user/${userId}?verify=true`)
    if (response.ok) {
      const body = await response.json()
      return body.exists
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[userService]: Unable to verify user.', error)
    throw error
  }
}
