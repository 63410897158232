var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-clamp",
    {
      attrs: {
        autoresize: "",
        expanded: _vm.isExpanded,
        "max-lines": _vm.maxLines,
      },
      on: {
        clampchange: function ($event) {
          return _vm.$emit("clamped", $event)
        },
        "update:expanded": function ($event) {
          _vm.isExpanded = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "after",
          fn: function ({ clamped, expanded, toggle }) {
            return [
              clamped || expanded
                ? _c(
                    "v-btn",
                    {
                      attrs: { small: "", text: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return toggle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            clamped ? _vm.$t("ui.more") : _vm.$t("ui.less")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }