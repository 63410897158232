import store from '@/store'

export const fetchChannels = async ({ filter }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  const queryString = filter ? `?filter=${encodeURIComponent(filter)}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/channels${queryString}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[channelService]:', error)
    throw error
  }
}

export const fetchChannel = async ({ channelId, channelSlug }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const path = channelId
      ? `/api/tenant/${tenantKey}/channel/${channelId}`
      : `/api/tenant/${tenantKey}/channel/slug/${channelSlug}`
    const response = await fetch(path)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[channelService]:', error)
    return {}
  }
}
