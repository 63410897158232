var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "hide-overlay": !_vm.frame, "max-width": "300" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "c-link ma-2",
                      attrs: { color: "primary", outlined: !_vm.isMobile },
                      on: {
                        click: function ($event) {
                          return _vm.show()
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isMobile ? _vm.$t("quiz.action.view") : _vm.label
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showPopup,
        callback: function ($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup",
      },
    },
    [_vm.frame === true ? _c("iframe", { attrs: { src: _vm.src } }) : _vm._e()]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }