import Vue from 'vue'
import Vuetify from 'vuetify/lib' // Vue plugin
import minifyTheme from 'minify-css-string'

// import fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faColumns, faSignature } from '@fortawesome/free-solid-svg-icons'

// import internal dependencies
import { extractLanguage } from '@/locales/utils.js'

Vue.component('FontAwesomeIcon', FontAwesomeIcon) // Register component globally
library.add(faColumns, faSignature)

// eslint-disable-next-line no-unused-vars
const themeCache = {
  get: (key) => localStorage.getItem(key),
  set: (key, value) => localStorage.setItem(key, value)
}

const setOptions = (store, i18n) => ({
  breakpoint: {
    mobileBreakpoint: 'xs',
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280 - 16, // reserves 16px for scrollbar
      lg: 1920 - 16
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      faColumns: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'columns']
        }
      },
      faSignature: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'signature']
        }
      }
    }
  },
  theme: {
    dark: store.state.themeStore.isDark,
    options: {
      customProperties: true,
      customVariables: ['@/styles/variables.scss'],
      minifyTheme,
      // themeCache,
      treeShake: true,
      variations: false
    },
    themes: store.state.themeStore.theme.themes
  },
  lang: {
    current: extractLanguage(store.state.i18nStore.locale), // may be redundant since Vuetify messages are loaded by VueI18n
    t: (key, ...params) => i18n.t(key, params)
  }
})

export class VuetifyPlugin {
  static install(Vue, { store, i18n }) {
    Vuetify.config.silent = false

    Vue.use(Vuetify)

    const vuetify = new Vuetify(setOptions(store, i18n))

    Vue.prototype.$vuetify = vuetify
  }
}
