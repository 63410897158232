import { NotFoundError } from '@/services/errors.js'

export const fetchTenants = async () => {
  try {
    const response = await fetch(`/api/tenants`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[tenantService]:', error)
    throw error
  }
}

export const fetchTenant = async (tenantKey) => {
  try {
    const response = await fetch(`/api/tenant/${tenantKey}`)
    if (response.ok) {
      return response.json()
    } else if (response.status === 404) {
      return new NotFoundError(response.statusText)
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error(`[tenantService]: Error fetching tenant '${tenantKey}'.`, error)
    throw error
  }
}
