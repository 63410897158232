var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card d-flex flex-column", attrs: { text: "" } },
    [
      _c(
        "div",
        { staticClass: "c-flex-img" },
        [
          _c(
            "v-img",
            {
              key: _vm.thumbnail,
              staticClass: "c-card-image c-clickable align-end white--text",
              attrs: {
                "aspect-ratio": 16 / 9,
                gradient: _vm.cardGradient,
                src: _vm.thumbnail,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("view:post")
                },
              },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "c-card-title text-left px-4 py-2" },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-title", { staticClass: "py-2" }, [
        _c("div", [
          _vm.displayName
            ? _c("p", { staticClass: "mb-0" }, [
                _vm._v(" " + _vm._s(_vm.displayName) + " "),
              ])
            : _vm._e(),
          _c("p", { staticClass: "grey--text mb-0" }, [
            _vm._v(" " + _vm._s(_vm.publishDate) + " "),
          ]),
        ]),
      ]),
      _c(
        "v-card-text",
        { staticClass: "c-excerpt pb-2 text-left" },
        [_c("ClampedText", [_vm._v(" " + _vm._s(_vm.post.excerpt) + " ")])],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-card-actions",
        { staticClass: "c-actions px-4" },
        [
          _c(
            "v-btn",
            {
              staticClass: "c-label",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("view:post")
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t(`post.action.${_vm.cardAction}`)) + " "
              ),
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("share")
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-share-variant "),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.revealBack = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-information "),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "red", icon: "", large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleFavourite()
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isFavourite ? "mdi-heart" : "mdi-heart-outline"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-scroll-x-reverse-transition",
        [
          _c("PostCardBack", {
            attrs: { author: _vm.post.author },
            model: {
              value: _vm.revealBack,
              callback: function ($$v) {
                _vm.revealBack = $$v
              },
              expression: "revealBack",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }