const enableAll = process.env.VUE_APP_ENABLE_ALL_FEATURES === 'true'

export const features = {
  accountProfile: false,
  accountSettings: false,
  advancedDrawTools: false,
  contactInfo: false,
  contentSizes: false,
  drawMode: false,
  notices: false,
  onlineStatus: true,
  pwaInstall: true,
  pwaNotifications: true,
  pwaRandomNotifications: false,
  pwaRegister: true,
  pwaDeregister: false,
  pwaDownload: false
}

export const getFeature = (name) => {
  return enableAll || features[name]
}
