var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-container c-draggable c-droppable",
      attrs: { draggable: _vm.active },
      on: {
        dragstart: function ($event) {
          return _vm.onDragStart($event, _vm.identifier)
        },
        drag: function ($event) {
          return _vm.onDrag($event)
        },
        dragend: function ($event) {
          return _vm.onDragEnd($event)
        },
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.onDragEnter($event)
        },
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.onDragOver($event)
        },
        dragleave: function ($event) {
          return _vm.onDragLeave($event)
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop($event, _vm.identifier)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }