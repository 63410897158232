var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-clamped-portable-text" },
    [
      _c(
        "div",
        {
          ref: "text",
          staticClass: "c-container",
          class: { "c-clamp": _vm.isClamped },
        },
        [_c("RichText", { attrs: { blocks: _vm.clampedBlocks } })],
        1
      ),
      _vm.isExtendable
        ? _c(
            "v-btn",
            {
              attrs: { small: "", text: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.isClamped = !_vm.isClamped
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isClamped ? _vm.$t("ui.more") : _vm.$t("ui.less")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }