<template>
  <div class="c-clamped-portable-text">
    <div ref="text" class="c-container" :class="{'c-clamp': isClamped}">
      <RichText :blocks="clampedBlocks"/>
    </div>
    <v-btn v-if="isExtendable" small text @click.stop="isClamped = !isClamped">
      {{ isClamped ? $t('ui.more') : $t('ui.less') }}
    </v-btn>
  </div>
</template>

<script>
import RichText from '@/components/base/RichText'

export default {
  name: 'ClampedPortableText',

  components: {
    RichText
  },

  props: {
    blocks: {
      type: Array,
      required: false,
      default: () => []
    },

    localizedBlocks: {
      type: Object, // block array is stored inside a localized object
      required: false,
      default: null
    }
  },

  data: function () {
    return {
      isExtendable: false,
      isClamped: true
    }
  },

  computed: {
    /* state */

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* rich text */

    clampedBlocks() {
      return this.localizedBlocks ? this.translatedBlocks : this.blocks || []
    },

    translatedBlocks() {
      return this.localizedBlocks[this.locale] || this.localizedBlocks[this.defaultLocale] || []
    }
  },

  mounted: function () {
    this.$nextTick(() => {
      this.isExtendable = this.checkOverflow(this.$refs.text)
      return true
    })

    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    checkOverflow(el) {
      const overflow = el.style.overflow

      // if visible, set overflow style to hidden
      if (!overflow || overflow === 'visible') el.style.overflow = 'hidden'

      // check if element is overflowing
      const isExtendable = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

      // restore original overflow style setting
      el.style.overflow = overflow

      return isExtendable
    },

    onResize() {
      this.isExtendable = this.checkOverflow(this.$refs.text)
    }
  }
}
</script>

<style lang="css" scoped>
.c-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.c-container > p {
  margin-bottom: 0;
}
</style>

