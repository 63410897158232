<template>
  <div class="c-figure d-flex flex-column justify-start align-center mb-4">
    <v-img
      class="c-image"
      :src="src"
      :alt="caption ? '' : alt"
      contain
    />
    <div v-if="attribution" class="text-subtitle-1 font-italic">{{ attribution }}</div>
    <div v-if="caption" class="text-h5 font-weight-bold">{{ caption }}</div>
  </div>
</template>

<script>
import { getURL } from '@/config/sanityConfig'

/* end of FIXME */

export default {
  name: 'FigureBlock',

  components: {},

  props: {
    asset: {
      type: Object,
      required: true
    },

    alt: {
      type: String,
      required: false,
      default: ''
    },

    attribution: {
      type: String,
      required: false,
      default: ''
    },

    caption: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    src() {
      return getURL(this.asset)
    }
  }
}
</script>

<style lang="css" scoped>
.c-figure {
  height: auto;
  width: 100%;
}
.c-image {
  height: auto;
  width: 100%;
}
</style>
