var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-content-tabs" },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "sheet",
            "center-active": "",
            "fixed-tabs": "",
            "show-arrows": "",
            vertical: _vm.tabs.length > 2,
          },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c("v-tab", { key: tab.name, staticClass: "c-tab" }, [
            _vm._v(" " + _vm._s(tab.name) + " "),
          ])
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "v-tab-item",
            { key: tab.name },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "g-text-oversized" },
                    [_c("RichText", { attrs: { blocks: tab.body } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }