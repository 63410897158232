var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c-widget-header d-flex align-stretch justify-space-between py-1",
      class: { "c-fill": _vm.fill },
    },
    [
      _c("div", { staticClass: "c-spacer pl-5" }),
      _c(
        "div",
        { staticClass: "my-auto text-caption text-center text-uppercase" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.title))]
          }),
        ],
        2
      ),
      _vm.closeable
        ? _c(
            "div",
            { staticClass: "c-close" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "c-btn-close c-rounded",
                  attrs: { block: "", text: "", ripple: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "c-spacer pl-5" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }