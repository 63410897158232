var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SectionPanels",
    {
      ref: "homeview",
      staticClass: "c-home-view",
      attrs: { depth: _vm.depth, initial: [0, 1, 2] },
    },
    [
      _c("ContentPanel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFavourites,
            expression: "showFavourites",
          },
        ],
        staticClass: "c-panel",
        attrs: {
          colour: "secondary",
          items: _vm.userFavouriteItems,
          layouts: ["grid", "carousel"],
          loading: _vm.isContentLoading,
          title: _vm.$t("home.favourites"),
        },
        on: {
          share: function ($event) {
            return _vm.shareItem($event)
          },
          "view:item": function ($event) {
            return _vm.viewItem($event)
          },
          "view:parent": function ($event) {
            return _vm.viewParent($event)
          },
        },
      }),
      _c("PostPanel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPosts,
            expression: "showPosts",
          },
        ],
        staticClass: "c-panel",
        attrs: {
          colour: "secondary",
          posts: _vm.userFavouritePosts,
          layouts: ["grid", "carousel"],
          loading: _vm.arePostsLoading,
          title: _vm.$t("home.posts"),
        },
        on: {
          share: function ($event) {
            return _vm.sharePost($event)
          },
          "view:post": function ($event) {
            return _vm.viewPost($event)
          },
        },
      }),
      _c("ChannelPanel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showChannels,
            expression: "showChannels",
          },
        ],
        staticClass: "c-panel",
        attrs: {
          colour: "secondary",
          channels: _vm.userFavouriteChannels,
          layouts: ["grid", "carousel"],
          loading: _vm.areChannelsLoading,
          title: _vm.$t("home.channels"),
        },
        on: {
          share: function ($event) {
            return _vm.shareChannel($event)
          },
          "view:channel": function ($event) {
            return _vm.viewChannel($event)
          },
        },
      }),
      _vm.showCalendar
        ? _c(
            "SectionPanel",
            {
              staticClass: "c-panel",
              attrs: {
                colour: "secondary",
                title: _vm.$t("home.today"),
                count: _vm.userEventsCount,
                loading: _vm.areEventsLoading,
              },
            },
            [
              _c("CalendarViewer", {
                attrs: { events: _vm.userEvents, period: "day" },
                on: { "update:period": _vm.fetchCalendarToday },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showContacts
        ? _c(
            "SectionPanel",
            {
              staticClass: "c-panel",
              attrs: {
                colour: "secondary",
                title: _vm.$t("home.contacts"),
                count: _vm.userContactsCount,
                loading: _vm.areContactsLoading,
              },
            },
            [
              _c("CardCarousel", {
                attrs: {
                  title: "home.contacts",
                  items: _vm.userContacts,
                  "card-width": 300,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "card",
                      fn: function (props) {
                        return [
                          _vm.areContactsLoading
                            ? _c("ClientCardSkeleton")
                            : _c("ContactCard", {
                                attrs: { contact: props.item },
                              }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3362639269
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showCompanies
        ? _c(
            "SectionPanel",
            {
              staticClass: "c-panel",
              attrs: {
                colour: "secondary",
                title: _vm.$t("home.companies"),
                count: _vm.userCompaniesCount,
                loading: _vm.areCompaniesLoading,
              },
            },
            [
              _c("CardCarousel", {
                attrs: {
                  title: "home.companies",
                  items: _vm.userCompanies,
                  "card-width": 300,
                  rerender: _vm.areCompaniesLoading,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "card",
                      fn: function (props) {
                        return [
                          _vm.areCompaniesLoading
                            ? _c("ClientCardSkeleton")
                            : _c("CompanyCard", {
                                attrs: { company: props.item },
                              }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2313374423
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showContentModal
        ? _c("ContentModal", {
            attrs: { item: _vm.item },
            model: {
              value: _vm.showContentModal,
              callback: function ($$v) {
                _vm.showContentModal = $$v
              },
              expression: "showContentModal",
            },
          })
        : _vm._e(),
      _vm.showPostModal
        ? _c("PostModal", {
            attrs: { post: _vm.post },
            model: {
              value: _vm.showPostModal,
              callback: function ($$v) {
                _vm.showPostModal = $$v
              },
              expression: "showPostModal",
            },
          })
        : _vm._e(),
      _vm.showSharingModal
        ? _c("ShareModal", {
            attrs: { entity: _vm.sharedEntity },
            model: {
              value: _vm.showSharingModal,
              callback: function ($$v) {
                _vm.showSharingModal = $$v
              },
              expression: "showSharingModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }