import TOOLNAMES from './names'
import AbstractHandler from './abstractHandler'

export default class CircleHandler extends AbstractHandler {
  constructor() {
    super()
    this.name = TOOLNAMES.circle
  }

  // manage context

  getContextAttributes() {
    return super.getContextAttributes()
  }

  setContextAttributes(options) {
    super.setContextAttributes(options)
  }

  // manage drawing

  mousedown(e) {
    super.mousedown(e)
  }

  mousemove(e) {
    if (this.isEraseMode) {
      this.tempContext.fillStyle = 'rgba(255,192,203, 0.2)'
    }
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)
    const { newX, newY } = this.getCoordinates(e)
    this.draw(this.tempContext, this.x, this.y, newX, newY)
  }

  mouseup(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.isEraseMode
      ? this.erase(this.mainContext, this.x, this.y, newX, newY)
      : this.draw(this.mainContext, this.x, this.y, newX, newY)
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)

    return {
      tool: this.name,
      attributes: this.getContextAttributes(),
      segments: [[this.x, this.y, newX, newY]]
    }
  }

  draw(ctx, x1, y1, x2, y2) {
    if (this.isEraseMode) {
      ctx.fillStyle = 'rgba(255,192,203, 0.4)'
      this.renderCircle(ctx, x1, y1, x2, y2, true)
    } else {
      this.renderCircle(ctx, x1, y1, x2, y2, false)
    }
  }

  erase(ctx, x1, y1, x2, y2) {
    ctx.globalCompositeOperation = 'destination-out'
    this.renderCircle(ctx, x1, y1, x2, y2, true)
  }

  renderCircle(ctx, x1, y1, x2, y2, fill) {
    const radius = Math.hypot(x2 - x1, y2 - y1)

    ctx.beginPath()
    ctx.arc(x1, y1, radius, 0, Math.PI * 2, true)
    fill ? ctx.fill() : ctx.stroke()
    ctx.closePath()
  }

  redraw({ ctx, segments, fill }) {
    const x1 = segments[0][0]
    const y1 = segments[0][1]
    const x2 = segments[0][2]
    const y2 = segments[0][3]

    this.renderCircle(ctx, x1, y1, x2, y2, fill)
  }
}

export const circleHandler = new CircleHandler()
