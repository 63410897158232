var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      staticClass: "d-flex justify-center align-center my-auto",
      class: { "c-clickable": _vm.clickable },
      attrs: { color: _vm.colour, size: _vm.size },
      on: {
        click: function ($event) {
          _vm.clickable && _vm.$emit("click")
        },
      },
    },
    [
      _vm.avatar
        ? _c("v-img", {
            attrs: {
              "lazy-src": require("@/assets/images/avatars/avatar.png"),
              src: _vm.avatar,
            },
          })
        : _c("span", { staticClass: "white--text text-h5" }, [
            _vm._v(" " + _vm._s(_vm.personInitials) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }