import store from '@/store'

export const fetchKey = async () => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/push/key`)
    if (response.ok) {
      const { vapidPublicKey } = await response.json()
      return { vapidPublicKey }
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[pushService]:', error)
    return {}
  }
}

export const saveSubscription = async (subscription) => {
  const tenantKey = store.state.tenantStore.tenantKey

  const augmentedSubscription = {
    ...subscription,
    origin: window.location.hostname,
    userId: store.state.userStore.userId
  }

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/push/subscription`, {
      method: 'POST',
      body: JSON.stringify(augmentedSubscription),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[pushService]:', error)
    return {}
  }
}

export const deleteSubscription = async (subscriptionId) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/push/subscription/${subscriptionId}`, {
      method: 'DELETE'
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[pushService]:', error)
    return {}
  }
}

export const updateSubscription = async (subscription) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/push/subscription/${subscription}`, {
      method: 'PUT'
    })

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[pushService]:', error)
    return {}
  }
}
