import TOOLNAMES from './names'
import AbstractHandler from './abstractHandler'

export default class TextHandler extends AbstractHandler {
  constructor() {
    super()
    this.name = TOOLNAMES.text
    this.text = 'Hello World!'
  }

  initialize(context) {
    super.initialize(context)
    this.parentElement = context.parentElement
  }

  // manage context

  getContextAttributes() {
    const attributes = super.getContextAttributes()
    return {
      ...attributes,
      font: JSON.parse(JSON.stringify(this.options.font))
    }
  }

  setContextAttributes(options) {
    super.setContextAttributes(options)
    const ctx = this.mainContext
    ctx.font = options.font
  }

  // manage typing

  mousedown(e) {
    console.log('text mouse down')
    super.mousedown(e)

    if (this.hasInput) return

    const input = document.createElement('input')
    input.type = 'text'
    input.style.left = e.pageX - 4 + 'px'
    input.style.top = e.pageY - 4 + 'px'
    input.className = 'c-canvas-text'
    input.addEventListener('keydown', this.handleEnter)
    document.body.appendChild(input)
    input.focus()
    this.hasInput = true
  }

  mousemove(e) {
    super.mousemove(e)
  }

  mouseup(_e) {
    // this.mainContext.fillText(this.text, this.x, this.y)
    return {
      tool: this.name,
      attributes: this.getContextAttributes()
      // segments: [[this.x, this.y]],
      // text: this.text
    }
  }

  handleEnter(e) {
    const keyCode = e.keyCode
    if (keyCode === 13) {
      this.draw(this.mainContext, parseInt(this.style.left, 10), parseInt(this.style.top, 10))
      document.body.removeChild(this)
      this.hasInput = false
    }
  }

  draw(ctx, x1, y1, _x2, _y2) {
    ctx.textBaseLine = 'top'
    ctx.textAlign = 'left'
    ctx.fillText(this.text, x1 - 4, y1 - 4)
  }

  redraw({ ctx, segments, text }) {
    const x = segments[0][0]
    const y = segments[0][1]

    ctx.fillText(text, x, y)
  }
}

export const textHandler = new TextHandler()
