var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.header
        ? _c(
            "v-sheet",
            { attrs: { height: "64" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "secondary", dense: "", flat: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 text-md-h6 text-lg-h5 text-xl-h5",
                      attrs: { outlined: "" },
                      on: { click: _vm.setToday },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("calendar.today")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { fab: "", text: "", outlined: "" },
                      on: { click: _vm.prev },
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v(" mdi-chevron-left "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { fab: "", text: "", outlined: "" },
                      on: { click: _vm.next },
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v(" mdi-chevron-right "),
                      ]),
                    ],
                    1
                  ),
                  _vm.$refs.calendar
                    ? _c(
                        "v-toolbar-title",
                        { staticClass: "text-md-h5 text-lg-h4 text-xl-h4" },
                        [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c("CalendarModeSwitcher")
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { outlined: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-md-h6 text-lg-h5 text-xl-h5",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.typeToLabel[_vm.type]) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-icon",
                                      { attrs: { "x-large": "", right: "" } },
                                      [_vm._v(" mdi-menu-down ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3542951342
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.type = "day"
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("calendar.day"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.type = "week"
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("calendar.week"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.type = "month"
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("calendar.month")) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.type = "4day"
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " 4 " + _vm._s(_vm.$t("calendar.days")) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-sheet",
        [
          _c("v-calendar", {
            ref: "calendar",
            attrs: {
              color: "primary",
              events: _vm.events,
              "event-color": _vm.getEventColour,
              "event-name": _vm.translateEventName,
              "event-overlap-mode": _vm.overlapMode,
              type: _vm.type,
              weekdays: _vm.weekday,
            },
            on: {
              "click:event": _vm.showEvent,
              "click:more": _vm.viewDay,
              "click:date": _vm.viewDay,
              change: _vm.getEvents,
            },
            scopedSlots: _vm._u([
              {
                key: "day-body",
                fn: function ({ date, week }) {
                  return [
                    ["day", "week", "4day"].includes(_vm.type)
                      ? _c("div", {
                          staticClass: "v-current-time",
                          class: { first: date === week[0].date },
                          style: { top: _vm.nowY },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.focus,
              callback: function ($$v) {
                _vm.focus = $$v
              },
              expression: "focus",
            },
          }),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                activator: _vm.selectedElement,
                "offset-x": "",
              },
              model: {
                value: _vm.selectedOpen,
                callback: function ($$v) {
                  _vm.selectedOpen = $$v
                },
                expression: "selectedOpen",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { "min-width": "350px", flat: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.selectedEvent.color,
                        dark: "",
                        dense: "",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v(" mdi-pencil "),
                          ]),
                        ],
                        1
                      ),
                      _c("v-toolbar-title", {
                        domProps: { innerHTML: _vm._s(_vm.selectedEvent.name) },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v(" mdi-heart "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v(" mdi-dots-vertical "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.selectedEvent.details),
                      },
                    }),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.selectedOpen = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("calendar.cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }