var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-content-details g-text-oversized" },
    [
      _c("h2", { staticClass: "c-heading text-heading-2 text-left mb-4" }, [
        _vm._v(" " + _vm._s(_vm.heading) + " "),
      ]),
      _vm.hasDescription
        ? _c("RichText", { attrs: { blocks: _vm.description } })
        : _vm._e(),
      _vm.hasTabs ? _c("ContentTabs", { attrs: { tabs: _vm.tabs } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }