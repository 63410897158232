var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass:
        "text--secondary text-caption text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-justify",
    },
    [
      _vm._v(
        " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut... "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }