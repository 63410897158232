var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card d-flex flex-column" },
    [
      _c(
        "v-skeleton-loader",
        _vm._b(
          {
            staticClass: "c-content-skeleton pb-5",
            attrs: {
              types: {
                "content-image": "image",
                "content-metadata": "button@2",
                "content-action-bar": "avatar@5",
              },
              type: "list-item-avatar-three-line, content-image, content-metadata, content-action-bar",
            },
          },
          "v-skeleton-loader",
          _vm.attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }