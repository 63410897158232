var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "c-container" }, [
    _c(
      "div",
      { staticClass: "c-header" },
      [
        _vm._t(
          "header",
          function () {
            return [
              _c(
                "div",
                { staticClass: "c-toolbar" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "c-dragger",
                      nativeOn: {
                        mousedown: function ($event) {
                          return _vm.onStartDrag($event)
                        },
                      },
                    },
                    [_vm._v(" mdi-drag ")]
                  ),
                ],
                1
              ),
            ]
          },
          { start: _vm.onStartDrag }
        ),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "c-body" },
      [
        _vm._t("default", function () {
          return [
            _c("v-card", { staticClass: "c-body-content" }, [
              _vm._v(" Content missing! "),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }