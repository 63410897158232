<template>
    <v-card
      class="c-references-card pt-4 px-4"
      elevation="4"
    >

      <v-card-title class="text-h6 text-left pa-0 pb-2">
        {{ $t('quiz.related') }}
      </v-card-title>

      <v-card-text>
        <div
          v-for="reference in references"
          :key="reference.id"
          class="c-references mb-4"
        >
          <ReferenceCard :reference="reference"/>
        </div>
      </v-card-text>
    </v-card>
</template>

<script>
import ReferenceCard from '@/components/base/ReferenceCard'

export default {
  name: 'QuizReferencesCard',

  components: {
    ReferenceCard
  },

  props: {
    references: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailAspectRatio: 4 / 3
    }
  },

  methods: {
    viewReference(parent) {
      this.viewMixin_viewReference(parent)
    }
  }
}
</script>

<style lang="css" scoped>
.c-references-card {
  width: 100%;
}

.c-references {
  width: 100%;
}
</style>
