<template>
  <!-- need .native since these are html element events  -->
  <!-- need .prevent to reverse default behaviour which is to ignore these events  -->
  <!-- need dragover event otherwise drop event won't fire  -->
  <div
    class="c-container c-draggable c-droppable"
    :draggable="active"
    @dragstart="onDragStart($event, identifier)"
    @drag="onDrag($event)"
    @dragend="onDragEnd($event)"
    @dragenter.prevent="onDragEnter($event)"
    @dragover.prevent="onDragOver($event)"
    @dragleave="onDragLeave($event)"
    @drop.prevent="onDrop($event, identifier)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DraggableNode',

  components: {},

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },

    dragType: {
      type: String,
      required: false,
      default: 'generic'
    },

    identifier: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      dropzoneOverlay: false
    }
  },

  methods: {
    onDragStart(e, dragId) {
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.setData('dragId', dragId)
      e.dataTransfer.setData(this.dragType, this.dragType)
      e.currentTarget.classList.add('c-dragging')
    },

    onDrag(_e) {},

    onDragEnd(e) {
      e.currentTarget.classList.remove('c-dragging')
    },

    onDragEnter(_e) {
      // dragenter and dragleave are fired by every child component!
      // (hence e.target = child element)
      // events propagate to parent doing the listening (i.e. e.currentTarget)
      // e.currentTarget.classList.add('c-dropzone')
    },

    onDragOver(e) {
      // ideally, should use dragenter vs. dragover, but can't
      // seem to filter out the flurry of child enter/leave events
      // (note: dragOver can inspect dataTransfer types, but not values)
      if (e.dataTransfer.types.includes(this.dragType)) {
        e.preventDefault()
        e.currentTarget.classList.add('c-dropzone')
      }
    },

    onDragLeave(e) {
      e.currentTarget.classList.remove('c-dropzone')
    },

    onDrop(e, dropId) {
      // clear out the styling
      e.currentTarget.classList.remove('c-dropzone')
      // act upon the drop
      const dragType = e.dataTransfer.getData(this.dragType)
      if (dragType === this.dragType) {
        const dragId = e.dataTransfer.getData('dragId')
        if (dragId !== dropId) {
          this.$emit('drop', { dragId, dropId })
        }
      }
    },

    doRectsOverlap(r1, r2) {
      // r2 is <...> r1
      // (0,0) is (top, left) hence negate y axis
      const rightOf = r2.left > r1.right
      const leftOf = r2.right < r1.left
      const above = r2.bottom * -1 > r1.top * -1
      const below = r2.top * -1 < r1.bottom * -1

      return leftOf || rightOf || above || below
    }
  }
}
</script>

<style lang="css" scoped>
.c-container {
  height: 100%;
  width: 100%;
}

/* style the element being dragged */
.c-draggable.c-dragging {
  opacity: 0.4;
}

/* style the element where the drop is occuring */
.c-droppable.c-dropzone {
  outline: solid 8px;
  outline-color: var(--v-sheet-base);
  box-shadow: 16px 0 0 green;
}
</style>

