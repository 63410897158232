<template>
  <div>
    <v-dialog
      v-model="showPostModal"
      content-class="c-post-modal justify-center"
      fullscreen
      overlay-color="background"
      scrollable
      transition="dialog-bottom-transition"
      @keydown.esc="closeModal"
    >
      <PostBar
        class="c-post-header"
        :title="post.title"
        :favourite="isFavourite"
        @toggle:favourite="toggleFavourite"
        @share="showShareMenu = !showShareMenu"
        @close="closeModal"
      />
      <PostBody
        class="c-post-body"
        :post="post"
      />
    </v-dialog>

    <v-bottom-sheet v-model="showShareMenu">
      <PostShareMenu :post="post" />
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import PostBar from '@/components/post/PostBar'
import PostBody from '@/components/post/PostBody'
import PostShareMenu from '@/components/post/PostShareMenu'

export default {
  name: 'PostModal',

  components: {
    PostBar,
    PostBody,
    PostShareMenu
  },

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    post: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false,
      showPostModal: false,
      showShareMenu: false
    }
  },

  computed: {
    favourite() {
      return !!(this.$store.state.userStore.posts.indexOf(this.post?.id) > -1)
    }
  },

  created: function () {
    this.showPostModal = this.show
  },

  mounted: function () {
    document.documentElement.style.overflow = 'hidden'
    document.body.scroll = 'no'

    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToPosts', 'removeFromPosts']),

    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      if (this.isFavourite) {
        this.addToPosts({ id: this.post.id })
        this.$emit('add:favourite')
      } else {
        this.removeFromPosts({ id: this.post.id })
        this.$emit('remove:favourite')
      }
    },

    closeModal() {
      this.showPostModal = false
      document.documentElement.style.overflow = 'auto'
      document.body.scroll = 'yes'
      this.$emit('close', this.showPostModal)
      // this.$router.go(-1)
      // this.$store.commit('postStore/setActivePost', null)
    }
  }
}
</script>

<style lang="css" scoped>
.c-post-modal {
  background: rgba(0.6, 0.6, 0.6, 0.75);
  overscroll-behavior: contain;
  overflow-y: auto; /* enables overscroll setting */
  width: 100%;
}
.c-post-body {
  padding-top: 48px;
}

.v-subheader {
  padding: 0px;
}
</style>
