var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card d-flex flex-column" },
    [
      _c(
        "v-skeleton-loader",
        _vm._b(
          {
            staticClass: "c-channel-skeleton pb-5",
            attrs: {
              types: {
                "channel-heading": "heading",
                "channel-excerpt": "paragraph",
                "channel-action-bar": "button, avatar@3",
              },
              type: "image, channel-heading, channel-excerpt, channel-action-bar",
            },
          },
          "v-skeleton-loader",
          _vm.attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }