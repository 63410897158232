export default {
  computed: {
    layoutMixin_scrollbarSize() {
      return this.$vuetify.breakpoint.scrollBarWidth
    }
  },

  methods: {
    layoutMixin_getViewports() {
      return {
        // Note: window.innerHeight adapts to handle
        // popup keyboard and browser bar shrinkage during scrolling
        viewport: {
          width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
          height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        },
        // https://github.com/WICG/visual-viewport
        // https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport
        visualViewport: {
          width: window.visualViewport.width,
          height: window.visualViewport.height
        }
      }
    },

    layoutMixin_calculateWindowScrollbarSize() {
      return {
        width: window.innerWidth - document.documentElement.clientWidth,
        height: window.innerHeight - document.documentElement.clientHeight
      }
    },

    layoutMixin_calculateScrollbarSize(element) {
      // console.debug('window.innerWidth=', window.innerWidth)
      // console.debug('document.body.clientWidth=', document.body.clientWidth)
      const el = element || this.$el
      const borderWidth =
        parseInt(getComputedStyle(el).borderLeftWidth, 10) +
        parseInt(getComputedStyle(el).borderRightWidth, 10)
      const borderHeight =
        parseInt(getComputedStyle(el).borderTopWidth, 10) +
        parseInt(getComputedStyle(el).borderBottomWidth, 10)

      // offset dimensions include border and scrollbar (whereas client dimensions do not)
      const scrollbarWidth = el.offsetWidth - el.clientWidth - borderWidth
      const scrollbarHeight = el.offsetHeight - el.clientHeight - borderHeight

      return {
        width: scrollbarWidth,
        height: scrollbarHeight
      }
    }
  }
}
