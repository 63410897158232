<template>
  <v-list color="background">
    <v-subheader>{{ $t('post.share') }}</v-subheader>
    <v-list-item v-for="(sharer, i) in shareLinks" :key="i" :href="sharer.link" target="_blank">
      <v-list-item-avatar size="56">
        <v-avatar size="56" tile>
          <v-icon x-large :color="sharer.colour">
            {{ sharer.icon }}
          </v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>{{ sharer.title }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'PostShareMenu',

  components: {},

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    appOrigin() {
      return window.location.origin
    },

    shareLinks() {
      const permalink = this.appOrigin + this.$route.path

      // Facebook
      const FBsharer = 'https://www.facebook.com/sharer/sharer.php?u='

      // Twitter
      const tweet = 'https://twitter.com/home?status=Check%20out%20this%20awesome%20post%3A%20'

      // LinkedIn
      const LinkedIn = 'https://www.linkedin.com/sharePost?mini=true&url='
      const LinkedInParams =
        '&title=' + this.post.title + '&summary=' + this.post.excerpt + '&source=Cosmicify'

      // Pinterest
      const Pin = 'https://pinterest.com/pin/create/button/?url='
      const PinParams = '&media=' + this.post.mainImage + '&description=' + this.post.excerpt

      // SMS
      const SMS = 'sms://?body=Hey%20check%20this%20out%20'

      // Email
      const Email = 'mailto:?&subject=Check out this post&body='

      return [
        {
          icon: 'mdi-facebook',
          colour: '#3c5a99',
          link: FBsharer + permalink,
          title: 'Facebook'
        },
        {
          icon: 'mdi-twitter',
          colour: '#1da1f2',
          link: tweet + permalink,
          title: 'Twitter'
        },
        {
          icon: 'mdi-linkedin',
          colour: '#0e76a8',
          link: LinkedIn + permalink + LinkedInParams,
          title: 'LinkedIn'
        },
        {
          icon: 'mdi-pinterest',
          colour: '#e60023',
          link: Pin + permalink + PinParams,
          title: 'Pinterest'
        },
        {
          icon: 'mdi-message-text',
          colour: 'green',
          link: SMS + permalink,
          title: 'Text Message'
        },
        {
          icon: 'mdi-email',
          colour: 'blue',
          link: Email + permalink,
          title: 'Send Email'
        }
      ]
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped></style>
