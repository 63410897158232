var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-title",
    { staticClass: "justify-end mb-n5" },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "", color: "yellow", large: "" },
          on: {
            click: function ($event) {
              return _vm.toggleFavourite()
            },
          },
        },
        [
          _c("v-icon", [
            _vm._v(
              " " +
                _vm._s(_vm.isFavourite ? "mdi-star" : "mdi-star-outline") +
                " "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }