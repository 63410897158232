var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-actions",
    { staticClass: "justify-space-between" },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("view:item")
            },
          },
        },
        [
          _c("v-icon", { attrs: { color: "accent", large: "" } }, [
            _vm._v(" mdi-eye "),
          ]),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { color: "accent", icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("share")
            },
          },
        },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v(" mdi-share-variant "),
          ]),
        ],
        1
      ),
      _vm.allowDownload
        ? _c(
            "v-btn",
            {
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  return _vm.archiveItem(_vm.item)
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-cloud-download "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { color: "accent", icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("view:info")
            },
          },
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-information ")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { color: "red", icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.toggleFavourite(_vm.item)
            },
          },
        },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.isFavourite ? "mdi-heart" : "mdi-heart-outline") +
                " "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }