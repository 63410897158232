<template>
  <div v-show="showInlineAd" class="c-inline-ad mx-auto my-4">
    <AdSpot2
      v-model="showInlineAd"
      class="c-inline-block"
      :max="1"
      stack
      zone="inline"
      @count="onCount($event)"
    />
  </div>
</template>

<script>
import AdSpot2 from '@/components/ad/AdSpot2'

export default {
  name: 'AdBlock',

  components: {
    AdSpot2
  },

  props: {
    keywords: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: function () {
    return {
      adCount: 0,
      showInlineAd: true
    }
  },

  computed: {
    ad() {
      return this.$store.state.adStore.ads[0]
    }
  },

  methods: {
    onCount(count) {
      this.showInlineAd = count > 0
    }
  }
}
</script>

<style lang="css" scoped>
.c-inline-ad {
  width: 100%;
  max-width: 350px;
}
.c-inline-block {
  margin: 1px; /* allow for border */
}
</style>
