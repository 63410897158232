<template>
  <div class="c-glb-viewer">
    <model-viewer
      ref="modelViewer"
      class="c-model-viewer"
      loading="auto"
      :xposter="poster"
      :src="modelURL"
      camera-controls
      :camera-orbit="cameraOrbit"
      :camera-target="defaultCameraTarget"
      :exposure="exposure"
      min-camera-orbit="-Infinity 0deg auto"
      max-camera-orbit="Infinity 360deg auto"
      :interaction-prompt="prompt"
      quick-look-browsers="safari chrome"
      touch-action="pan-y"
      ar
      ar-placement="wall"
      ar-scale="auto"
      ar-modes="webxr scene-viewer quick-look fallback"
      :animation-name="animation"
      :autoplay="false"
      :disable-pan="disablePan"
      :disable-tap="false"
      :disable-zoom="disableZoom"
      :auto-rotate="autoRotate"
      auto-rotate-delay="0"
      rotation-per-second="45deg"
      @load="onModelLoad($event)"
      @progress="onProgress($event)"
      @error="onError($event)"
    >
      <div v-if="showProgress" slot="progress-bar" class="c-progress text-center">
        <v-progress-circular
          :rotate="360"
          :size="100"
          :width="25"
          :value="percentage"
          color="primary"
        >
          {{ percentage + '%' }}
        </v-progress-circular>
      </div>
      <button slot="xhotspot-bladder" class="c-hotspot" data-position="0 0 -10" data-normal="1 0 0">
        <div class="c-annotation">This is a hotspot.</div>
      </button>
    </model-viewer>
  </div>
</template>

<script>
/*
 * Built-In Actions:
 * - tap/click outside model to reset orbit/orientation/scale
 * - tap on model to change camera target (default target is model centre)
 * - pinch zoom
 * - two fingers to pan (aka move)
 * - one finger to pan left/right (touch-action="pan-x")
 * - one finger to rotate
 */
import '@google/model-viewer'
import { EventNames } from '@/players/ModelPlayer/constants/ControlConstants.js'

export default {
  name: 'GLBViewer',

  components: {},

  props: {
    src: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: false,
      default: () => {}
    },

    poster: {
      type: String,
      required: false,
      default: ''
    },

    cacheSize: {
      type: Number,
      required: false,
      default: 5
    }
  },

  data: function () {
    return {
      // loading
      modelURL: this.src,
      initialFOV: 0,
      progress: 0,
      showProgress: true,
      scenePoster: this.poster,

      // controls
      animation: '',
      autoRotate: false,
      defaultCameraOrbit: '0deg 0deg auto',
      defaultCameraTarget: 'auto auto auto', // model centre
      defaultExposure: '1',
      disablePan: true,
      disableRotate: false,
      disableZoom: true,
      prompt: 'auto',
      showAnnotations: true
    }
  },

  computed: {
    baseURL() {
      return this.src.substring(0, this.src.lastIndexOf('/'))
    },

    cameraOrbit() {
      return this.options?.cameraOrbit || this.defaultCameraOrbit
    },

    exposure() {
      return this.options?.exposure?.toString() || this.defaultExposure
    },

    percentage() {
      return (this.progress * 100).toFixed(0)
    }
  },

  mounted: function () {
    // number of models to cache (keep minimum = 5)
    this.$refs.modelViewer.cacheSize = this.cacheSize < 5 ? 5 : this.cacheSize
    this.$refs.modelViewer.exposure = this.exposure
    // turn off interaction prompt (after approx rendering twice)
    setInterval(() => (this.prompt = 'none'), 8000)
  },

  methods: {
    sendViewerMessage({ event, param }) {
      const eventMethods = {
        [EventNames.LOAD_SCENE]: this.onSelectScene,
        [EventNames.RESET]: this.onReset,
        [EventNames.REVOLVE]: this.onRevolve,

        [EventNames.MODE_MOVE]: this.onEnablePan,
        [EventNames.MODE_ROTATE]: this.onEnableRotate,
        [EventNames.MODE_SCALE]: this.onEnableZoom,
        [EventNames.SHOW_ANNOTATIONS]: this.onShowAnnotations,
        [EventNames.TOGGLE_ANIMATION]: this.onToggleAnimation,
        [EventNames.ZOOM_IN]: this.onZoomIn,
        [EventNames.ZOOM_OUT]: this.onZoomOut
      }

      eventMethods[event] ? eventMethods[event](param) : () => {}
    },

    /* event methods */

    onModelLoad(_e) {
      this.$emit('loaded', true)
      if (this.animation) this.$refs.modelViewer.play()
      this.initialFOV = this.$refs.modelViewer.getFieldOfView()
    },

    onProgress(e) {
      this.progress = e.detail.totalProgress
      if (this.progress === 1) {
        setInterval(() => (this.showProgress = false), 1000)
      }
    },

    onError(e) {
      const errMsg = `${e.detail.type}: ${e.detail.sourceEvent}`
      console.error('[GLBViewer]:', errMsg)
      this.$emit('error', errMsg)
    },

    onReset() {
      const mv = this.$refs.modelViewer

      // stop automatic revolutions and reset to initial rotational position
      this.autoRotate = false
      mv.resetTurntableRotation()

      // reset camera
      mv.fieldOfView = this.initialFOV // size
      mv.cameraOrbit = this.cameraOrbit // orientation
      mv.cameraTarget = this.defaultCameraTarget // location

      // mv.updateFraming()
      // if called: size and location, but not orientation
      // if not called: orientation and location, but not size

      // restore prompt
      // this.prompt = 'auto'
      // mv.resetInteractionPrompt()
    },

    onEnablePan(_param) {
      this.disablePan = false
      this.disableRotate = true
      this.disableZoom = true
    },

    onEnableRotate(_param) {
      this.disablePan = true
      this.disableRotate = false
      this.disableZoom = true
    },

    onEnableZoom(_param) {
      this.disablePan = true
      this.disableRotate = true
      this.disableZoom = false
    },

    onRevolve(param) {
      const rotate = !!param
      this.autoRotate = rotate
      this.prompt = rotate ? 'none' : 'auto'
    },

    onSelectScene(param) {
      this.showProgress = true
      this.animation = ''
      this.scenePoster = '' // only the first scene uses a poster
      this.modelURL = `${this.baseURL}/${param}.glb`
    },

    onShowAnnotations(param) {
      this.showAnnotations = !!param
    },

    onToggleAnimation(param) {
      if (this.animation) this.$refs.modelViewer.pause()
      this.animation = param
      if (this.animation) this.$refs.modelViewer.play()
    },

    onZoomIn(_param) {
      this.$refs.modelViewer.zoom(1)
    },

    onZoomOut(_param) {
      this.$refs.modelViewer.zoom(-1)
    }
  }
}
</script>

<style lang="css" scoped>
* {
  --poster-color: black;
}

.c-glb-viewer {
  width: 100%;
  height: calc(var(--c-content-viewport-height));
}

.c-model-viewer {
  position: relative;
  width: 100%;
  height: 100%;
}

.c-progress {
  position: absolute;
  top: 50%;
  left: 50%;
}

.c-progress :deep(.v-progress-circular__info) {
  color: black;
}
</style>
