<template>
  <v-sheet
    ref="page"
    class="c-player-page"
    :class="{ 'c-fullscreen': isFullscreen }"
  >
    <PlayerBar
      v-model="showDetails"
      class="c-player-bar"
      :class="{ 'c-fullscreen': isFullscreen }"
      :item="item"
      @activate:canvas="isCanvasActivated = $event"
      @close="onClose"
      @fullscreen="isFullscreen = $event"
      @height="top = $event"
      @share="shareItem($event)"
    />

    <PlayerDrawer
      class="c-player-drawer"
      :class="{ 'c-fullscreen': isFullscreen }"
      :context="context"
      :item="item"
      :open="showDetails"
      @resizing="resizing = $event"
    />

    <CircularProgress
      v-if="isLoading"
      class="c-progress"
      :message="$t('message.fetchingContent')"
    />

    <template v-else>
      <div
        class="c-bar-spacer"
        :class="{ 'c-fullscreen': isFullscreen }"
      />
      <ContentLayout
        v-if="isFound"
        :ad-free="isAdFree"
        :authors="authors"
        :fullscreen="isFullscreen"
        :params="params"
        :type="mediaType"
        :top="top"
      >
        <ContentPlayer
          v-if="item"
          class="c-content g-skinny-scrollbars"
          :class="{ 'c-fullscreen': isFullscreen }"
          :canvas="isCanvasActivated"
          :fullscreen="isFullscreen"
          :item="item"
          :overlay="resizing"
        >
          <template #player="props">
            <component
              :is="player"
              :class="props.playerClass"
              :content="item"
              :fullscreen="isFullscreen"
              :params="props.params"
              @context="context = $event"
              @error:handled="onError(true, $event)"
              @error:loading="onError(false, $event)"
            />
          </template>
        </ContentPlayer>
      </ContentLayout>

      <ErrorMessage
        v-else
        :title="$t('error.ContentNotFoundError.title')"
        :message="$t('error.ContentNotFoundError.message')"
      />
    </template>

    <ShareModal v-if="showSharingModal" v-model="showSharingModal" :entity="sharedEntity" />
  </v-sheet>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'
import ContentLayout from '@/components/content/ContentLayout'
import ContentPlayer from '@/components/content/ContentPlayer'
import PlayerBar from '@/components/player/PlayerBar'
import PlayerDrawer from '@/components/player/PlayerDrawer'
import ShareModal from '@/components/base/ShareModal'

import exitMixin from '@/mixins/exitMixin.js'
import metaMixin from '@/mixins/metaMixin.js'
import mobileMixin from '@/mixins/mobileMixin.js'
import shareMixin from '@/mixins/shareMixin.js'
import players from '@/players/playerRegistry.json'
import { contentService } from '@/services/contentService'
import { mapActions, mapGetters } from 'vuex'

class ParameterError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ParameterError'
  }
}

export default {
  name: 'PlayerPage',

  components: {
    CircularProgress,
    ContentLayout,
    ContentPlayer,
    PlayerBar,
    PlayerDrawer,
    ShareModal
  },

  mixins: [exitMixin, metaMixin, mobileMixin, shareMixin],

  beforeRouteEnter: function (_to, from, next) {
    next((vm) => (vm.from = from))
  },

  beforeRouteUpdate: function (to, from, next) {
    this.from = from
    next()
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      // content
      contentService: contentService,
      isLoading: true,
      isFound: false,
      item: null,

      // canvas
      isCanvasActivated: false,

      // navigation
      from: null,

      // layout
      isFullscreen: false,
      prevScrollPosition: 0,
      top: 48,

      // ads
      areAdsLoaded: false,
      showAds: true,

      // drawer
      context: null,
      showDetails: false, // null means initially open on mobile, closed on desktop
      resizing: false,

      // sharing
      showSharingModal: false,
      sharedEntity: {}
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAdsByZone']),

    /* app context */

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* org context */

    orgName() {
      return this.$store.state.orgStore.orgName
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    metaTags() {
      const title = this.item?.title || ''
      const description = this.item?.abstract || ''
      const thumbnail = this.item?.thumbnail || ''
      return {
        loaded: !this.isLoading,
        docTitle: `${title} | ${this.orgName}`,
        meta: [
          // OpenGraph
          { property: 'og:title', content: title },
          { property: 'og:image', content: thumbnail },
          { property: 'og:description', content: description },
          { property: 'og:site_name', content: this.orgName },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: window.location.href },

          // Google / Schema.org
          { itemprop: 'name', content: title },
          { itemprop: 'image', content: thumbnail },
          { itemprop: 'description', content: description }
        ]
      }
    },

    /* ad context */

    isAdFree() {
      return (
        !this.item ||
        // any item can be ad free
        this.item.adFree === true ||
        // educational content is ad free
        this.item.contentTypeKeyword === 'detail-aid' ||
        this.item.contentTypeKeyword === 'learning-aid' ||
        this.item.contentTypeKeyword === 'cme' ||
        // promotional content is ad free
        this.item.contentTypeKeyword === 'brochure' ||
        this.item.contentTypeKeyword === 'promo'
      )
    },

    // layout

    authors() {
      return this.item?.authors
    },

    mediaType() {
      return this.item?.mediaType
    },

    isArticle() {
      return this.mediaType === 'article'
    },

    params() {
      return this.item?.params
    },

    showHeader() {
      return (
        (this.isReallyMobile || this.isArticle) &&
        !this.isFullscreen &&
        this.showAds &&
        !this.isAdFree
      )
    },

    showSide() {
      const show = !this.isReallyMobile && !this.isFullscreen && this.showAds && !this.isAdFree
      return show
    },

    // navigation
    isMultiPage() {
      return players.find((player) => player.mediaType === this.item?.mediaType)?.isMultiPage
    },

    player() {
      const playerComponent =
        players.find((player) => player.mediaType === this.item?.mediaType)?.player || 'WebViewer'
      return () =>
        import(/* webpackChunkName: "[request]" */ `@/players/${playerComponent}/index.js`)
    }
  },

  watch: {
    id: {
      immediate: true,
      handler: async function (toId, _fromId) {
        const realId = this.shareMixin_extractId(toId)
        try {
          this.isFound = false
          this.item = await this.fetchItem(realId)
          if (this.item) {
            this.isFound = true
            this.fetchItemAds(this.item)
          }
        } catch (error) {
          await this.showAlert(error)
        } finally {
          this.isLoading = false
        }
      }
    }
  },

  mounted: async function () {
    this.$refs.page.$el.style.setProperty('--c-player-bar-height', `${this.top}px`)
    window.addEventListener('unload', this.onRefresh)
  },

  activated: async function () {
    console.warn('[PlayerPage]: activated!')
  },

  deactivated: function () {},

  beforeDestroy: function () {
    window.removeEventListener('unload', this.onRefresh)
  },

  methods: {
    ...mapActions('adStore', ['clearAds', 'fetchAds']),

    resetData() {
      // content
      this.context = null
      this.isLoading = true
      this.isFound = false
      this.item = null

      // scrolling
      this.prevScrollPosition = 0
    },

    async fetchItem(id) {
      // TODO: fetch from cache (via store)
      try {
        if (id) {
          const item = await this.contentService.fetchContentItem({ id })
          return item
        } else {
          throw new ParameterError('Error: invalid id in url.')
        }
      } catch (error) {
        console.error('[PlayerPage]:', error)
        throw error
      }
    },

    async fetchItemAds(item) {
      try {
        this.areAdsLoaded = false
        this.clearAds()
        if (!this.isAdFree) {
          const itemKeywords = item.adWords || []
          const topicKeywords = item.topicKeywords || []
          const portalKeywords = [this.portalKey]
          const keywords = this.item?.hasExclusiveAds
            ? [...itemKeywords, '__exclusive__']
            : [...itemKeywords, ...topicKeywords, ...portalKeywords]

          this.fetchAds({
            locale: this.locale,
            keywords
          })

          this.areAdsLoaded = true
        }
      } catch (error) {
        console.error('[PlayerPage]:', error)
      }
    },

    /* events */

    onClose() {
      /*
       * There are two events that initiate closing a player:
       * 1)The Player emits an "error" event.
       * 2)The user selects the "close" from the PlayerBar
       *
       */
      console.debug('[PlayerPage]: Closing the player.')
      // go back...or forward (i.e. a fake "go back")...to whence you came
      const exitTo = this.isMultiPage ? this.from : null
      this.exitMixin_close({ from: this.from, to: exitTo })
    },

    async onError(isHandled, error) {
      console.debug('[PlayerPage]: Closing the player due to an error.')
      if (!isHandled) await this.showAlert(error)
      this.onClose()
    },

    onRefresh(event) {
      event.preventDefault()
      console.debug('[PlayerPage]: refreshing the page.')
      // this.$router.go(0)
      return // don't let Safari show the "are you sure?" dialog box
    },

    async shareItem() {
      this.sharedEntity = await this.shareMixin_shareItem(this.item)
      this.showSharingModal = !!this.sharedEntity
    },

    async showAlert(error) {
      const result = await this.$alert({
        icon: 'error',
        title: this.$t(`error.LoadingError.title`),
        text: this.$t(`error.LoadingError.message`),
        footer: error,
        confirmButtonText: this.$t(`ui.close`)
      })
      return result.isConfirmed ? true : false
    }
  }
}
</script>

<style lang="css" scoped>
/* page elements */
.c-player-page {
  --c-player-bar-height: 48px;
  background-color: var(--v-background-base);
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
}
.c-player-bar {
  z-index: 1;
}
.c-player-drawer {
  top: var(--c-player-bar-height) !important;
  height: calc(var(--c-viewport-height) - var(--c-player-bar-height)) !important;
  overflow-y: auto;
}

/* viewport */
.c-progress {
  padding-top: var(--c-player-bar-height);
  height: calc(var(--c-viewport-height));
  overflow: hidden;
}

.c-bar-spacer {
  padding-top: var(--c-player-bar-height);
}
.c-content {
  min-height: 100%;
  width: 100%;
}
.c-article {
  height: 100%;
}

/* fullscreen */
.c-player-bar.c-fullscreen {
  display: none;
}
.c-player-drawer.c-fullscreen {
  display: none;
  visibility: hidden;
}
.c-bar-spacer.c-fullscreen {
  padding-top: 0px;
}
.c-content.c-fullscreen {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  background-color: black;
}
</style>
