/*
 * Notes:
 *   1)Workbox offers helper functions for service workers.
 *   2)Service Worker collaborates with AppInstall component.
 *   3)Reference Material
 *     https://web.dev/articles/service-worker-lifecycle
 *     https://gist.github.com/leommoore/6415005
 */

import { Workbox } from 'workbox-window'
import splashScreens from '@/config/splashScreens.js'
import { parseURL } from '@/utilities/urlUtils.js'
import { pushService } from '@/services/pushService.js'

const isDev = process.env.NODE_ENV === 'development'
const NOTIFY_METHOD = 'APP' // 'APP', 'USER' or 'SILENT'

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const displayQuota = () => {
  if (isDev) {
    navigator.storage.estimate().then((estimate) => {
      const quotaMB = (estimate.quota / 1024 / 1024).toFixed(2)
      const quotaPercent = ((estimate.usage / estimate.quota) * 100).toFixed(2)
      console.warn('[pwa]: Quota available (Mb) =', quotaMB)
      console.warn('[pwa]: Quota used (%) =', quotaPercent)
    })
  }
}

export const isPWA = () => {
  return (
    // iOS only
    window.navigator['standalone'] ||
    // everywhere else
    ['standalone', 'minimal-ui'].some(
      (displayMode) => window.matchMedia(`(display-mode: ${displayMode})`).matches
    )
  )
}

// register the service worker (based on workbox-window)
export const register2 = async () => {
  // check if service workers are supported
  if (!('serviceWorker' in navigator)) {
    console.error('[pwa]: Service workers are not supported!')
    return
  }

  const wb = new Workbox('/sw.js')

  // handle updates
  const onUpdated = (newSW) => {
    displayQuota()

    switch (NOTIFY_METHOD) {
      // notify app that a new worker has been installed
      case 'APP':
        setTimeout(() => {
          const event = new CustomEvent('CustomUpdateAvailable', { detail: newSW })
          document.dispatchEvent(event)
        }, 5000)
        break

      // notify user that a new worker has been installed
      case 'USER':
        if (window.confirm('Update available! Do you want to reload the app?')) {
          // tell the waiting worker to proceed
          wb.messageSW({ type: 'SKIP_WAITING' })
        }
        break

      // silently update
      default:
        console.warn('[pwa]: Skipping wait and activating immediately!')
        wb.messageSW({ type: 'SKIP_WAITING' })
    }
  }

  wb.addEventListener('waiting', (event) => {
    console.warn(
      `[pwa]: A new service worker has been installed, but it can't activate` +
        `until all tabs running the current version have fully unloaded.`
    )

    onUpdated(event.sw)

    // unnecessary since main.js is already listening
    wb.addEventListener('controlling', (_event) => {
      console.warn('[pwa]: Reloading page for latest content.')
      if (confirm(`ControllingEvent: New content is available!. Click OK to refresh`)) {
        // window.location.reload()
      }
    })
  })

  // unnecessary since cache updates don't occur
  wb.addEventListener('message', (event) => {
    if (event.data.type === 'CACHE_UPDATED') {
      const { updatedURL } = event.data.payload

      console.warn(`[pwa]: A newer version of ${updatedURL} is available!`)
    }
  })

  // unnecessary since user has already been informed by AppUpdate component
  wb.addEventListener('installed', (event) => {
    if (!event.isUpdate) {
      console.log('[pwa]: Service worker installed for the first time!')
    } else {
      console.log('[pwa]: Service worker installed by an update!')
      if (confirm(`Install: New content is available!. Click OK to refresh`)) {
        // window.location.reload()
      }
    }
  })

  // unnecessary because everything is precached
  wb.addEventListener('activated', (event) => {
    /*
    // Get the current page URL + all resources the page loaded.
    const urlsToCache = [
      location.href,
      ...performance.getEntriesByType('resource').map((r) => r.name)
    ]

    // Send that list of URLs to your router in the service worker.

    wb.messageSW({
      type: 'CACHE_URLS',
      payload: { urlsToCache }
    })
    */

    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      console.warn('[pwa]: Service worker activated (for the first time)!')

      // If your service worker is configured to precache assets, those
      // assets should all be available now.
    } else {
      console.warn('[pwa]: Service worker activated (after an update)!')
    }
  })

  // register the service worker
  wb.register()

  // ask the service worker for its version
  const swVersion = await wb.messageSW({ type: 'GET_VERSION' })
  console.log('[pwa]: Service Worker version:', swVersion)
}

// register the service worker (based on lifecycle monitoring)
export const register = async () => {
  // check if service workers are supported
  if (!('serviceWorker' in navigator)) {
    console.error('[pwa]: Service workers are not supported!')
    return
  }

  const oldSW = navigator.serviceWorker.controller
  let isUpdated = false
  console.warn('[pwa]: Previous controller detected=', !!oldSW)

  // handle updates
  const onUpdated = ({ newSW, oldSW }) => {
    displayQuota()

    const notifyMethod = isPWA() ? 'APP' : 'SILENT'
    console.warn('[pwa]: Update notification method=', notifyMethod)

    switch (notifyMethod) {
      // notify app that a new worker has been installed
      case 'APP':
        setTimeout(() => {
          const event = new CustomEvent('CustomUpdateAvailable', { detail: newSW })
          document.dispatchEvent(event)
        }, 5000)
        break

      // notify user that a new worker has been installed
      case 'USER':
        if (window.confirm('Update available! Do you want to reload the app?')) {
          // tell the waiting worker to proceed
          if (oldSW) oldSW.postMessage({ type: 'ABORT' })
          newSW.postMessage({ type: 'SKIP_WAITING' })
        }
        break

      // silently update
      case 'SILENT':
        console.warn('[pwa]: Skipping wait and activating immediately!')
        if (oldSW) oldSW.postMessage({ type: 'ABORT' })
        newSW.postMessage({ type: 'SKIP_WAITING' })
        break

      default:
        console.warn('[pwa]: Invalid notification method!', notifyMethod)
    }
  }

  // invoke registration
  try {
    // register service worker (register -> install -> activate -> ready)
    const swURL = '/sw.js'
    const options = { scope: '/' }
    const registration = await navigator.serviceWorker.register(swURL, options)

    // registration.installing - the installing service worker (or undefined)
    // registration.waiting - the waiting service worker (or undefined)
    // registration.active - the active service worker (or undefined)

    // handle the case where the updatefound event was missed
    /* If there's a waiting service worker with a matching URL before the
     * `updatefound` event fires, it likely means that this site is open
     * in another tab (and in that tab, the service worker was installed)
     * or the user refreshed the page (but the previous page wasn't
     * fully unloaded before this page started loading).
     * https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#waiting
     */
    if (registration.waiting) {
      console.warn('[pwa]: Registration immediate!')
      onUpdated({ newSW: registration.waiting, oldSW })
      return
    }

    // otherwise, define a handler for new updates
    registration.addEventListener('updatefound', () => {
      console.warn('[pwa]: Service worker update found!')

      const newSW = registration.installing

      // listen for further state changes
      newSW.addEventListener('statechange', () => {
        console.warn('[pwa]: Updated service worker state=', newSW.state)
        // "installing" - the install event has fired, but not yet complete
        // "installed"  - install complete
        // "activating" - the activate event has fired, but not yet complete
        // "activated"  - fully active
        // "redundant"  - discarded (failed to install or replaced by a newer version)
        if (newSW.state === 'installed') {
          console.warn('[pwa]: Updated service worker installed!')
          // check if there's a pre-existing "activated" service worker
          if (oldSW) {
            console.warn('[pwa]: ...Previous service worker exists.')
            isUpdated = true
            onUpdated({ newSW, oldSW })
          } else {
            // first time install (so there's nothing to do)
            console.warn('[pwa]: ...No previous service worker exists.')
          }
        } else if (newSW.state === 'redundant') {
          console.error('[pwa]: Service worker installation abandoned.')
        } else if (newSW.state === 'activated') {
          isUpdated
            ? console.error('[pwa]: Service worker activated (after an update)!')
            : console.error('[pwa]: Service worker activated (for the first time)!')
          // window.location.reload()
        }
      })
    })

    // wait for service worker to be ready
    await navigator.serviceWorker.ready
    console.warn('[pwa]: Service worker is ready!')
  } catch (error) {
    console.error('[pwa]: Service worker registration failed!', error)
  }
}

// deregister the service worker
export const deregister = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready
      registration.unregister()
    } catch (error) {
      console.error('[pwa]: Deregistering service worker failed!', error)
    }
  }
}

// deregister *all* service workers
export const deregisterAll = async () => {
  if (window.navigator && navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for (let registration of registrations) {
      registration.unregister()
    }
  }
}

// add screen
export const addSplashScreens = () => {
  const { tenantKey } = parseURL()
  for (const ss of splashScreens) {
    const link = document.createElement('link')
    link.rel = 'apple-touch-startup-image'
    link.media = `screen and (device-width: ${ss.width}px) and (device-height: ${ss.height}px) and (-webkit-device-pixel-ratio: ${ss.pixelRatio}) and (orientation: ${ss.orientation})`
    link.href = `/${tenantKey}/splash_screens/${ss.devices}`
    document.head.appendChild(link)
  }
}

// events raised by the browser client
export const addInstallListeners = () => {
  // Chrome/Edge only
  window.addEventListener('beforeinstallprompt', (e) => {
    console.warn('[pwa]: beforeinstallprompt received')
    e.preventDefault()
    const installEvent = e // save the event
    setTimeout(() => {
      const event = new CustomEvent('CustomBeforeInstallPrompt', { detail: installEvent })
      document.dispatchEvent(event)
    }, 1000)
  })

  // Chrome/Edge only
  window.addEventListener('appinstalled', (e) => {
    setTimeout(() => {
      const event = new CustomEvent('CustomAppInstalled', { detail: e })
      document.dispatchEvent(event)
    }, 5000)
  })
}

// events raised (explicitly / implicitly) by the Service Worker
export const addWorkerListeners = () => {
  // navigator.serviceWorker is the parent container of the service worker
  if (navigator.serviceWorker) {
    // a new service worker has taken control
    navigator.serviceWorker.addEventListener('controllerchange', (e) => {
      setTimeout(() => {
        const event = new CustomEvent('CustomControllerChange', { detail: e.detail })
        document.dispatchEvent(event)
      }, 0)
    })

    // receive messages from the active service worker
    navigator.serviceWorker.addEventListener('message', (_e) => {
      // receive messages here
      // (alternatively, service worker can raise events)
    })
  }
}

// subscribe to push notifications
export const subscribe = async () => {
  try {
    const registration = await navigator.serviceWorker.getRegistration()

    if (!registration.pushManager) {
      return
    }

    // check for an existing subscription
    const currentSubscription = await registration.pushManager.getSubscription()
    if (currentSubscription) {
      console.warn('[pwa]: Current push subscription=', currentSubscription)
      return currentSubscription
    }

    // get VAPID public key
    const { vapidPublicKey } = await pushService.fetchKey()

    // create new subscription
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(vapidPublicKey)
    })

    // save subscription in backend
    await pushService.saveSubscription(subscription.toJSON())
  } catch (error) {
    console.error('[pwa]: Unable to subscribe to push notifications.', error)
  }
}

export const unsubscribe = async () => {
  try {
    const registration = await navigator.serviceWorker.getRegistration()

    if (!registration.pushManager) {
      return
    }

    const subscription = await registration.pushManager.getSubscription()
    const result = await registration.pushManager.unsubscribe(subscription)
    if (result) {
      // delete subscription from backend
      const subscriptionId = subscription.endpoint.split('/').pop()
      await pushService.deleteSubscription(subscriptionId)
    }
  } catch (error) {
    console.error('[pwa]: Unable to unsubscribe from push notifications.', error)
  }
}
