export default {
  /* Notes:
   *  1)Vue variables can't be prefixed with $ or _
   *  2)Computed ensures reactive translations occur
   */
  computed: {
    channelMixin_filters() {
      return []
    },

    channelMixin_sorts() {
      return [
        {
          text: this.$t('post.sortOptions.sortByTitleAsc'),
          value: 'sortByTitleAsc'
        },
        {
          text: this.$t('post.sortOptions.sortByTitleDesc'),
          value: 'sortByTitleDesc'
        }
      ]
    }
  }
}
