<template>
  <WidgetWrapper
    v-show="count > 0"
    class="c-ad-widget"
    :title="$t('ad.advertisements')"
    @close="onClose()"
  >
    <AdSpot
      v-model="showAds"
      :closeable="false"
      :max="3"
      :reverse="reverse"
      stack
      :zone="zone"
      @count="onCount($event)"
    />
  </WidgetWrapper>
</template>

<script>
import AdSpot from '@/components/ad/AdSpot'
import WidgetWrapper from '@/components/base/WidgetWrapper'

export default {
  name: 'AdWidget',

  components: {
    WidgetWrapper,
    AdSpot
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },

    reverse: {
      type: Boolean,
      required: false,
      default: false
    },

    zone: {
      type: String,
      required: false,
      default: 'side'
    }
  },

  data: function () {
    return {
      count: 0,
      showAds: this.show
    }
  },

  computed: {},

  methods: {
    onClose() {
      this.showAds = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onCount(count) {
      this.count = count
      this.$emit('count', count)
    }
  }
}
</script>

<style lang="css" scoped></style>
