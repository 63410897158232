var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    _vm._g(
      _vm._b(
        {
          ref: "drawer",
          staticClass: "c-drawer",
          attrs: {
            bottom: "",
            fixed: "",
            floating: _vm.isMobile,
            width: _vm.drawerWidth,
          },
        },
        "v-navigation-drawer",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { ref: "header", staticClass: "c-drawer__header" },
        [_vm._t("header")],
        2
      ),
      _c(
        "div",
        { ref: "body", staticClass: "c-drawer__body" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }