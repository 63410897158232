var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": "",
        "close-on-content-click": false,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      class: _vm.activatorClass,
                      attrs: { fab: "", small: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm.isAuthenticated
                    ? _c("v-avatar", { attrs: { size: "40" } }, [
                        _c("img", { attrs: { src: _vm.avatarURL } }),
                      ])
                    : _c("v-icon", { attrs: { color: "accent", large: "" } }, [
                        _vm._v(" mdi-account "),
                      ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { rounded: "" } },
        [
          _vm.isAuthenticated
            ? _c(
                "v-list-item",
                [
                  _c("v-list-item-avatar", { attrs: { size: "40" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "c-anchor",
                        attrs: {
                          href: _vm.$appConfig.avatarBaseURL,
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [
                        _c("v-avatar", { attrs: { size: "40" } }, [
                          _c("img", { attrs: { src: _vm.avatarURL } }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(_vm.userDisplayName)),
                      ]),
                      _c("v-list-item-subtitle", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(_vm.userEmail)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.updateMode.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [
                  _c("ModeSwitcher", {
                    ref: "modeSwitcher1",
                    attrs: { activator: false, mode: _vm.isDark, reverse: "" },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-title",
                {
                  staticClass:
                    "d-flex align-center justify-space-between text-h6 text-left",
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("account.mode")) + " "),
                  _c("ModeSwitcher", {
                    ref: "modeSwitcher2",
                    attrs: { activator: false, mode: _vm.isDark, switch: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-group",
            {
              staticClass: "c-list-group",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "accent", "x-large": "" } },
                            [_vm._v(" mdi-translate ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-h6 text-left" },
                        [_vm._v(" " + _vm._s(_vm.currentLocaleName) + " ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("LocaleSelector")],
            1
          ),
          _c("AccountSettings", {
            model: {
              value: _vm.showSettings,
              callback: function ($$v) {
                _vm.showSettings = $$v
              },
              expression: "showSettings",
            },
          }),
          _vm._l(_vm.enabledMenuItems, function (item) {
            return _c(
              "v-list-item",
              { key: item.key, on: { click: item.action } },
              [
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: "accent", "x-large": "" } },
                      [_vm._v(" " + _vm._s(item.icon) + " ")]
                    ),
                  ],
                  1
                ),
                _c("v-list-item-title", { staticClass: "text-h6" }, [
                  _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }