var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.src
    ? _c(
        "v-img",
        {
          key: _vm.src,
          ref: "img",
          staticClass: "c-ad-image c-image",
          class: { "c-cta": _vm.hasCTA },
          attrs: {
            "aspect-ratio": _vm.optimize ? _vm.aspectRatio : undefined,
            contain: "",
            position: _vm.optimize ? "top" : "center center",
            src: _vm.src,
            width: "100%",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("select", _vm.ad)
            },
            load: function ($event) {
              return _vm.$emit("load", _vm.$refs.img.$el)
            },
          },
        },
        [
          _vm.closeable
            ? _c(
                "v-btn",
                {
                  staticClass: "c-btn-close",
                  attrs: { color: "rgba(0, 0, 0, 0.2)", fab: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white", small: "" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }