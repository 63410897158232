// import client from '@client'
import client from '@/services/mock'

var systemMessage = {
  type: 'info',
  icon: '',
  text: {
    en: '',
    fr: ''
  }
}

class MessageService {
  constructor() {
    this.type = ''
  }

  getSystemMessage = () => {
    return systemMessage
  }

  fetchSystemMessage = () => {
    return client.fetchSystemMessage().then((message) => {
      systemMessage = message
      return message
    })
  }
}

export default MessageService
export const messageService = new MessageService()
