import { postService } from '@/services/postService'

export default {
  /* common actions */

  restoreState({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */

  async fetchPosts({ state, commit, getters }) {
    if (!state.fetched) {
      // needs a refresh from the CMS
      if (!state.loading) {
        // first one in turns on the lights
        const filter = getters.activePortal?.postFilter
        try {
          console.warn(`[postStore]: Fetching posts from CMS.`)
          const promise = postService.fetchPosts({ filter })
          commit('setLoading', { loading: true, promise })
          const posts = await promise
          commit('setPosts', posts)
          commit('setFetched', true)
          return posts
        } catch (error) {
          console.warn(`[postStore]: ...Fetch failed. Using cache.`, error)
          return state.posts || []
        } finally {
          commit('setLoading', { loading: false })
          commit('setPrimed', true)
        }
      } else {
        // a close second waits for the first to do all the work
        try {
          console.warn('[postStore]: Use pre-existing promise.')
          const posts = await state.promise
          return posts
        } catch (error) {
          console.warn('[postStore]: ...Pre-existing fetch failed.', error)
          return state.posts || []
        }
      }
    } else {
      // use cached values (no resync; already fetched)
      const count = state.posts.length
      console.warn(`[postStore]: Fetching posts from cache (${count}).`)
      return state.posts
    }
  },

  async fetchPost({ getters, commit }, { id, slug }) {
    const post = getters.getPost({ id, slug })

    if (post?.body) {
      return post
    } else {
      try {
        const fetchedPost = await postService.fetchPost({ id, slug })
        commit('setPost', fetchedPost)
        return fetchedPost
      } catch (error) {
        console.error('[postStore]:', error)
        return null
      }
    }
  },

  async incrementLikes({ commit }, { postId, deltaLikes }) {
    try {
      const { id, likes } = await postService.incrementLikes({ postId, deltaLikes })
      commit('setPost', { id, likes })
      return { id, likes }
    } catch (error) {
      console.error('[postStore]:', error)
    }
  }
}
