import client from '@/services/real'

class TenantService {
  constructor() {
    this.type = ''
  }

  fetchTenants = async () => {
    const tenants = await client.fetchTenants()
    return tenants
  }

  fetchTenant = async (tenantKey) => {
    const tenant = await client.fetchTenant(tenantKey)
    return tenant.key !== undefined ? tenant : null
  }
}

export default TenantService
export const tenantService = new TenantService()
