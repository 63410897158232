var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-settings-dialog",
        eager: "",
        fullscreen: _vm.$vuetify.breakpoint.xsAndDown,
        "overlay-opacity": "0.2",
        transition: "scale-transition",
        width: "80%",
        "max-width": "600px",
        "min-width": "300px",
      },
      on: {
        "click:outside": _vm.closeSettings,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeSettings.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showSettings,
        callback: function ($$v) {
          _vm.showSettings = $$v
        },
        expression: "showSettings",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-avatar", [
                    _c("img", { attrs: { src: _vm.$auth.getUserAvatar() } }),
                  ]),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$auth.getUserDisplayName())),
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(_vm.$auth.getUserEmail())),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          class: { "red--text": _vm.isFavourite },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.isFavourite = !_vm.isFavourite
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-heart")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { inset: "", color: "success" },
                        model: {
                          value: _vm.tooltips,
                          callback: function ($$v) {
                            _vm.tooltips = $$v
                          },
                          expression: "tooltips",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("settings.tooltips"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-switch", {
                        attrs: { inset: "", color: "success" },
                        model: {
                          value: _vm.hints,
                          callback: function ($$v) {
                            _vm.hints = $$v
                          },
                          expression: "hints",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("settings.connect"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.cancelSettings } },
                [_vm._v(" " + _vm._s(_vm.$t("ui.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.saveSettings },
                },
                [_vm._v(" " + _vm._s(_vm.$t("ui.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }