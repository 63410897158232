<template>
  <!-- question card -->
  <v-card
    class="c-question-card"
    elevation="4"
  >
    <!-- context -->
    <div class="c-context">
      <ClampedText
        v-if="hasContext"
        class="c-context-text c-text pa-4"
        :max-lines="5"
      >
        {{ question.context }}
      </ClampedText>
    </div>

    <div v-if="hasReference" class="px-4 py-0 text-center">
      <PopupWindow
        :frame="question.reference.frame || false"
        :label="question.reference.name || question.reference.url"
        :src="question.reference.url"
      />
    </div>

    <!-- question -->
    <div class="c-question">
      <v-card-title class="c-question-text c-text font-italic text-h6 py-2">
        Q{{ qno }}: {{ question.query || question.text }}
      </v-card-title>

      <div class="c-question-content d-flex flex-wrap ma-auto">
        <!-- visuals -->
        <v-card-text
          v-if="question.image"
          class="c-figure c-half ma-auto"
        >
          <v-img
            :key="qno"
            class="ma-auto"
            contain
            :src="question.image.url"
          />
          <div class="text-center ma-auto">
            {{ question.image?.caption }}
          </div>
        </v-card-text>

        <!-- choices -->
        <v-card-text class="c-choices c-half">
          <p class="c-prompt pb-0 mt-0 text-left">
            {{ prompt }}
          </p>
          <v-list class="pa-0" shaped>
            <v-list-item-group
              v-model="selected"
              class="c-choice-ol"
              color="accent"
              :multiple="isMulti"
            >
              <v-list-item
                v-for="(choice, index) in choices"
                :key="index"
                class="c-choice-li"
                :class="{'c-correct': choice.correct, 'c-incorrect': !choice.correct, 'c-review': review }"
                active-class="c-selected"
                :disabled="review"
              >
                <template #default="{ active }">
                  <v-list-item-icon class="ma-0 pt-3 c-choice-text">
                    {{ index | charIndex }}.
                 </v-list-item-icon>

                  <v-list-item-content class="c-choice-text c-text">
                    {{ choice.text }}
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-if="isMulti"
                      :disabled="review"
                      :input-value="active"
                      color="accent"
                    />
                    <v-radio
                      v-else
                      :read-only="review"
                      :off-icon="active ? '$radioOn' : '$radioOff'"
                      color="accent"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </div>
    </div>

    <!-- review actions -->
    <v-card-actions
      v-if="review"
      class="d-flex justify-space-around pa-4 pt-0"
    >
      <v-btn
        v-if="!isMobile"
        class="c-btn-invisible"
      >
        {{ $t('quiz.action.return') }}
      </v-btn>

      <v-spacer v-if="!isMobile" />

      <v-btn
        class="mr-4"
        color="primary"
        :disabled="first"
        @click="$emit('prev', selected)"
      >
        {{ $t('quiz.action.prev') }}
      </v-btn>

      <v-btn
        class="mr-4"
        color="primary"
        @click="$emit('next', selected)"
      >
      {{ $t('quiz.action.next') }}
      </v-btn>

      <v-spacer />

      <v-btn
        color="primary"
        @click="$emit('exit')"
      >
        {{ $t('quiz.action.return') }}
      </v-btn>
    </v-card-actions>

    <!-- answer actions -->
    <v-card-actions
      v-else
      class="d-flex justify-center pa-4 pt-0"
    >
      <v-btn
        class="mr-4"
        color="primary"
        :disabled="first"
        @click="$emit('prev', selected)"
      >
        {{ $t('quiz.action.prev') }}
      </v-btn>

      <v-btn
        class="mr-4"
        color="primary"
        @click="$emit('next', selected)"
      >
        {{ next }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ClampedText from '@/components/base/ClampedText'
import PopupWindow from '@/components/base/PopupWindow'

export default {
  name: 'QuizQuestionCard',

  components: {
    ClampedText,
    PopupWindow
  },

  filters: {
    charIndex: function (i) {
      return String.fromCharCode(97 + i)
    }
  },

  props: {
    qno: {
      type: Number,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    choices: {
      type: Array,
      required: true
    },

    answer: {
      type: [Number, Array],
      required: false,
      default: null
    },

    first: {
      type: Boolean,
      required: false,
      default: false
    },

    last: {
      type: Boolean,
      required: false,
      default: false
    },

    review: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      selected: this.answer
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    isMulti() {
      return this.choices.filter((choice) => choice.correct).length > 1
    },

    hasContext() {
      return this.question.context?.length > 0
    },

    hasReference() {
      return this.question.reference?.url?.length > 0
    },

    prompt() {
      return this.isMulti ? this.$t('quiz.prompt.multiple') : this.$t('quiz.prompt.single')
    },

    next() {
      return this.selected != null
        ? this.last
          ? this.$t('quiz.action.submit')
          : this.$t('quiz.action.next')
        : this.$t('quiz.action.skip')
    }
  },

  methods: {
    viewReference(url) {
      window.open(url, '_blank', 'width=300, height=250')
    }
  }
}
</script>

<style lang="css" scoped>
.c-question-card {
  height: 100%;
  width: 100%;
  white-space: normal;
}
.c-xtext {
  white-space: pre-wrap;
}
.c-context-text,
.c-question-text {
  font-size: 1.1rem;
}
.c-half {
  flex: 1 1 50%;
}
.c-figure {
  max-width: 350px;
}
.c-prompt {
  font-size: 1.25rem;
}
.c-choice-ol {
  list-style-type: lower-alpha;
  list-style-position: outside;
}
.c-choice-li {
}
.c-choice-text {
  font-size: 1.25rem;
}
.c-selected {
  background-color: var(--c-primary-light);
}

/* review mode styles */
.c-review.c-correct {
  color: white !important;
  background-color: var(--v-success-base);
}
.c-review.c-correct .v-input {
  color: white !important;
}
.c-review.c-selected.c-incorrect {
  color: white;
  background-color: var(--v-error-base);
}
.c-review.c-selected.c-incorrect .v-input {
  color: white;
}
</style>
