import { memoryStorage } from './memory.js'

function isLocalStorage() {
  try {
    const testKey = 'Lumenii:test'
    window.localStorage.setItem(testKey, 'success')
    window.localStorage.getItem(testKey)
    window.localStorage.removeItem(testKey)
    return true
  } catch (e) {
    console.warn('[store]: Local storage not available.  Using memory instead.')
    return false
  }
}

// if localStorage is not available, fallback to memory
const storage = isLocalStorage() ? window.localStorage : memoryStorage

export function getItem(key, defaultValue = null) {
  try {
    return storage.getItem(key) || defaultValue
  } catch (e) {
    console.error(
      `[store]: Unable to retrieve storage item with key=${key}.  ${e.message}.  Using default value.`
    )
    return defaultValue
  }
}

export function setItem(key, value) {
  try {
    storage.setItem(key, value)
    return true
  } catch (e) {
    console.error(`[store]: Unable to set storage item with key=${key}.  ${e.message}.`)
    return false
  }
}

export function removeItem(key) {
  try {
    storage.removeItem(key)
    return true
  } catch (e) {
    console.error(`[store]: Unable to remove storage item with key=${key}.  ${e.message}.`)
    return false
  }
}
