import { circleHandler } from './circleHandler'
import { freehandHandler } from './freehandHandler'
import { lineHandler } from './lineHandler'
import { rectangleHandler } from './rectangleHandler'
import { textHandler } from './textHandler'
import TOOLNAMES from './names'

export default {
  circle: { name: TOOLNAMES.circle, handler: circleHandler },
  freehand: { name: TOOLNAMES.freehand, handler: freehandHandler },
  line: { name: TOOLNAMES.line, handler: lineHandler },
  rectangle: { name: TOOLNAMES.rectangle, handler: rectangleHandler },
  text: { name: TOOLNAMES.text, handler: textHandler }
}
