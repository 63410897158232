var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("canvas", {
        ref: "mainCanvas",
        staticClass: "c-canvas",
        class: { "c-canvas--active": _vm.activated },
        on: {
          mousedown: _vm.onMouseDown,
          mouseup: _vm.onMouseUp,
          mousemove: _vm.onMouseMove,
        },
      }),
      _c("canvas", {
        ref: "tempCanvas",
        staticClass: "c-canvas",
        class: { "c-canvas--active": _vm.activated },
        on: {
          mousedown: _vm.onMouseDown,
          mouseup: _vm.onMouseUp,
          mousemove: _vm.onMouseMove,
        },
      }),
      _vm.activated
        ? _c(
            "v-footer",
            {
              staticClass: "c-footer d-flex",
              attrs: {
                app: "",
                color: "rgba(0,0,0,0)",
                fixed: "",
                inset: "",
                padless: "",
                tile: "",
              },
            },
            [
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "c-canvas__tools text-center mb-1" },
                [
                  _vm.activated
                    ? _c("DrawControls", {
                        on: {
                          clear: _vm.clear,
                          capture: function ($event) {
                            return _vm.$emit("capture")
                          },
                          download: function ($event) {
                            return _vm.$emit("download")
                          },
                          redo: _vm.redo,
                          undo: _vm.undo,
                          "update:shape": function ($event) {
                            return _vm.setActiveShape($event)
                          },
                          "update:option": function ($event) {
                            return _vm.updateOption($event)
                          },
                          erase: function ($event) {
                            return _vm.setEraseMode($event)
                          },
                          text: function ($event) {
                            return _vm.setTextMode($event)
                          },
                        },
                        model: {
                          value: _vm.controls,
                          callback: function ($$v) {
                            _vm.controls = $$v
                          },
                          expression: "controls",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }