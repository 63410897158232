import { subscribe, unsubscribe } from '@/pwa.js'

export default {
  computed: {
    pushMixin_appName() {
      return this.portal?.title?.[this.locale] || this.$appConfig.appName
    },

    pushMixin_assetRoot() {
      return this.$store.state.orgStore.assetRoot
    },

    pushMixin_hasPermission() {
      return this.$store.state.appStore.notification.permission === 'granted'
    },

    pushMixin_isPushEnabled() {
      return 'serviceWorker' in navigator && 'PushManager' in window
    },

    pushMixin_isiOS() {
      return (
        /iPhone|iPad|iPod/i.test(window.navigator.userAgent) ||
        (window.navigator.userAgent.includes('Macintosh') && window.navigator.maxTouchPoints >= 1)
      )
    },

    pushMixin_orgName() {
      return this.$store.state.orgStore.orgName
    }
  },

  methods: {
    pushMixin_pushNotification({ title, body, type, tag }) {
      if (this.pushMixin_hasPermission) {
        const options = {
          badge: this.pushMixin_getBadge(type),
          body: body,
          icon: `${this.pushMixin_assetRoot}/icons/notifications-192x192.png`,
          tag: tag
        }

        new Notification(title, options)
      }
    },

    pushMixin_getBadge(type) {
      const badges = {
        info: require(`@/assets/images/badges/info.png`),
        warning: require(`@/assets/images/badges/warning.png`),
        error: require(`@/assets/images/badges/error.png`),
        failed: require(`@/assets/images/badges/failed.png`),
        success: require(`@/assets/images/badges/success.png`)
      }
      return badges[type] || ''
    },

    async pushMixin_monitorPermission(q, cb) {
      // add a listener
      q.addEventListener('change', () => {
        console.warn('[pushMixin]: Notification permission changed:', q.state)
        // 'granted', 'denied', 'prompt'
        cb(q.state)
      })
    },

    async pushMixin_getPermissionState(cb) {
      // 'granted', 'denied' or 'prompt'
      try {
        if (navigator.permissions && navigator.permissions.query) {
          const queryResult = await navigator.permissions.query({ name: 'notifications' })
          console.warn('[pushMixin]: Notification permission=', queryResult.state)

          this.pushMixin_monitorPermission(queryResult, cb)

          return queryResult.state === 'prompt' && this.pushMixin_isiOS
            ? this.$store.state.appStore.notification.permission
            : queryResult.state
        } else {
          return 'denied'
        }
      } catch (error) {
        console.error('[pushMixin]: Error capturing permission.', error)
        return 'denied'
      }
    },

    async pushMixin_requestPermission() {
      try {
        if (this.permission === 'prompt') {
          const requestResult = await Notification.requestPermission()
          // requestResult: 'granted', 'denied', 'default'
          this.permission = requestResult === 'default' ? 'prompt' : requestResult
        }

        return this.permission
      } catch (error) {
        console.error('[pushMixin]: Error requesting permission.', error)
      }
    },

    async pushMixin_subscribe() {
      subscribe()
    },

    async pushMixin_unsubscribe() {
      const result = await navigator.permissions.revoke({ name: 'notification' })
      this.permission = result.state
      unsubscribe()
      console.log('[pushMixin]: Permission revoked.', result)
    }
  }
}
