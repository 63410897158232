var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card d-flex flex-column mx-auto" },
    [
      _c("v-skeleton-loader", {
        staticClass: "c-client-skeleton pb-5",
        attrs: {
          elevation: "2",
          type: "avatar-big, list-item-avatar@4",
          types: { "avatar-big": "avatar" },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }