<template>
  <v-dialog
    content-class="c-permission ma-0 g-skinny-scrollbars"
    dialog-transition="dialog-top-transition"
    hide-overlay
    max-width="300"
    persistent
    :value="showRequest"
  >
    <v-card class="c-card">
      <div class="d-flex align-center justify-center pa-4">
        <v-img :src="logo" max-width="50" />
      </div>

      <v-card-title class="c-title pt-0">
        {{ $t('app.request.title') }}
      </v-card-title>

      <v-card-text class="c-text pb-2">
        {{ $t('app.request.text') }}
      </v-card-text>

      <v-card-actions class="justify-space-around">
        <v-btn width="100" @click="cancel">
          {{ $t('app.request.cancel') }}
        </v-btn>
        <v-btn color="primary" width="100" @click="allow">
          {{ $t('app.request.allow') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import pushMixin from '@/mixins/pushMixin.js'

export default {
  name: 'AppNotificationPermission',

  components: {},

  mixins: [pushMixin],

  data: function () {
    return {
      permission: null,
      showRequest: false
    }
  },

  computed: {
    hasPermission() {
      return this.permission === 'granted'
    },

    logo() {
      return this.$store.state.orgStore.orgLogo
    },

    welcomeNotification() {
      const appName = this.pushMixin_appName
      const orgName = this.pushMixin_orgName
      return {
        title: this.$t('app.name', { appName, orgName }),
        body: this.$t('app.request.welcome'),
        type: 'info',
        tag: 'welcome'
      }
    }
  },

  mounted: async function () {
    this.permission = await this.pushMixin_getPermissionState((perm) => (this.permission = perm))

    if (this.permission === 'prompt') {
      const lastRequestDate = this.$store.state.appStore.notification.requestDate
      const ONE_DAY = 24 * 60 * 60 * 1000
      const ONE_WEEK = 7 * ONE_DAY // only make a request once a week
      if (lastRequestDate === 0 || Date.now() - lastRequestDate > ONE_WEEK) {
        setTimeout(() => (this.showRequest = true), 5000)
      }
    }
  },

  methods: {
    ...mapActions('appStore', ['updateNotificationPermission']),

    async allow() {
      this.permission = await this.pushMixin_requestPermission()

      // update timestamp
      this.updateNotificationPermission(this.permission)
      this.showRequest = false

      // send a welcome message
      if (this.permission === 'granted') {
        await this.pushMixin_subscribe()
        this.pushMixin_pushNotification(this.welcomeNotification)
      }
    },

    cancel() {
      // update timestamp
      this.updateNotificationPermission(this.permission)
      this.showRequest = false
    }
  }
}
</script>

<style lang="css" scoped>
:deep(.c-permission) {
  position: fixed;
  top: 0;
}
.c-title {
  line-height: 1.25rem;
}
.c-text {
  line-height: 1rem;
  font-weight: 400;
}
</style>
