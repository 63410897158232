var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-widget-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "c-widget px-2 pb-2",
        class: [
          _vm.isDark ? "c-dark" : "c-light",
          { "c-outlined": _vm.outlined },
        ],
      },
      [
        _c(
          "WidgetHeader",
          {
            attrs: { background: _vm.outlined, closeable: _vm.closeable },
            on: {
              close: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }