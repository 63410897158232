<template>
  <div>
    <v-card class="c-result-card pa-4 mb-4" elevation="4">
      <!-- summary -->
      <v-card-title class="d-flex justify-center pt-0 pb-3">
        <v-icon v-if="scoreGrade >= passingGrade" color="success" x-large>
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="error" x-large>
          mdi-alpha-x-circle
        </v-icon>
        <div class="c-score">
          {{ $t('quiz.status.score') }} {{ formattedScore() }} / {{ quizLength }}
        </div>
      </v-card-title>

      <!-- actions -->
      <v-card-actions class="c-actions py-0">
        <v-btn class="mr-4" color="primary" @click="$emit('review')">
          {{ $t('quiz.action.review') }}
        </v-btn>
        <v-btn v-if="quiz.reference" class="mr-4" color="primary" @click="$emit('learn')">
          {{ $t('quiz.action.learn') }}
        </v-btn>
        <v-btn color="primary" @click="$emit('retake')">
          {{ $t('quiz.action.retake') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <QuizSourceCard
      v-if="source"
      :source="source"
    />

    <QuizReferencesCard
      v-if="references.length > 0"
      :references="references"
    />
  </div>
</template>

<script>
import QuizReferencesCard from '@/components/quiz/QuizReferencesCard'
import QuizSourceCard from '@/components/quiz/QuizSourceCard'

export default {
  name: 'QuizResultCard',

  components: {
    QuizReferencesCard,
    QuizSourceCard
  },

  props: {
    quiz: {
      type: Object,
      required: true
    },

    source: {
      type: Object,
      required: false,
      default: null
    },

    references: {
      type: Array,
      required: false,
      default: () => []
    },

    userResponses: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      quizCorrectAnswers: 0,
      userCorrectAnswers: 0,
      scoreGrade: 0
    }
  },

  computed: {
    passingGrade() {
      return this.quiz.pass || this.quizCorrectAnswers * 0.6
    },

    quizLength() {
      return this.quiz.questions.length
    }
  },

  created: function () {
    this.scoreGrade = this.calculateScore()
  },

  methods: {
    calculateScore() {
      this.quizCorrectAnswers = 0
      this.userCorrectAnswers = 0

      return this.userResponses.reduce((score, answer, i) => {
        if (Array.isArray(answer)) {
          // multiple correct answers exist for this question

          // count the number of correct answers for this question
          const quizCorrectAnswers = this.quiz.questions[i].responses.filter(
            (response) => response.correct
          ).length
          this.quizCorrectAnswers += quizCorrectAnswers

          // count the number of selected correct answers for this question
          const userCorrectAnswers = answer.filter(
            (response) => this.quiz.questions[i].responses[response].correct
          ).length
          this.userCorrectAnswers += userCorrectAnswers

          // accumulate the score
          score += userCorrectAnswers / quizCorrectAnswers
        } else {
          // only a single correct answer exists
          this.quizCorrectAnswers += 1
          if (answer != null && this.quiz.questions[i].responses[answer].correct) {
            this.userCorrectAnswers += 1
            score += 1
          }
        }
        return score
      }, 0)
    },

    formattedScore() {
      return this.scoreGrade.toFixed(2).replace(/[.,]00$/, '')
    }
  }
}
</script>

<style lang="css" scoped>
.c-result-card {
  text-align: center;
  width: 100%;
}

.c-actions {
  display: flex;
  justify-content: center;
}
</style>
