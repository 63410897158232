var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "c-link",
      attrs: { href: _vm.href, target: "_blank", rel: "noopener noreferrer" },
    },
    [
      _c("v-img", {
        staticClass: "c-image",
        attrs: { src: _vm.src, alt: _vm.alt, contain: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }