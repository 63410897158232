<template>
  <PortalWindow
    v-model="portal"
    class="c-portal"
    :path="jitsiPath"
    :title="$t('meeting.docTitle')"
    @closed="endMeeting"
    @reset="endMeeting"
  >
    <div>
      {{ 'hello' }}
    </div>
  </PortalWindow>
</template>

<script>
import CryptoService from '@/services/cryptoService'
// import JitsiMeeting from '@/components/meetings/JitsiMeeting'
import PortalWindow from '@/components/base/PortalWindow'

export default {
  name: 'MeetingWindow',

  components: {
    // JitsiMeeting,
    PortalWindow
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      required: true
    },

    jwt: {
      type: String,
      required: true
    },

    room: {
      type: String,
      required: true
    },

    subject: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      isLoading: true,
      portal: false
    }
  },

  computed: {
    jitsiPath() {
      const cryptoService = new CryptoService()
      const encryptedRoom = cryptoService.encrypt(this.room)
      const encodedSubject = encodeURIComponent(this.subject)
      return `/meet/${encryptedRoom}?subject=${encodedSubject}&token=${this.jwt}`
    }
  },

  watch: {
    open: {
      immediate: false, // initial response handled via created hook
      handler(newValue, _oldValue) {
        console.debug('[MeetingWindow]: open=', newValue)
        this.portal = newValue
      }
    }
  },

  created: function () {
    this.portal = this.open
  },

  mounted: function () {
    console.debug('[MeetingWindow]: mounted and portal=', this.portal)
  },

  methods: {
    startMeeting() {
      console.debug('[MeetingWindow]: meeting started!')
      this.portal = true
      this.$emit('started')
    },

    endMeeting() {
      console.debug('[MeetingWindow]: meeting ended!')
      this.portal = false
      this.$emit('update:open', false)
      this.$emit('ended')
    }
  }
}
</script>

<style lang="css" scoped>
.c-fullheight {
  height: 100%;
}

.c-portal {
  background-color: var(--v-background-base);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
}
</style>
