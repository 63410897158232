export default {
  /* Notes:
   *  1)Vue variables can't be prefixed with $ or _
   *  2)Computed ensures reactive translations occur
   */
  computed: {
    postMixin_locale() {
      return this.$store.state.i18nStore.locale
    },

    postMixin_filters() {
      return [
        {
          field: 'postedBy',
          label: this.$t('post.byPoster'),
          isMultiValued: false,
          requiresTranslation: false
        },
        {
          field: 'topics',
          label:
            this.$store.state.contentStore.taxonomyTitles?.topic?.[this.postMixin_locale] ||
            this.$t('content.topic'),
          isMultiValued: true,
          requiresTranslation: true
        },
        {
          field: 'genre',
          label: this.$t('post.byType'),
          isMultiValued: false,
          requiresTranslation: false,
          labelRequiresTranslation: true,
          labelPrefix: 'post.type'
        }
      ]
    },

    postMixin_sorts() {
      return [
        {
          text: this.$t('post.sortOptions.sortByPublishDateDesc'),
          value: 'sortByPublishDateDesc',
          default: true
        },
        {
          text: this.$t('post.sortOptions.sortByPublishDateAsc'),
          value: 'sortByPublishDateAsc'
        },
        {
          text: this.$t('post.sortOptions.sortByAuthorAsc'),
          value: 'sortByPosterAsc'
        },
        {
          text: this.$t('post.sortOptions.sortByAuthorDesc'),
          value: 'sortByPosterDesc'
        },
        {
          text: this.$t('post.sortOptions.sortByTitleAsc'),
          value: 'sortByTitleAsc'
        },
        {
          text: this.$t('post.sortOptions.sortByTitleDesc'),
          value: 'sortByTitleDesc'
        }
      ]
    }
  }
}
