import { createAuth0Client, PopupCancelledError, PopupTimeoutError } from '@auth0/auth0-spa-js'
import auth0Config from '@/services/agents/auth0/auth0Config.js'
import {
  AuthenticationError,
  // AuthenticationCancelledError,
  // AuthorizationError,
  // AuthorizationRequiredError,
  // PopupError,
  TokenError
} from '@/config/authConfig.js'

class Auth0Agent {
  constructor() {
    // client
    this.auth0Client = null
    this.loading = true

    // state
    this.authenticated = false
    this.tenant = null
    this.user = null

    // tokens (all are JWT-based)
    this.accessToken = null // from IdP (to call /userinfo endpoint)
    this.idToken = null // from IdP
  }

  async initialize(dynamicConfig) {
    const config = {
      ...auth0Config,
      ...dynamicConfig
    }

    this.auth0Client = await createAuth0Client(config)
  }

  /*
   * Response Handling Methods
   */

  async handleRedirect() {
    this.loading = true
    this.authenticated = false
    this.user = null

    try {
      // If the user is returning to the app after authentication..
      const search = window.location.search

      if (search.includes('state=') && search.includes('code=')) {
        // handle the redirect and retrieve tokens
        const { appState } = await this.auth0Client.handleRedirectCallback()
        return appState
      } else if (search.includes('error=')) {
        const qParams = new URLSearchParams(search)
        throw new AuthenticationError(qParams.get('error'))
      }
    } catch (e) {
      return null
    } finally {
      this.user = await this.auth0Client.getUser()
      this.authenticated = await this.auth0Client.isAuthenticated()
      this.loading = false
    }
  }

  /*
   * Login/Logout Methods
   */

  constructLoginRequest(options) {
    return {
      appState: options.state || {},
      authorizationParams: {
        prompt: options.prompt || 'select_account',
        domain_hint: options.domainHint || '',
        login_hint: options.loginHint || '',
        id_token_hint: options.idTokenHint || '',
        scope: options.scopes || 'openid', // space separated string
        redirect_uri: window.location.origin,
        ui_locales: options.locale || 'en'
      }
    }
  }

  async loginWithPopup(options) {
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }

    try {
      await this.auth0Client.loginWithPopup(loginRequest)
      return false // do not retry
    } catch (e) {
      // handle case where user cancels authentication
      if (e.error === 'access_denied') {
        return true // try again
      }

      if (e instanceof PopupCancelledError || e instanceof PopupTimeoutError) {
        e.popup.close()
        return true // try again
      }

      console.error('[auth0Agent]: LoginWithPopup error.', e)
      throw new AuthenticationError(e)
    } finally {
      this.user = await this.auth0Client.getUser()
      this.authenticated = await this.auth0Client.isAuthenticated()
    }
  }

  /** Authenticates the user using the redirect method */
  async loginWithRedirect(options) {
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }

    try {
      await this.auth0Client.loginWithRedirect(loginRequest)
      return false
    } catch (e) {
      // handle case where user cancels authentication
      if (e.error === 'access_denied') {
        return true // try again
      }

      console.error('[auth0Agent]: LoginWithRedirect error.', e)

      throw new AuthenticationError(e)
    }
  }

  /** Authenticates the user using the redirect method */
  async loginWithSSO(options) {
    const loginRequest = {
      ...this.constructLoginRequest(options)
    }

    try {
      loginRequest.prompt = 'none'
      await this.auth0Client.loginWithPopup(loginRequest)
      return false // do not retry
    } catch (e) {
      if (this.isRetriable(e)) {
        loginRequest.prompt = 'select_account'
        const retry = await this.loginWithPopup(loginRequest)
        return retry
      } else {
        console.error('[auth0Agent]: LoginWithSSO error.', e)
        throw new AuthenticationError(e)
      }
    }
  }

  /** Logs the user out and removes their session on the authorization server */
  async logout(options) {
    const logoutRequest = {
      logoutParams: {
        // federated: options.federated, // logout from IdP
        returnTo: options.redirectURI || '/logout'
      }
      // openURL: async (url) => { window.location.replace(url) }, // can use instead of returnTo
    }

    try {
      await this.auth0Client.logout(logoutRequest)
      this.isAuthenticated = await this.auth0Client.isAuthenticated()
    } catch (e) {
      console.log('[auth0Agent]: Error during logout.', e)
      throw e
    }
  }

  isAuthenticated() {
    return this.authenticated
  }

  isRetriable(e) {
    const retriableErrors = ['login_required', 'consent_required', 'interaction_required']
    if (retriableErrors.includes(e.error)) {
      return true
    }
    return false
  }

  /*
   * User Methods
   */

  getUserId() {
    return this.getUserEmail()?.toLowerCase()
  }

  getUserEmail() {
    return this.user?.email_verified || this.user?.email
  }

  getUserDisplayName() {
    return this.user?.name
  }

  getUserUniqueId() {
    return this.user?.sub
  }

  getUserPicture() {
    return this.user?.picture
  }

  getUserSubject() {
    return this.user?.sub
  }

  /*
   * Token Methods
   */

  /** Returns all the claims present in the ID token */
  async getIdTokenClaims() {
    try {
      await this.auth0Client.getIdTokenClaims()
    } catch (e) {
      throw new TokenError(e)
    }
  }

  async acquireTokens(options) {
    try {
      await this.auth0Client.getTokenSilently(options)
    } catch (e) {
      if (this.isRetriable(e)) {
        await this.auth0Client.getTokenWithPopup(options)
      } else {
        throw new TokenError(e)
      }
    }
  }
}

export const createAuth0Agent = async (options) => {
  const agent = new Auth0Agent()
  await agent.initialize(options)
  return agent
}
