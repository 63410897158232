<template>
  <v-navigation-drawer
    ref="drawer"
    class="c-drawer"
    v-bind="$attrs"
    bottom
    fixed
    :floating="isMobile"
    :width="drawerWidth"
    v-on="$listeners"
  >
    <div ref="header" class="c-drawer__header">
      <slot name="header"/>
    </div>
    <div ref="body" class="c-drawer__body">
      <slot name="default" />
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'ResizableDrawer',

  props: {
    initialWidth: {
      type: [Number, String],
      required: false,
      default: 256
    }
  },

  data: function () {
    return {
      drawerElement: null,
      borderElement: null,

      drawerWidth: this.initialWidth,
      borderWidth: 8
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },

  mounted: function () {
    // drawer details
    this.drawerElement = this.$refs.drawer.$el
    this.drawerDirection = this.drawerElement.classList.contains('v-navigation-drawer--right')
      ? 'right'
      : 'left'

    // border details
    this.borderElement = this.$refs.drawer.$el.querySelector('.v-navigation-drawer__border')
    this.borderElement.style.width = this.borderWidth + 'px'

    this.addEvents()
  },

  beforeDestroy: function () {
    this.removeEvents()
  },

  methods: {
    addEvents() {
      // look for mousedown events on the border itself
      this.borderElement.addEventListener('mousedown', this.onMouseDown, false)
      // look for mouseup events anywere they occur
      document.addEventListener('mouseup', this.onMouseUp, false)
    },

    removeEvents() {
      this.borderElement.removeEventListener('mousedown', this.onMouseDown, false)
      document.removeEventListener('mouseup', this.onMouseUp, false)
      document.removeEventListener('mousemove', this.onResizeDrawer, false)
    },

    onMouseDown(_e) {
      this.drawerElement.style.transition = 'initial'
      this.$emit('resizing', true)
      document.addEventListener('mousemove', this.onResizeDrawer, false)
    },

    onMouseUp(_e) {
      this.$emit('resizing', false)
      this.drawerElement.style.transition = ''
      const width = parseInt(this.drawerElement.style.width, 10)
      // prevent drawer width going below border width
      this.drawerWidth = width < this.borderWidth ? this.borderWidth : width
      document.removeEventListener('mousemove', this.onResizeDrawer, false)
    },

    onResizeDrawer(e) {
      const width =
        this.drawerDirection === 'right' ? document.body.scrollWidth - e.clientX : e.clientX
      this.drawerWidth = width
      // this.drawerElement.style.width = width + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.c-drawer {
  /* drawer border */
  &.v-navigation-drawer--open {
    :deep(.v-navigation-drawer__content) {
      margin-left: 8px;
    }

    :deep(.v-navigation-drawer__border) {
      z-index: 2;

      &::after {
        position: absolute;
        content: '\02551';
        top: 50%;
        left: -50%;
        transform: translateY(-50%);
      }

      &:hover {
        cursor: col-resize;
        background-color: var(--v-primary-base);
      }
    }
  }

  /* mobile drawer (no border; from bottom) */
  &.v-navigation-drawer--is-mobile.v-navigation-drawer--open {
    max-height: 60%;

    :deep(.v-navigation-drawer__content) {
      margin-left: 0px;
    }

    :deep(.v-navigation-drawer__border) {
      width: 0 !important;

      &::after {
        content: '';
      }
    }
  }
}

/* slots */
.c-drawer__header {
  background-color: var(--v-background-base);
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
}
.c-drawer__body {
}
</style>
