import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export class ComponentsPlugin {
  static install(Vue, _options) {
    const components = {}
    const requireComponent = require.context('@/components/global', true, /\.vue$/)

    // list and register all components in the @/components/global directory
    requireComponent.keys().forEach((fileName) => {
      const componentConfig = requireComponent(fileName)

      const componentName = upperFirst(
        camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
      )

      const componentModule = componentConfig.default || componentConfig
      components[componentName] = componentModule

      Vue.component(componentName, componentModule)
    })
  }
}
