<template>
  <v-clamp
    autoresize
    :expanded="isExpanded"
    :max-lines="maxLines"
    @clampchange="$emit('clamped', $event)"
    @update:expanded="isExpanded = $event"
  >
    <slot></slot>
    <template #after="{ clamped, expanded, toggle }">
      <v-btn
        v-if="clamped || expanded"
        small
        text
        @click.stop="toggle"
      >
        {{ clamped ? $t('ui.more') : $t('ui.less') }}
      </v-btn>
    </template>
  </v-clamp>
</template>

<script>
import VClamp from 'vue-clamp'

export default {
  name: 'ClampedText',

  components: {
    'v-clamp': VClamp
  },

  props: {
    expand: {
      type: Boolean,
      required: false,
      default: false
    },

    maxLines: {
      type: Number,
      required: false,
      default: 3
    }
  },

  data: function () {
    return {
      isExpanded: this.expand
    }
  },

  methods: {
    onToggle({ clamped, toggle }) {
      this.$emit('clamped', clamped)
      toggle()
    }
  }
}
</script>

<style lang="css" scoped>
.c-clamp {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

