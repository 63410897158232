import store from '@/store'
import defaultOrg from '@/config/defaultOrg.json'

export const fetchOrgs = async () => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/orgs`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[orgService]:', error)
    return []
  }
}

export const fetchOrg = async (orgKey) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/org/${orgKey}`)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error(`[orgService]: Unable to retrieve org=${tenantKey}/${orgKey}. ${error}`)
    return defaultOrg
  }
}

export const fetchOrgPersons = async ({ orgKey, portalKey }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/org/${orgKey}/portal/${portalKey}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[orgService]:', error)
    return []
  }
}
