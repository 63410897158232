export default {
  // authAgent: process.env.VUE_APP_AUTH_AGENT || 'msal',
  // authStyle: process.env.VUE_APP_AUTH_STYLE || 'popup',
  authAgent: 'msal',
  authStyle: 'redirect',
  scopes: [
    'openid', // required
    // 'email', // only used by the https://graph.microsoft.com/oidc/userinfo endpoint
    // 'profile', // only used by the https://graph.microsoft.com/oidc/userinfo endpoint
    'offline_access' // returns a refresh token
  ]
}

// authentication styles
export const AUTH_STYLE_POPUP = 'popup'
export const AUTH_STYLE_REDIRECT = 'redirect'
export const AUTH_STYLE_SSO = 'sso'

// exceptions
export class AuthenticationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

export class AuthenticationCancelledError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthenticationCancelledError'
  }
}

// user is defintely not authorized or an error prevented verification
export class AuthorizationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthorizationError'
  }
}

// user is definitely not authorized and should be prompted to join a tenant
export class AuthorizationRequiredError extends Error {
  constructor(message) {
    super(message)
    this.name = 'AuthorizationRequiredError'
  }
}

export class PopupError extends Error {
  constructor(message) {
    super(message)
    this.name = 'PopupError'
  }
}

export class TokenError extends Error {
  constructor(message) {
    super(message)
    this.name = 'TokenError'
  }
}
