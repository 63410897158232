export default {
  /* common mutations */

  syncState(state, newState) {
    state.posts = newState.posts || []
    if (state.posts.length > 0) {
      state.primed = true
    }
  },

  /* specific mutations */

  setPosts(state, posts) {
    state.posts = [...posts]
  },

  setPost(state, post) {
    // check if the post is already in the state
    const postId = post.id
    const index = state.posts.findIndex((p) => p.id === postId)

    index < 0
      ? state.posts.push(post) // append
      : state.posts.splice(index, 1, post) // replace
  },

  setFetched(state, isFetched) {
    state.fetched = isFetched
  },

  setLoading(state, { loading, promise = null }) {
    state.loading = loading
    state.promise = promise
  },

  setPrimed(state, isPrimed) {
    state.primed = isPrimed
  }
}
