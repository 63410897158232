var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-speed-dial",
    {
      staticClass: "c-layout-dial",
      attrs: { direction: "left", transition: "slide-y-transition" },
      on: {
        input: function ($event) {
          return _vm.$emit("status", _vm.isOpen)
        },
      },
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: { class: "primary--text" },
                      expression: "{ class: 'primary--text' }",
                    },
                  ],
                  staticClass: "c-btn",
                  attrs: { fab: "", small: "", text: "" },
                  model: {
                    value: _vm.isOpen,
                    callback: function ($$v) {
                      _vm.isOpen = $$v
                    },
                    expression: "isOpen",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: { "accent--text": _vm.isOpen },
                      attrs: { large: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.isOpen ? "mdi-close" : _vm.currentIcon) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    _vm._l(_vm.layoutOptions, function (option) {
      return _c(
        "v-btn",
        {
          key: option.name,
          staticClass: "c-btn",
          attrs: { fab: "", small: "" },
          on: {
            click: function ($event) {
              return _vm.selectLayout(option)
            },
          },
        },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v(" " + _vm._s(option.icon) + " "),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }