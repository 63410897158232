import Vue from 'vue'
import Vuex from 'vuex'
import { version } from '@@/package.json'
// import client from '@client'
import client from '@/services/mock'

Vue.use(Vuex)

const initialState = {
  version: version,
  online: window.navigator.onLine,
  notices: [],
  notification: {
    permission: 'prompt',
    requestDate: 0
  }
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // state.version = state.version
      state.notification = {
        permission: Notification.permission,
        requestDate: newState.notification.requestDate
      }
    },

    /* specific mutations */

    setOnlineStatus(state, online) {
      state.online = online
    },

    setNotices(state, notices) {
      state.notices = notices
    },

    setNotificationPermission(state, { permission, requestDate }) {
      state.notification.permission = permission
      state.notification.requestDate = requestDate
    },

    setVersion(state, version) {
      state.version = version
    }
  },

  actions: {
    /* common actions */

    restoreState({ commit }, { sessionState }) {
      commit('setNotificationPermission', sessionState.notification)
    },

    /* specific actions */

    reportState({ state }) {
      console.info('[appStore]: Version=', state.version)
      console.info('[appStore]: Initially ' + (state.online ? 'on' : 'off') + 'line.')
    },

    updateOnlineStatus({ commit }, onlineStatus) {
      commit('setOnlineStatus', onlineStatus)
    },

    fetchNotices({ commit }) {
      return client.fetchNotices().then((notices) => {
        commit('setNotices', notices)
        return notices
      })
    },

    updateNotices({ commit }, { notices }) {
      commit('setNotices', notices)
    },

    updateNotificationPermission({ commit }, permission) {
      const notification = {
        permission: permission,
        requestDate: Date.now()
      }
      commit('setNotificationPermission', notification)
    },

    updateVersion({ commit }, version) {
      commit('setVersion', version)
    }
  }
}
