var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app-layout",
      style: _vm.bottomHeightStyle,
      attrs: { id: "app-layout" },
    },
    [
      _c("AppSystemBar", { attrs: { message: _vm.message } }),
      !_vm.isReallyMobile
        ? _c("AppSideBar", {
            attrs: { show: _vm.showMenu },
            on: {
              width: function ($event) {
                _vm.sideMenuWidth = $event
              },
            },
          })
        : _vm._e(),
      _c("AppTopBar", {
        attrs: { menu: _vm.showMenu, title: _vm.title, subtitle: _vm.subtitle },
        on: { toggle: _vm.toggleMenu },
      }),
      _c("AppMain", {
        on: {
          count: function ($event) {
            _vm.subtitle = `${$event}`
          },
        },
      }),
      _vm.showFooter ? _c("AppFooter") : _vm._e(),
      _vm.isReallyMobile
        ? _c("AppBottomBar", { attrs: { show: _vm.showMenu } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }