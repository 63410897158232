import { render, staticRenderFns } from "./GLBViewer.vue?vue&type=template&id=7d4028a9&scoped=true"
import script from "./GLBViewer.vue?vue&type=script&lang=js"
export * from "./GLBViewer.vue?vue&type=script&lang=js"
import style0 from "./GLBViewer.vue?vue&type=style&index=0&id=7d4028a9&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4028a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d4028a9')) {
      api.createRecord('7d4028a9', component.options)
    } else {
      api.reload('7d4028a9', component.options)
    }
    module.hot.accept("./GLBViewer.vue?vue&type=template&id=7d4028a9&scoped=true", function () {
      api.rerender('7d4028a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/players/GLBViewer/GLBViewer.vue"
export default component.exports