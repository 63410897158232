import { appVersion } from '@/config/appConfig'
import Router from '@/router'

class Message {
  constructor(type, payload) {
    this.source = 'lumenii-player'
    this.type = type
    this.payload = payload
  }
}

function parse(x) {
  try {
    return JSON.parse(x)
  } catch (e) {
    return x
  }
}

/*
   This service enables Players to receive messages
   from content running inside an iframe
   (sent using assets/client/js/lumenii-library.js)
*/
class WebService {
  /**
   * @constructor
   */
  constructor() {
    this.context = {
      guidance: false,
      version: appVersion
    }
  }

  generateMessage(type, data) {
    return new Message(type, data)
  }

  extractOrigin(src) {
    const domain = new window.URL(src)
    return `${domain.protocol}//${domain.hostname}`
  }

  receiveMessage(e, sourceOrigin) {
    // security check
    //   1. Sandboxed iframes which lack the 'allow-same-origin'
    //      header have 'null' rather than a valid origin.
    // if (e.origin === 'null' && e.source === frame.contentWindow) do ...
    if (e.origin !== window.location.origin && e.origin !== sourceOrigin) return null

    // parse data
    const data = parse(e.data)

    // avoid accidental conflicts with other senders
    if (data.source === 'lumenii-content') {
      console.debug(`[WebService]: received message=${JSON.stringify(data)}`)
      return data
    } else {
      return null
    }
  }

  reply(e, message) {
    console.debug('[WebService]: replying with message=', JSON.stringify(message))
    // send message back to origin (e.source)
    e.source.postMessage(JSON.stringify(message), e.origin)
  }

  sendMessage(targetWindow, message, targetOrigin) {
    console.debug('[WebService]: sending message=', JSON.stringify(message))
    if (targetWindow) {
      // perform unnecessary stringify just to avoid Veeva library exceptions
      targetWindow.postMessage(JSON.stringify(message), targetOrigin)
    }
  }

  async processMessage(e, message) {
    // handshake
    if (message.type === 'handshake') {
      console.debug('[WebService]: received handshake!...replying with context.')
      const replyMessage = new Message('handshake', this.context)
      this.reply(e, replyMessage)
      return
    }

    // handle internal link
    if (message.type === 'link') {
      Router.push({
        name: 'iplayer',
        // params: { slug: message.payload }
        params: { id: message.payload }
      }).catch((error) => console.error('[WebService]: routing error=', error))
    }

    // handle page transition
    if (message.type === 'goto') {
      const src = message.payload
      console.debug(`[WebService]: received goto...${src}`)
    }
  }

  setContext(context) {
    this.context = { ...this.context, ...context }
  }

  onMessage(e, src) {
    const sourceOrigin = this.extractOrigin(src)
    const message = this.receiveMessage(e, sourceOrigin)
    if (message) {
      this.processMessage(e, message)
    }
  }
}

export default WebService
export const webService = new WebService()
