var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "ad", staticClass: "c-ad-spot mx-auto" },
    [
      _vm.hasAds && _vm.showAds
        ? _c(
            "div",
            { staticClass: "c-ad-slot" },
            [
              _vm.stack
                ? _c("AdStack", {
                    staticClass: "c-ad-stack",
                    attrs: { ads: _vm.activeAds, header: _vm.closeable },
                    on: {
                      close: function ($event) {
                        return _vm.onCloseAds()
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "ad",
                          fn: function (props) {
                            return [
                              _c("AdImage", {
                                staticClass: "c-ad-image",
                                attrs: {
                                  "data-ad": props.index,
                                  ad: props.ad,
                                  closeable: false,
                                },
                                on: {
                                  load: function ($event) {
                                    return _vm.onLoadAd($event, props.ad)
                                  },
                                  select: function ($event) {
                                    return _vm.onSelectAd($event)
                                  },
                                },
                              }),
                              props.ad.adItem?.copy
                                ? _c("AdCopy", {
                                    attrs: { copy: props.ad.adItem?.copy },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3885590100
                    ),
                  })
                : _vm._e(),
              _vm.carousel
                ? _c("AdCarousel", {
                    staticClass: "c-ad-carousel",
                    attrs: { ads: _vm.activeAds },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "ad",
                          fn: function (props) {
                            return [
                              _c("AdImage", {
                                staticClass: "c-ad-image",
                                attrs: {
                                  "data-ad": props.index,
                                  ad: props.ad,
                                  closeable: _vm.closeable,
                                  optimize: _vm.optimize,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.onCloseAds()
                                  },
                                  load: function ($event) {
                                    return _vm.onLoadAd($event, props.ad)
                                  },
                                  select: function ($event) {
                                    return _vm.onSelectAd($event)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      284121283
                    ),
                  })
                : _vm._e(),
              _vm.image
                ? _c("AdImage", {
                    staticClass: "c-ad-image",
                    attrs: {
                      "data-ad": 0,
                      ad: _vm.activeAds[0],
                      closeable: _vm.closeable,
                      optimize: _vm.optimize,
                    },
                    on: {
                      close: function ($event) {
                        return _vm.onCloseAds()
                      },
                      load: function ($event) {
                        return _vm.onLoadAd($event, _vm.activeAds[0])
                      },
                      select: function ($event) {
                        return _vm.onSelectAd($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm.popup
                ? _c("AdPopup", {
                    attrs: { ad: _vm.activeAds[0] },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "ad",
                          fn: function (props) {
                            return [
                              _c("AdImage", {
                                staticClass: "c-ad-image",
                                attrs: {
                                  "data-ad": 0,
                                  ad: props.ad,
                                  closeable: _vm.closeable,
                                  optimize: _vm.optimize,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.onCloseAds()
                                  },
                                  load: function ($event) {
                                    return _vm.onLoadAd($event, props.ad)
                                  },
                                  select: function ($event) {
                                    return _vm.onSelectAd($event)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3297213933
                    ),
                  })
                : _vm._e(),
              _vm.rollup && _vm.showHeroAd
                ? _c(
                    "transition",
                    {
                      staticClass: "c-ad-rollup",
                      attrs: { name: "rollup", mode: "out-in" },
                    },
                    [
                      _c("AdImage", {
                        directives: [
                          {
                            name: "touch",
                            rawName: "v-touch",
                            value: {
                              up: () => (_vm.showHeroAd = false),
                            },
                            expression:
                              "{\n          up: () => (showHeroAd = false)\n        }",
                          },
                        ],
                        staticClass: "c-ad-image c-ad-hero",
                        attrs: {
                          "data-ad": 0,
                          ad: _vm.activeAds[0],
                          closeable: false,
                        },
                        on: {
                          load: function ($event) {
                            return _vm.onLoadAd($event, _vm.activeAds[0])
                          },
                          select: function ($event) {
                            return _vm.onSelectAd($event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showModal
        ? _c("AdModal", {
            attrs: { src: _vm.modalSrc },
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }