<template>
  <DraggableDiv class="c-popup c-resizable">
    <template #header="{ start }">
      <!-- header -->
      <v-toolbar class="c-popup-toolbar" dense>
        <v-btn
          class="c-dragger"
          icon
          @click.stop
          @mousedown.native="start"
        >
          <v-icon>mdi-drag</v-icon>
        </v-btn>
        <v-toolbar-title class="px-0">
          {{ hasTitle ? title : $t('model.info') }}
        </v-toolbar-title>
        <v-btn
          class="c-close"
          icon
          @click="$emit('update:show', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <!-- body -->
    <template #default>
      <v-card
        class="c-popup-body g-skinny-scrollbars"
        flat
      >
        <v-card-text class="pa-2 text-left">
          <slot></slot>
        </v-card-text>
      </v-card>
    </template>
  </DraggableDiv>
</template>

<script>
import DraggableDiv from '@/components/base/DraggableDiv'

export default {
  name: 'SceneInfo',

  components: {
    DraggableDiv
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    hasTitle() {
      return this.title.length > 0
    }
  }
}
</script>

<style lang="css" scoped>
/* popup layout */
.c-popup {
  width: 250px;
  max-width: 100%;
  overflow: hidden;
}
.c-popup.c-resizable {
  resize: both;
}
.c-popup-toolbar {
  height: 28px !important;
  background-color: var(--v-primary-base) !important;
}
.c-popup-body {
  background-color: var(--v-background-base);
  height: 100%;
  overflow: auto;
}

/* dragging styles */
.c-dragger:hover {
  cursor: grab;
}
.c-dragger:active {
  cursor: grabbing;
}
</style>

<style lang="css">
.c-popup-toolbar .v-toolbar__content {
  justify-content: space-between;
  height: 28px !important;
  padding-left: 0px;
  padding-right: 0px; /* button has 12px of right padding */
}
</style>
