<template>
  <v-toolbar
    class="c-quiz-toolbar"
    color="primary"
    dense
  >
    <v-menu
      content-class='c-menu'
      bottom
      offset-y
      transition="slide-y-transition"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>
      </template>

      <v-list class="c-menu-list">
        <v-list-item
          v-for="(question, index) in questions"
          :key="index"
          @click="$emit('select', index)"
        >
          <v-list-item-title>Q{{ index + 1 }}: {{ truncate(question.question.text) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>
    <v-spacer v-if="showProgress" />
    {{ showProgress ? $t('quiz.status.progress', { question: Math.min(current + 1, quizLength), questions: quizLength }) : '' }}
  </v-toolbar>
</template>

<script>
export default {
  name: 'QuizToolbar',

  components: {},

  props: {
    questions: {
      type: Array,
      required: true
    },

    current: {
      type: Number,
      required: true
    },

    showProgress: {
      type: Boolean,
      required: false,
      default: true
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    quizLength() {
      return this.questions.length
    }
  },

  methods: {
    truncate(text) {
      const maxLength = 60
      return text.length < maxLength ? text : `${text.substring(0, maxLength)}...`
    }
  }
}
</script>

<style lang="css" scoped>
.c-quiz-toolbar {
  width: 100%;
  flex-grow: 0;
}
.c-menu-list {
  background-color: var(--v-background-base);
}
</style>
