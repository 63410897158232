var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-speed-dial",
    {
      attrs: {
        direction: _vm.direction,
        "open-on-hover": _vm.hover,
        transition: _vm.transition,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: {
                        class: _vm.current.colour
                          ? `${_vm.current.colour}--text`
                          : "primary--text",
                      },
                      expression:
                        "{ class: current.colour ? `${current.colour}--text` : 'primary--text' }",
                    },
                  ],
                  class: [
                    _vm.current.colour ? "c-btn-simple" : "c-btn",
                    { "c-btn--active": _vm.active },
                  ],
                  attrs: {
                    "active-class": "c-btn--active",
                    color:
                      _vm.current.colour === _vm.themeColour
                        ? _vm.activatorBackgroundColour
                        : "",
                    fab: "",
                    small: "",
                    text: !_vm.current.colour,
                  },
                  model: {
                    value: _vm.isOpen,
                    callback: function ($$v) {
                      _vm.isOpen = $$v
                    },
                    expression: "isOpen",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: {
                        "accent-text": _vm.isOpen,
                        [`${_vm.current.colour}--text`]:
                          !_vm.isOpen && _vm.current.colour,
                      },
                      attrs: { large: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isOpen
                              ? "mdi-close"
                              : _vm.current.icon || _vm.icon
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "v-btn",
        {
          key: option.name,
          class: option.colour ? "c-btn-simple" : "c-btn",
          attrs: { color: _vm.buttonBackgroundColour, fab: "", small: "" },
          on: {
            click: function ($event) {
              return _vm.setSelection(option)
            },
          },
        },
        [
          _c(
            "v-icon",
            {
              class: { [`${option.colour}--text`]: option.colour },
              attrs: { large: "" },
            },
            [_vm._v(" " + _vm._s(option.icon || _vm.icon) + " ")]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }