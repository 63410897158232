<template>
  <PortableText
    class-name="c-rich-text"
    :blocks="blocks"
    :serializers="serializers"
    :project-id="blockOptions.projectId"
    :dataset="blockOptions.dataset"
  />
</template>

<script>
// TODO: V3 migration -> https://github.com/portabletext/vue-portabletext/blob/main/MIGRATING.md
import blocksToHTML from '@sanity/block-content-to-html'
import PortableText from 'sanity-blocks-vue-component'

// portableText serializers
import AudioBlock from '@/components/block/AudioBlock'
import ClickableImageBlock from '@/components/block/ClickableImageBlock'
import FigureBlock from '@/components/block/FigureBlock'
import AdBlock from '@/components/block/AdBlock'
import VideoBlock from '@/components/block/VideoBlock'
import YouTubeBlock from '@/components/block/YouTubeBlock'

// const h = blocksToHTML.h

export default {
  name: 'RichText',

  components: {
    // AdBlock,
    // AudioBlock,
    // FigureBlock,
    // VideoBlock,
    // YouTubeBlock,
    PortableText
  },

  props: {
    blocks: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    blockOptions() {
      return {
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      }
    },

    serializers() {
      return {
        styles: {
          normal: ({ _style, _children }) => {}
        },
        marks: {
          highlight: ({ _mark, children }) => {
            // eslint-disable-next-line no-unused-vars
            /*
            const vNode = h(
              'pre',
              {},
              h('span', { style: { 'background-color': 'yellow', 'color': 'black' } }, children)
            )
            */

            return <span style={{ backgroundColor: 'yellow', color: 'black' }}>{children}</span>
          },

          externalLink: ({ mark, children }) => {
            const { href } = mark
            // eslint-disable-next-line no-unused-vars
            // const vNode = h('a', { href, target: '_blank', rel: 'noopener noreferrer' }, children)
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            )
          },

          internalLink: ({ mark, children }) => {
            const id = mark.reference._ref
            const href = `/ipost/${id}`
            // eslint-disable-next-line no-unused-vars
            // const vNode = h('a', { href, target: '_blank', rel: 'noopener' }, children)
            return (
              <a href={href} target="_blank">
                {children}
              </a>
            )
          }
        },
        types: {
          inlineAd: AdBlock,
          audio: AudioBlock,
          clickableImage: ClickableImageBlock,
          figure: FigureBlock,
          video: VideoBlock,
          youtube: YouTubeBlock
        }
      }
    },

    tenant() {
      return this.$store.state.tenantStore.tenant
    }
  },

  methods: {
    toHTML(blocks) {
      return blocksToHTML({
        blocks,
        serializers: this.serializers,
        projectId: this.tenant.cms.projectId,
        dataset: this.tenant.cms.dataset,
        useCdn: true
      })
    },

    toText() {
      const text = []
      this.blocks[0].children?.forEach((child) => text.push(child.text))
      return text.join('')
    }
  }
}
</script>

<style lang="css" scoped>
.c-rich-text {
  width: 100%;
}
.c-rich-text :deep(ol),
.c-rich-text :deep(ul) {
  margin-bottom: 16px;
}
.c-rich-text :deep(ol) {
  margin-left: 12px; /* for Safari */
}
</style>
