import content from './content.json'
import headers from '@/services/real/content/headers.json'
import { fakeFetch } from '@/services/mock/fakeFetch'

export const fetchContent = (_filter) => {
  return fakeFetch(content, 500) // wait 500ms before returning content
}

export const fetchContentItem = (id) => {
  return fakeFetch(content, 0).then((content) => {
    const foundItem = content.find((item) => item.id === id)
    return foundItem
  })
}

export const getContentHeaders = () => {
  return JSON.parse(JSON.stringify(headers))
}
