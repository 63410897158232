var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c-web-viewer c-frame-wrapper d-flex align-center justify-center",
    },
    [
      _c("iframe", {
        key: _vm.src,
        ref: "iframe",
        attrs: { allowfullscreen: "", src: _vm.src, title: _vm.title },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }