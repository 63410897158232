var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-content-player" },
    [
      _vm.overlay
        ? _c("div", { staticClass: "c-content-player__overlay" })
        : _vm._e(),
      _vm._t("player", null, {
        params: _vm.params,
        playerClass: "c-content-player__player",
      }),
      _c("DrawCanvas", {
        staticClass: "c-content-player__canvas",
        class: { "c-canvas-active": _vm.isCanvasActivated },
        attrs: { activated: _vm.isCanvasActivated, type: _vm.item.mediaType },
        on: { capture: _vm.capture, download: _vm.download },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }