var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "pa-2",
      class: _vm.center
        ? "d-flex justify-center flex-column text-center"
        : "d-flex justify-left",
      attrs: { elevation: "0", rounded: "" },
    },
    [
      _c("PersonAvatar", { attrs: { person: _vm.person, size: _vm.size } }),
      _c(
        "div",
        { staticClass: "c-name-block d-flex flex-column" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "c-person-name pa-0 ma-auto",
              class: _vm.center ? "text-center" : "ml-2 text-left",
            },
            [_vm._v(" " + _vm._s(_vm.personName) + " ")]
          ),
          _vm.suffixes
            ? _c(
                "v-card-subtitle",
                {
                  staticClass: "c-person-suffixes py-0",
                  class: _vm.center ? "text-center" : "text-left",
                },
                [_vm._v(" " + _vm._s(_vm.personSuffixes) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }