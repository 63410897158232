<template>
  <div class="c-loader d-flex flex-column align-center justify-space-around">
    <CircularProgress :message="$t('message.loadingModel')">
      <template #append>
        <div class="c-progress-bar">
          <div
            class="c-progress-bar-fill"
            :style="progressBarFillStyle"
          />
        </div>
      </template>
    </CircularProgress>
  </div>
</template>

<script>
import CircularProgress from '@/components/base/CircularProgress'

export default {
  name: 'UnityAppLoadingScreen',

  components: {
    CircularProgress
  },

  props: {
    progress: {
      type: Number,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    progressBarFillStyle() {
      return { width: this.progress * 100 + '%' }
    }
  }
}
</script>

<style lang="css" scoped>
.c-loader {
  background-color: var(--v-background-base);
  height: 100%;
  width: 100%;
}

.c-progress-bar {
  background-color: var(--v-background-base);
  border: 1px solid var(--v-primary-base);
  margin: 1.5rem;
  width: 50vw;
}

.c-progress-bar-fill {
  background-color: var(--v-primary-base);
  height: 1rem;
  margin: 2px;
  transform-origin: top left;
}
</style>
