import { render, staticRenderFns } from "./QuizCard.vue?vue&type=template&id=0abe2e3f&scoped=true"
import script from "./QuizCard.vue?vue&type=script&lang=js"
export * from "./QuizCard.vue?vue&type=script&lang=js"
import style0 from "./QuizCard.vue?vue&type=style&index=0&id=0abe2e3f&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abe2e3f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0abe2e3f')) {
      api.createRecord('0abe2e3f', component.options)
    } else {
      api.reload('0abe2e3f', component.options)
    }
    module.hot.accept("./QuizCard.vue?vue&type=template&id=0abe2e3f&scoped=true", function () {
      api.rerender('0abe2e3f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/quiz/QuizCard.vue"
export default component.exports