var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { color: "background" } },
    [
      _c("v-subheader", [_vm._v(_vm._s(_vm.$t("post.share")))]),
      _vm._l(_vm.shareLinks, function (sharer, i) {
        return _c(
          "v-list-item",
          { key: i, attrs: { href: sharer.link, target: "_blank" } },
          [
            _c(
              "v-list-item-avatar",
              { attrs: { size: "56" } },
              [
                _c(
                  "v-avatar",
                  { attrs: { size: "56", tile: "" } },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", color: sharer.colour } },
                      [_vm._v(" " + _vm._s(sharer.icon) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-list-item-title", [_vm._v(_vm._s(sharer.title))]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }