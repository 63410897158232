<template>
  <div class="c-widget-wrapper">
    <div
      class="c-widget px-2 pb-2"
      :class="[isDark ? 'c-dark' : 'c-light', {'c-outlined': outlined}]"
    >
      <WidgetHeader
        :background="outlined"
        :closeable="closeable"
        @close="$emit('close')"
      >
        {{ title }}
      </WidgetHeader>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import WidgetHeader from '@/components/base/WidgetHeader'

export default {
  name: 'WidgetWrapper',

  components: {
    WidgetHeader
  },

  props: {
    closeable: {
      type: Boolean,
      required: false,
      default: true
    },

    outlined: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    }
  }
}
</script>

<style lang="css" scoped>
.c-widget-wrapper {
  width: 100%;
  max-width: 100%; /* flex item: so it doesn't overflow */
  min-width: 0; /* flex item: so it can shrink */
  min-height: 0; /* flex item: so it can shrink */
}

.c-widget {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 0 1pt var(--v-outline-base);
  border-radius: 16px;
}
.c-widget.c-outline {
  outline: 1px solid var(--v-outline-base);
}
.c-widget.c-dark {
  background-color: rgba(0, 0, 0, 0.25);
}
.c-widget.c-light {
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
