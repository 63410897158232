<template>
  <v-card class="c-card d-flex flex-column" text>
    <div class="c-flex-img">
      <v-img
        :key="thumbnail"
        class="c-card-image c-clickable align-end white--text"
        :aspect-ratio="16 / 9"
        :gradient="cardGradient"
        :src="thumbnail"
        @click="$emit('view:post')"
      >
        <v-card-title class="c-card-title text-left px-4 py-2">
          {{ title }}
        </v-card-title>
      </v-img>
    </div>

    <v-card-title class="py-2">
      <div>
        <p v-if="displayName" class="mb-0">
          {{ displayName }}
        </p>
        <p class="grey--text mb-0">
          {{ publishDate }}
        </p>
      </div>
    </v-card-title>

    <v-card-text class="c-excerpt pb-2 text-left">
      <ClampedText>
        {{ post.excerpt }}
      </ClampedText>
    </v-card-text>

    <v-spacer />

    <v-card-actions class="c-actions px-4">
      <v-btn class="c-label" color="primary" @click="$emit('view:post')">
        {{ $t(`post.action.${cardAction}`) }}
      </v-btn>
      <v-spacer />
      <!-- share -->
      <v-btn color="accent" icon large @click.stop="$emit('share')">
        <v-icon large> mdi-share-variant </v-icon>
      </v-btn>
      <!-- info -->
      <v-btn color="accent" icon large @click.stop="revealBack = true">
        <v-icon large> mdi-information </v-icon>
      </v-btn>
      <!-- favourite -->
      <v-btn color="red" icon large @click.stop="toggleFavourite()">
        <v-icon large>
          {{ isFavourite ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-scroll-x-reverse-transition>
      <PostCardBack v-model="revealBack" :author="post.author" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import ClampedText from '@/components/base/ClampedText'
import PostCardBack from '@/components/post/PostCardBack'
import { getImage } from '@/config/sanityConfig'
import { mapActions } from 'vuex'

export default {
  name: 'PostCard',

  components: {
    ClampedText,
    PostCardBack
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isFavourite: false,
      maxLines: 3,
      revealBack: false,
      action: 'read'
    }
  },

  computed: {
    // app context
    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    // post card
    cardAction() {
      const actions = {
        podcast: 'listen',
        vcast: 'watch',
        blog: 'read'
      }
      return actions[this.post.genre] || 'explore'
    },

    cardGradient() {
      return ''
      // return 'to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)'
    },

    displayName() {
      return this.post.author?.displayName
    },

    excerpt() {
      return this.post.excerpt.length > 0 ? this.post.excerpt : this.excerptAlternative
    },

    excerptAlternative() {
      const position = this.post.body.search('</p>')
      const paragraph = this.post.body.substr(0, position)
      return paragraph.length > 100 ? paragraph.substr(0, 100) + '...</p>' : paragraph + '</p>'
    },

    favourite() {
      return this.$store.state.userStore.posts.indexOf(this.post.id) > -1
    },

    publishDate() {
      const dtm = new Date(this.post.publishedAt)
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
      return dtm.toLocaleDateString(this.locale, options)
    },

    thumbnail() {
      const image = this.post.cardImage?.obj || this.post.mainImage?.obj
      return image ? getImage(image).width(400).height(225).url() : ''
    },

    title() {
      return this.post.episode
        ? `[Episode #${this.post.episode}]: ${this.post.title}`
        : this.post.title
    }
  },

  created: function () {
    this.isFavourite = this.favourite
  },

  activated: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    ...mapActions('userStore', ['addToPosts', 'removeFromPosts']),

    getCardHeight(colWidth) {
      // cardWidth
      const colGap = 12
      const rowGap = 12
      const cardWidth = Math.min(Math.max(colWidth - 2 * colGap, 275), 400)

      // thumbnail height
      const thumbnailHeight = cardWidth * (9 / 16)

      // fixed  blocks
      const authorHeight = 80
      const excerptHeight = 80
      const actionHeight = 60

      // totals
      const height = 2 * rowGap + thumbnailHeight + authorHeight + excerptHeight + actionHeight
      return height
    },

    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.addToFavourites() : this.removeFromFavourites()
    },

    addToFavourites() {
      this.addToPosts({ id: this.post.id })
      this.$emit('add:post')
    },

    removeFromFavourites() {
      this.removeFromPosts({ id: this.post.id })
      this.$emit('remove:post')
    }
  }
}
</script>

<style lang="css" scoped>
.c-card {
  margin: auto;
  height: 100%;
  max-width: min(400px, 100%);
  min-width: 275px;
  border-radius: 16px;
}

.c-card-image {
  border-radius: inherit;
}

.c-card-title {
  background-color: rgb(0, 0, 0, 0.4);
  font-size: 1.15rem;
  line-height: 1.25rem;
  padding-bottom: 8px;
  text-shadow: 0px 2px 5px #222;
}

.c-clickable {
  cursor: pointer;
}

.c-excerpt {
  min-height: 80px;
}

.c-label {
  color: var(--v-text-base) !important;
}
</style>
