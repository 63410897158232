import client from '@/services/real'

class AdService {
  constructor() {
    this.type = ''
  }

  async fetchAds({ locale, keywords = [], zone = '' }) {
    const ads = await client.fetchAds({ locale, keywords, zone })
    return ads
  }
}

export default AdService
export const adService = new AdService()
