var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-progress-card pa-2", attrs: { elevation: "4" } },
    [
      _c(
        "div",
        {
          staticClass:
            "c-quiz-status d-flex flex-column justify-center align-center",
        },
        [
          _c("div", [
            _vm._v(
              _vm._s(_vm.questions) +
                " " +
                _vm._s(_vm.$t("quiz.status.questions")) +
                ", " +
                _vm._s(_vm.answers) +
                " " +
                _vm._s(_vm.$t("quiz.status.answered"))
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "c-progress-bar d-flex flex-column align-center" },
        [
          _c("v-progress-linear", {
            staticClass: "c-progress-bar",
            attrs: { color: "success", height: "8", value: _vm.progress },
          }),
          _vm._v(
            " " +
              _vm._s(_vm.formattedProgress) +
              "% " +
              _vm._s(_vm.$t("quiz.status.complete")) +
              " "
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }