<template>
  <v-sheet class="c-post-body">
    <!-- title -->
    <h1 class="c-title">{{ post.title }}</h1>

    <!-- prolog (author, host and/or guests) -->
    <PostPrologue :post="post" />

    <!-- excerpt (aka teaser) -->
    <div class="c-excerpt mt-4 mb-6">
      <i>{{ post.excerpt }}</i>
    </div>

    <!-- date/time stamp -->
    <v-list three-line subheader>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('post.postedOn') }} {{ post.publishedAt | date }}
        </v-list-item-title>
      </v-list-item-content>

      <!-- hero (blogs only) -->
      <v-row
        v-if="isBlog"
        class="c-hero text-center mx-0 my-2"
        justify="center"
      >
        <v-img
          :aspect-ratio="16 / 9"
          max-height="450px"
          :src="heroSrc"
        />
      </v-row>

      <!-- content -->
      <v-list-item-content class="c-content">
        <RichText class="c-blocks" :blocks="postBlocks"/>
      </v-list-item-content>

      <!-- author (epilog) -->
      <v-list-item-content class="c-author d-flex">
        <PersonBanner :person="post.author" />
        <RichText :blocks="bioBlocks" />
      </v-list-item-content>
    </v-list>

    <!-- footer -->
    <v-list v-if="post.tags" three-line subheader>
      <v-subheader>{{ $t('post.tags') }}</v-subheader>
      <TagList :tags="post.tags" />
    </v-list>
  </v-sheet>
</template>

<script>
import { getImage } from '@/config/sanityConfig'

import PersonBanner from '@/components/person/PersonBanner'
import PostPrologue from '@/components/post/PostPrologue'
import RichText from '@/components/base/RichText'
import TagList from '@/components/base/TagList'

export default {
  name: 'PostBody',

  components: {
    PersonBanner,
    PostPrologue,
    RichText,
    TagList
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    /* blogs */

    isBlog() {
      return this.post.genre === 'blog'
    },

    heroSrc() {
      return getImage(this.post.mainImage.obj).width(1600).height(900).url()
    },

    /* context */

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    /* rich text */

    bioBlocks() {
      return (
        this.post.author?.bio?.[this.locale] || this.post.author?.bio?.[this.defaultLocale] || []
      )
    },

    postBlocks() {
      return this.post.body || []
    }
  },

  mounted: function () {}
}
</script>

<style lang="css" scoped>
/* post formatting */
.c-post-body {
  width: 100%;
  padding: 24px min(10%, 48px);
}

.c-excerpt {
  font-size: 1.25rem;
  line-height: 1.5;
}
.c-title {
  line-height: 1.5;
}

.c-author :deep(*) {
  line-height: 1.5;
}

/* content */
.c-content {
  font-size: 1.5rem;
}

.c-content :deep(*) {
  line-height: 1.75 !important;
}
.c-content .c-blocks > p:first-of-type::first-letter,
.c-content .c-blocks > h1:first-of-type::first-letter,
.c-content .c-blocks > h2:first-of-type::first-letter,
.c-content .c-blocks > h3:first-of-type::first-letter,
.c-content .c-blocks > h4:first-of-type::first-letter,
.c-content .c-blocks > h5:first-of-type::first-letter,
.c-content .c-blocks > h6:first-of-type::first-letter {
  color: var(--v-primary-base);
  float: left;
  font-family: Georgia;
  font-size: 400%;
  line-height: 85%;
}
.c-content ol,
.c-content ul {
  padding-left: 36px;
}
.c-content img {
  width: 100%;
}
</style>
