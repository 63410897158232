// import client from '@client'
import client from '@/services/real'

class CommentService {
  constructor() {
    this.type = 'sanity'
  }

  fetchComments = async (targetId) => {
    const comments = await client.fetchComments(targetId)
    return comments
  }

  fetchReplies = async (parentId) => {
    const comments = await client.fetchReplies(parentId)
    return comments
  }

  addComment = async (comment) => {
    const addedComment = await client.addComment(comment)
    return addedComment
  }

  modifyComment = async (comment) => {
    const modifiedComment = await client.modifyComment(comment)
    return modifiedComment
  }

  removeComment = async (comment) => {
    const updatedComment = await client.removeComment(comment)
    return updatedComment
  }
}

export default CommentService
export const commentService = new CommentService()
