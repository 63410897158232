var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "c-post-modal justify-center",
            fullscreen: "",
            "overlay-color": "background",
            scrollable: "",
            transition: "dialog-bottom-transition",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.closeModal.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showPostModal,
            callback: function ($$v) {
              _vm.showPostModal = $$v
            },
            expression: "showPostModal",
          },
        },
        [
          _c("PostBar", {
            staticClass: "c-post-header",
            attrs: { title: _vm.post.title, favourite: _vm.isFavourite },
            on: {
              "toggle:favourite": _vm.toggleFavourite,
              share: function ($event) {
                _vm.showShareMenu = !_vm.showShareMenu
              },
              close: _vm.closeModal,
            },
          }),
          _c("PostBody", {
            staticClass: "c-post-body",
            attrs: { post: _vm.post },
          }),
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          model: {
            value: _vm.showShareMenu,
            callback: function ($$v) {
              _vm.showShareMenu = $$v
            },
            expression: "showShareMenu",
          },
        },
        [_c("PostShareMenu", { attrs: { post: _vm.post } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }