<template>
  <v-card class="c-progress-card pa-2" elevation="4">
    <!-- stats -->
    <div class="c-quiz-status d-flex flex-column justify-center align-center">
      <div>{{ questions }} {{ $t('quiz.status.questions') }}, {{ answers }} {{ $t('quiz.status.answered') }}</div>
    </div>

    <!-- progress bar -->
    <div class="c-progress-bar d-flex flex-column align-center">
      <v-progress-linear
        class="c-progress-bar"
        color="success"
        height="8"
        :value="progress"
      />
      {{ formattedProgress }}% {{ $t('quiz.status.complete') }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'QuizProgressCard',

  components: {},

  props: {
    questions: {
      type: Number,
      required: true
    },

    answers: {
      type: Number,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    progress() {
      return (this.answers / this.questions) * 100
    },

    formattedProgress() {
      return this.progress.toFixed(0)
    }
  }
}
</script>

<style lang="css" scoped>
.c-progress-card {
  width: 100%;
}
.c-progress-bar {
  margin: auto;
  width: 90%;
}
</style>
