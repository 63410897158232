var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ThreeColumnLayout", {
    staticClass: "c-content-layout g-skinny-scrollbars",
    attrs: {
      header: _vm.showTopAds,
      left: _vm.showLeftPanel,
      right: _vm.showSideAds,
      fullscreen: _vm.isFullscreen,
      top: _vm.fullscreen ? 0 : _vm.top,
      scroll: true,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _c("AdLeaderboard", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAds,
                    expression: "showAds",
                  },
                ],
                attrs: { hero: _vm.isArticle },
                on: {
                  close: function ($event) {
                    _vm.showAds = false
                  },
                },
                model: {
                  value: _vm.showTopAds,
                  callback: function ($$v) {
                    _vm.showTopAds = $$v
                  },
                  expression: "showTopAds",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "left",
          fn: function () {
            return [
              _vm.hasAuthors
                ? _c("PeopleWidget", {
                    attrs: {
                      people: _vm.sortedAuthors,
                      title:
                        _vm.$t("content.authors") +
                        "/" +
                        _vm.$t("content.advisors"),
                    },
                  })
                : _vm._e(),
              _vm.hasQuiz
                ? _c("QuizWidget", { attrs: { qid: _vm.quizId } })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "middle",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
        {
          key: "right",
          fn: function () {
            return [
              _c("AdWidget", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAds,
                    expression: "showAds",
                  },
                ],
                attrs: { zone: "side" },
                on: {
                  empty: function ($event) {
                    _vm.showSideAds = false
                  },
                  close: function ($event) {
                    _vm.showAds = false
                  },
                },
                model: {
                  value: _vm.showSideAds,
                  callback: function ($$v) {
                    _vm.showSideAds = $$v
                  },
                  expression: "showSideAds",
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }