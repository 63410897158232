var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card mx-auto" },
    [
      _c("ClientFavourite", {
        attrs: { favourite: _vm.favourite },
        on: {
          "add:favourite": function ($event) {
            return _vm.addToFavourites(_vm.contact.id)
          },
          "remove:favourite": function ($event) {
            return _vm.removeFromFavourites(_vm.contact.id)
          },
        },
      }),
      _c(
        "v-card-title",
        [
          _c(
            "v-avatar",
            {
              staticClass: "ma-auto",
              attrs: { fab: "", size: _vm.avatarSize, color: "indigo" },
            },
            [
              _vm.contact.avatar
                ? _c("v-img", { attrs: { src: _vm.contact.avatar } })
                : _c("span", { staticClass: "white--text text-h5" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.contact.firstName[0]) +
                        _vm._s(_vm.contact.lastName[0]) +
                        " "
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c("v-list-item-icon", { attrs: { "align-self": "center" } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.contact.linkedin,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: "#0072b1", "x-large": "" } },
                      [_vm._v("mdi-linkedin")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-subtitle", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.contact.title) + " "),
                  ]),
                  _c("v-list-item-title", { staticClass: "text-h5" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.contact.firstName) +
                        " " +
                        _vm._s(_vm.contact.lastName) +
                        " "
                    ),
                  ]),
                  _c("v-list-item-subtitle", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.contact.jobTitle) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.callContact(_vm.contact)
                        },
                      },
                    },
                    [_vm._v(" mdi-phone ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [_vm._v(" " + _vm._s(_vm.contact.phone) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.textContact(_vm.contact)
                        },
                      },
                    },
                    [_vm._v(" mdi-message-text ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.emailContact(_vm.contact)
                        },
                      },
                    },
                    [_vm._v(" mdi-email ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [_vm._v(" " + _vm._s(_vm.contact.email) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.zoomContact(_vm.contact)
                        },
                      },
                    },
                    [_vm._v(" mdi-video ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.showMap(_vm.contact)
                        },
                      },
                    },
                    [_vm._v(" mdi-map-marker ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [_vm._v(" " + _vm._s(_vm.contact.address) + " ")]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.contact.city) +
                          " " +
                          _vm._s(_vm.contact.province) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.contact.country) +
                          " " +
                          _vm._s(_vm.contact.postalCode) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }