<template>
  <div
    class="c-widget-header d-flex align-stretch justify-space-between py-1"
    :class="{'c-fill': fill}"
  >
    <div class="c-spacer pl-5" />
    <div class="my-auto text-caption text-center text-uppercase">
      <slot>{{ title }}</slot>
    </div>
    <div v-if="closeable" class="c-close">
      <v-btn
        class="c-btn-close c-rounded"
        block
        text
        ripple
        x-small
        @click="$emit('close')"
      >
        <v-icon small> mdi-close </v-icon>
      </v-btn>
    </div>
    <div v-else class="c-spacer pl-5" />
  </div>
</template>

<script>
export default {
  name: 'WidgetHeader',

  components: {},

  props: {
    fill: {
      type: Boolean,
      required: false,
      default: false
    },

    closeable: {
      type: Boolean,
      required: false,
      default: true
    },

    rounded: {
      type: Boolean,
      required: false,
      default: true
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isDark() {
      return this.$store.state.themeStore.isDark
    }
  }
}
</script>

<style lang="css" scoped>
.c-widget-header {
  width: 100%;
}
.c-widget-header.c-fill {
  background-color: var(--v-outline-base);
}
.c-btn-close {
  padding: 0 !important;
  min-width: 24px !important;
}
.c-btn-close.c-rounded {
  border-top-right-radius: 4px;
}
.c-btn-close i:hover {
  transform: scale(1.25);
}
</style>
