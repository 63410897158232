import errorMixin from '@/mixins/errorMixin.js'
import { logger } from '@/services/loggerService.js'

const formatName = function (vm) {
  if (!vm) {
    return '<Unknown>'
  }
  if (vm.$root === vm) {
    return '<Root>'
  }
  const options =
    typeof vm === 'function' && vm.cid != null
      ? vm.options
      : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm
  var name = options.name || options._componentTag
  var file = options.__file
  if (!name && file) {
    var match = file.match(/([^/\\]+)\.vue$/)
    name = match && match[1]
  }

  return name ? '<' + name + '>' : '<Anonymous>' + file
}

const errorHandler = (err, vm, info) => {
  const message = `Vue: ${formatName(vm)}: ${err?.name} (in ${info})`
  const stack = err?.stack
  logger.logError({ message, stack })
}

const warnHandler = (msg, vm, trace) => {
  const message = `Vue: ${formatName(vm)}: ${msg}`
  logger.logWarning({ message, trace })
}

// TODO: only needed for remote telemetry logging
// eslint-disable-next-line no-unused-vars
const onError = () => {
  if (typeof window !== 'undefined') {
    window.onerror = function (event, source, lineNo, colNo, error) {
      const { message } = event
      if (message.startsWith('ResizeObserver loop')) {
        // console.warn('Ignored: ResizeObserver loop limit exceeded.')
        return false
      } else {
        const stack = error?.stack || ''
        logger.logError({ message, stack })
      }
    }
  }
}

export class ErrorHandlerPlugin {
  static install(Vue, _options) {
    // setup Vue error handling
    Vue.config.errorHandler = errorHandler
    Vue.config.warnHandler = warnHandler
    Vue.mixin(errorMixin)
    Vue.prototype.$logger = logger

    // setup browser error handling
    // onError()
  }
}
