<template>
  <v-main
    id="main"
    class="c-main"
  >
    <div
      class="c-container g-skinny-scrollbars"
      :class="{ 'c-mobile': isReallyMobile }"
      fluid
    >
      <transition name="fade" :duration="100" mode="out-in" @after-leave="afterLeave">
        <keep-alive :include="keepAliveRouteComponentNames">
          <router-view
            :key="$route.path"
            class="c-view"
            :class="{ 'c-mobile': isReallyMobile }"
            @count="$emit('count', $event)"
            @title="$emit('title', $event)"
          />
        </keep-alive>
      </transition>
    </div>
  </v-main>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'AppMain',

  components: {},

  mixins: [mobileMixin],

  data: function () {
    return {}
  },

  computed: {
    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    keepAliveRouteComponentNames() {
      const routeComponentsSet = new Set(
        this.$router.options.routes
          .filter((route) => route.meta?.keepAlive)
          .map((route) => route.meta?.component || '')
      )
      return Array.from(routeComponentsSet)
    }
  },

  methods: {
    afterLeave() {
      console.debug('[AppMain]: emit triggerScroll')
      this.$root.$emit('triggerScroll')
    }
  }
}
</script>

<style lang="css" scoped>
.c-main {
  padding: 0; /* overriden by 'app' prop */
  background-color: var(--v-sheet-base);
}

.c-container {
  padding: 0;
  min-height: calc(var(--c-viewport-height) - var(--c-top-bar-height));
}

.c-view {
  min-height: inherit !important;
}
.c-view.c-mobile {
  padding-bottom: var(--c-bottom-bar-height);
}
</style>
