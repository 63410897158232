var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-permission ma-0 g-skinny-scrollbars",
        "dialog-transition": "dialog-top-transition",
        "hide-overlay": "",
        "max-width": "300",
        persistent: "",
        value: _vm.showRequest,
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "c-card" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center pa-4" },
            [_c("v-img", { attrs: { src: _vm.logo, "max-width": "50" } })],
            1
          ),
          _c("v-card-title", { staticClass: "c-title pt-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("app.request.title")) + " "),
          ]),
          _c("v-card-text", { staticClass: "c-text pb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("app.request.text")) + " "),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "justify-space-around" },
            [
              _c(
                "v-btn",
                { attrs: { width: "100" }, on: { click: _vm.cancel } },
                [_vm._v(" " + _vm._s(_vm.$t("app.request.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", width: "100" },
                  on: { click: _vm.allow },
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.request.allow")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }