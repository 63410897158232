<template>
    <v-list rounded>
      <v-subheader class="c-header">
        {{ $t('model.animations').toUpperCase() }}
      </v-subheader>
      <v-list-item-group
        v-model="selectedAnimationIds"
        class="c-list-item-group"
        active-class="c-list-item--active"
        multiple
        @change="$emit('sync', selectedAnimationIds)"
      >
        <v-list-item
          v-for="animation in animations"
          :key="animation.id"
          dense
          :value="animation.id"
          @click.stop="$emit('select', animation.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ animation.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
  name: 'AnimationList',

  components: {},

  model: {
    prop: 'ids',
    event: 'sync'
  },

  props: {
    ids: {
      type: Array,
      required: true
    },

    animations: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      selectedAnimationIds: []
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  watch: {
    ids: {
      immediate: false,
      handler: function (newIds, _oldId) {
        if (newIds) this.selectedAnimationIds = [...newIds]
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-header {
  font-weight: 600;
}

.c-list-item-group.theme--light {
  background-color: white;
}
.c-list-item-group.theme--dark {
  background-color: black;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
