<template>
  <div ref="ad" class="c-ad-spot mx-auto">
    <!-- ad types -->
    <div v-if="hasAds && showAds" class="c-ad-slot">
      <AdStack
        v-if="stack"
        class="c-ad-stack"
        :ads="activeAds"
        :header="closeable"
        @close="onCloseAds()"
      >
        <template #ad="props">
          <AdImage
            class="c-ad-image"
            :data-ad="props.index"
            :ad="props.ad"
            :closeable="false"
            @load="onLoadAd($event, props.ad)"
            @select="onSelectAd($event)"
          />
          <AdCopy v-if="props.ad.adItem?.copy" :copy="props.ad.adItem?.copy" />
        </template>
      </AdStack>

      <AdCarousel v-if="carousel" class="c-ad-carousel" :ads="activeAds">
        <template #ad="props">
          <AdImage
            class="c-ad-image"
            :data-ad="props.index"
            :ad="props.ad"
            :closeable="closeable"
            :optimize="optimize"
            @close="onCloseAds()"
            @load="onLoadAd($event, props.ad)"
            @select="onSelectAd($event)"
          />
        </template>
      </AdCarousel>

      <AdImage
        v-if="image"
        class="c-ad-image"
        :data-ad="0"
        :ad="activeAds[0]"
        :closeable="closeable"
        :optimize="optimize"
        @close="onCloseAds()"
        @load="onLoadAd($event, activeAds[0])"
        @select="onSelectAd($event)"
      />

      <AdPopup v-if="popup" :ad="activeAds[0]">
        <template #ad="props">
          <AdImage
            class="c-ad-image"
            :data-ad="0"
            :ad="props.ad"
            :closeable="closeable"
            :optimize="optimize"
            @close="onCloseAds()"
            @load="onLoadAd($event, props.ad)"
            @select="onSelectAd($event)"
          />
        </template>
      </AdPopup>

      <transition v-if="rollup && showHeroAd" class="c-ad-rollup" name="rollup" mode="out-in">
        <AdImage
          v-touch="{
            up: () => (showHeroAd = false)
          }"
          class="c-ad-image c-ad-hero"
          :data-ad="0"
          :ad="activeAds[0]"
          :closeable="false"
          @load="onLoadAd($event, activeAds[0])"
          @select="onSelectAd($event)"
        />
      </transition>
    </div>

    <!-- modal for click-to-action content -->
    <AdModal v-if="showModal" v-model="showModal" :src="modalSrc" />
  </div>
</template>

<script>
import AdCarousel from '@/components/ad/AdCarousel'
import AdCopy from '@/components/ad/AdCopy'
import AdImage from '@/components/ad/AdImage'
import AdModal from '@/components/ad/AdModal'
import AdPopup from '@/components/ad/AdPopup'
import AdStack from '@/components/ad/AdStack'
import { trackingId } from '@/config/appConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'AdSpot',

  components: {
    AdCarousel,
    AdCopy,
    AdImage,
    AdModal,
    AdPopup,
    AdStack
  },

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    /* ad selection */

    max: {
      type: Number,
      required: false,
      default: 3
    },

    reverse: {
      type: Boolean,
      required: false,
      default: false
    },

    zone: {
      type: String,
      required: true
    },

    /* block rendering */

    closeable: {
      type: Boolean,
      required: false,
      default: true
    },

    optimize: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: false,
      default: true
    },

    /* block types */

    carousel: {
      type: Boolean,
      required: false,
      default: false
    },

    image: {
      type: Boolean,
      required: false,
      default: false
    },

    popup: {
      type: Boolean,
      required: false,
      default: false
    },

    rollup: {
      type: Boolean,
      required: false,
      default: false
    },

    stack: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // ads used in this block
      showAds: this.show,
      activeAds: [],

      // hero ad processing
      showHeroAd: false,

      // modal for ad click content
      modalSrc: '',
      showModal: false,

      // ad observer
      visibilityObserver: null
    }
  },

  computed: {
    ...mapGetters('adStore', ['getAdsByZone']),

    ads() {
      // need to access adStore to ensure reactivity to ad inventory
      const adInventory = this.$store.state.adStore.ads
      return adInventory.length > 0 ? this.getAdsByZone(this.zone) : []
    },

    adsReversed() {
      return this.ads.slice().reverse()
    },

    randomizedAds() {
      return this.ads.length > this.max
        ? this.getRandomSubarray(this.ads, this.max)
        : this.reverse
        ? this.adsReversed
        : this.ads
    },

    hasAds() {
      return this.activeAds.length > 0
    },

    portalKey() {
      return this.$store.state.tenantStore.portalKey
    },

    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },

  watch: {
    ads: {
      // 'ads' property contains all matching ads (< max)
      // must be lazy because an event is emitted */
      immediate: false,
      handler: function (_newAds, _oldAds) {
        this.activateAds()
      }
    },

    show: {
      immediate: true, // allow ads to load
      handler: function (newShow, _prevShow) {
        this.showAds = newShow
      }
    }
  },

  created: function () {
    // setup observers
    this.visibilityObserver = new IntersectionObserver(this.onIntersection, {
      root: null,
      threshold: 0.66
    })
  },

  mounted: function () {
    this.activateAds()
  },

  beforeDestroy: function () {
    if (this.visibilityObserver) this.visibilityObserver.disconnect()
  },

  methods: {
    /* ad selection */

    getRandomSubarray(array, size) {
      const results = []
      const sampled = {}
      // select up to the requested size OR the supplied array length (whichever is less)
      while (results.length < size && results.length < array.length) {
        // FIXME: could loop forever...pick from unselected indexes instead
        const index = Math.trunc(Math.random() * array.length)
        // avoid duplicates
        if (!sampled[index]) {
          results.push(array[index])
          sampled[index] = true
        }
      }
      return results
    },

    getRandomIndex(array) {
      return Math.trunc(Math.random() * array.length)
    },

    /* actions */

    onCloseAds() {
      this.showAds = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onCloseModal() {
      this.showModal = false
    },

    onSelectAd(ad) {
      this.modalSrc = ad.adItem?.cta?.href || ''
      if (ad.adItem?.cta?.href) {
        ad.adItem.cta.popup
          ? (this.showModal = true)
          : window.open(ad.adItem.cta.href, 'LumeniiAdWindow', 'popup')
      } else if (ad.adItem?.cta?.link) {
        this.$router.push(ad.adItem.cta.link)
      }
      this.sendBeacon(ad, 'adClick')
    },

    onViewAd(ad) {
      this.sendBeacon(ad, 'adView')
    },

    // rendering

    activateAds() {
      // take a snapshot of the randomized ads
      this.activeAds = [...this.randomizedAds]

      // initiate hero ad transition
      if (this.zone === 'hero' && this.ads.length > 0) {
        this.showHeroAd = true
        this.startHeroTransition()
      }

      // notify parent
      this.$emit('count', this.activeAds.length)
    },

    startHeroTransition() {
      setTimeout(() => {
        this.showHeroAd = false
      }, 4000)
    },

    /* analytics */

    onLoadAd(img, _ad) {
      this.visibilityObserver.observe(img)
    },

    onIntersection(entries, observer) {
      entries.forEach((entry) => {
        // console.warn('intersecting?=', this.activeAds[entry.target.dataset.ad].title)
        if (entry.isIntersecting) {
          // console.warn('intersecting!=', this.activeAds[entry.target.dataset.ad].title)
          if (this.isVisible(entry.target)) {
            const ad = this.activeAds[entry.target.dataset.ad]
            console.warn(`[AdSpot]: ${this.zone} impression! ad=`, ad.title)
            this.onViewAd(ad)
            observer.unobserve(entry.target)
          }
        }
      })
    },

    isVisible(el) {
      const style = getComputedStyle(el)

      // if (el.clientHeight === 0) return false
      if (style.display === 'none') return false
      if (style.visibility !== 'visible') return false
      if (style.opacity < 0.1) return false

      return true
    },

    sendBeacon(ad, eventName) {
      const url = 'https://www.healthplexus.net/ad/track/index.php'

      // define analytics context
      const body = {
        // tracking event (e.g. 'adClick', 'adView')
        event: eventName,

        // ad specific context
        ad_name: ad.title,
        ad_group: ad.group,
        ad_zone: this.zone,

        // invocation context
        path: this.$route.path,
        token: 'abc123',
        tid: trackingId,

        // user context
        tz: this.timezone,
        uid: this.$auth.getUserSubject(),

        // Urchin codes
        utm_campaign: this.$route.query.utm_campaign || '',
        utm_medium: this.$route.query.utm_medium || 'pwa',
        utm_source: this.$route.query.utm_source || this.portalKey
      }

      // construct form data
      const formData = new FormData()
      for (const [key, value] of Object.entries(body)) {
        formData.append(key, value)
      }

      // fire POST event
      if (navigator.sendBeacon) {
        const result = navigator.sendBeacon(url, formData)
        if (!result) {
          console.error('[AdSpot]: Call to sendBeacon() failed.')
        }
      } else {
        fetch(url, {
          method: 'post',
          body: formData,
          mode: 'no-cors',
          keepalive: true
        })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-ad-spot {
  max-width: 100%; /* flex item: so it doesn't overflow */
  min-width: 0; /* flex item: so it can shrink */
}
.c-ad-hero {
  position: absolute;
  top: 0;
  z-index: 1;
}
</style>
