<template>
  <div
    class="c-stack d-flex flex-column justify-start align-center pb-2"
    :class="stackClass"
  >
    <WidgetHeader
      v-if="header"
      fill
      :rounded="false"
      @close="$emit('close')"
    >
      {{ title }}
    </WidgetHeader>

    <div
      v-for="(ad, index) in ads"
      :key="ad.id"
      class="c-ad-slot"
      :class="{
        'mb-4': index < ads.length - 1,
        'c-cta': ad.adItem?.cta
      }"
    >
      <slot name="ad" :ad="ad" :index="index" />
    </div>
  </div>
</template>

<script>
import WidgetHeader from '@/components/base/WidgetHeader'

export default {
  name: 'AdStack',

  components: {
    WidgetHeader
  },

  props: {
    ads: {
      type: Array,
      required: true
    },

    header: {
      type: Boolean,
      required: false,
      default: true
    },

    stackClass: {
      type: String,
      required: false,
      default: 'c-stack-class'
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isImage(ad) {
      return !!ad.adItem?.image
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    title() {
      return this.ads.length > 1 ? this.$t('ad.advertisements') : this.$t('ad.advertisement')
    }
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-stack {
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 0;
}
.c-ad-slot {
  width: 100%;
  max-width: 100%; /* flex item: so it doesn't overflow */
  min-width: 0; /* flex item: so it can shrink */
  min-height: 0; /* flex item: so it can shrink */
}
.c-ad-slot.c-outline {
  outline: solid 1px;
}

.c-cta {
  cursor: pointer;
}
</style>
