// import client from '@client'
import client from '@/services/mock'

class ContactService {
  constructor() {
    this.type = ''
  }

  getContacts = () => {}

  fetchContacts = async () => {
    const contacts = await client.fetchContacts()
    return contacts
  }
}

export default ContactService
export const contactService = new ContactService()
