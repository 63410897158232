var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { staticClass: "c-quiz-toolbar", attrs: { color: "primary", dense: "" } },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "content-class": "c-menu",
            bottom: "",
            "offset-y": "",
            transition: "slide-y-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-format-list-numbered")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { staticClass: "c-menu-list" },
            _vm._l(_vm.questions, function (question, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.$emit("select", index)
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      "Q" +
                        _vm._s(index + 1) +
                        ": " +
                        _vm._s(_vm.truncate(question.question.text))
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _vm.showProgress ? _c("v-spacer") : _vm._e(),
      _vm._v(
        " " +
          _vm._s(
            _vm.showProgress
              ? _vm.$t("quiz.status.progress", {
                  question: Math.min(_vm.current + 1, _vm.quizLength),
                  questions: _vm.quizLength,
                })
              : ""
          ) +
          " "
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }