var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "carousel",
      staticClass: "c-card-carousel pa-3 swiper",
    },
    [
      _c(
        "div",
        {
          staticClass: "swiper-wrapper",
          class: { "c-pagination-wrapper": _vm.isPaginationActive },
        },
        _vm._l(_vm.items, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "c-card-wrapper swiper-slide" },
            [_vm._t("card", null, { item: item })],
            2
          )
        }),
        0
      ),
      _c("div", { ref: "pagination", staticClass: "swiper-pagination" }),
      !_vm.isReallyMobile
        ? _c(
            "div",
            {
              ref: "prev",
              staticClass: "swiper-button-prev",
              class: { "c-mobile": _vm.isReallyMobile },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "c-swiper-button c-swiper-button-prev",
                  attrs: { fab: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.carousel.swiper.slidePrev()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                    _vm._v(" mdi-chevron-left "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isReallyMobile
        ? _c(
            "div",
            {
              ref: "next",
              staticClass: "swiper-button-next",
              class: { "c-mobile": _vm.isReallyMobile },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "c-swiper-button c-swiper-button-next",
                  attrs: { fab: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.carousel.swiper.slideNext()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                    _vm._v(" mdi-chevron-right "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }