var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SectionPanel",
    {
      attrs: {
        colour: _vm.colour,
        title: _vm.title,
        count: _vm.itemCount,
        loading: _vm.loading,
      },
      scopedSlots: _vm._u([
        {
          key: "toolbar",
          fn: function () {
            return [
              _c("LayoutToolbar", {
                attrs: {
                  items: _vm.items,
                  filters: _vm.itemFilters,
                  layouts: _vm.layouts,
                  sort: _vm.itemSortOptionName,
                  sorts: _vm.itemExtendedSortOptions,
                },
                on: {
                  results: function ($event) {
                    _vm.itemResultSet = $event
                  },
                  "update:layout": function ($event) {
                    return _vm.updatePreferences({ favouritesLayout: $event })
                  },
                  "update:sort": function ($event) {
                    return _vm.updatePreferences({ favouritesSort: $event })
                  },
                },
                model: {
                  value: _vm.contentLayout,
                  callback: function ($$v) {
                    _vm.contentLayout = $$v
                  },
                  expression: "contentLayout",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.contentLayout === "grid"
        ? _c("CardGrid", {
            attrs: { items: _vm.resultSet },
            scopedSlots: _vm._u(
              [
                {
                  key: "card",
                  fn: function (props) {
                    return [
                      _c(
                        "DraggableNode",
                        {
                          attrs: {
                            active: _vm.isContentDraggable,
                            "drag-type": "content",
                            identifier: props.item.id,
                          },
                          on: {
                            drop: function ($event) {
                              return _vm.moveFavourite({
                                from: $event.dragId,
                                after: $event.dropId,
                              })
                            },
                          },
                        },
                        [
                          _vm.loading
                            ? _c("ContentCardSkeleton")
                            : _c("ContentCard", {
                                attrs: { item: props.item },
                                on: {
                                  "view:item": function ($event) {
                                    return _vm.$emit("view:item", props.item)
                                  },
                                  "view:parent": function ($event) {
                                    return _vm.$emit(
                                      "view:parent",
                                      props.item.parent
                                    )
                                  },
                                  share: function ($event) {
                                    return _vm.$emit("share", props.item)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2635805518
            ),
          })
        : _c("CardCarousel", {
            attrs: {
              title: "home.favourites",
              items: _vm.resultSet,
              "card-width": 300,
            },
            scopedSlots: _vm._u([
              {
                key: "card",
                fn: function (props) {
                  return [
                    _vm.loading
                      ? _c("ContentCardSkeleton")
                      : _c("ContentCard", {
                          attrs: { item: props.item },
                          on: {
                            "view:item": function ($event) {
                              return _vm.$emit("view:item", props.item)
                            },
                            "view:parent": function ($event) {
                              return _vm.$emit("view:parent", props.item.parent)
                            },
                            share: function ($event) {
                              return _vm.$emit("share", props.item)
                            },
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }