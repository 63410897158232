var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { rounded: "" } },
    [
      _c("v-subheader", { staticClass: "c-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("model.animations").toUpperCase()) + " "),
      ]),
      _c(
        "v-list-item-group",
        {
          staticClass: "c-list-item-group",
          attrs: { "active-class": "c-list-item--active", multiple: "" },
          on: {
            change: function ($event) {
              return _vm.$emit("sync", _vm.selectedAnimationIds)
            },
          },
          model: {
            value: _vm.selectedAnimationIds,
            callback: function ($$v) {
              _vm.selectedAnimationIds = $$v
            },
            expression: "selectedAnimationIds",
          },
        },
        _vm._l(_vm.animations, function (animation) {
          return _c(
            "v-list-item",
            {
              key: animation.id,
              attrs: { dense: "", value: animation.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("select", animation.id)
                },
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(animation.name))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }