import ArticleViewer from '@/players/ArticleViewer'
import BookPlayer from '@/players/BookPlayer'
import ChatbotPlayer from '@/players/ChatbotPlayer'
import ImageViewer from '@/players/ImageViewer'
import ModelPlayer from '@/players/ModelPlayer'
import PosterViewer from '@/players/PosterViewer'
import TabletViewer from '@/players/TabletViewer'
import UnityPlayer from '@/players/UnityPlayer'
import VideoPlayer from '@/players/VideoPlayer'
import WebViewer from '@/players/WebViewer'

export class PlayerPlugin {
  static install(Vue, _options) {
    /* article viewer */
    Vue.component('ArticleViewer', ArticleViewer)
    /* "book" player (aka slides player) */
    Vue.component('BookPlayer', BookPlayer)
    /* chatbot w/ corpus */
    Vue.component('ChatbotPlayer', ChatbotPlayer)
    /* image viewer */
    Vue.component('ImageViewer', ImageViewer)
    /* model viewer */
    Vue.component('ModelPlayer', ModelPlayer)
    /* poster (aka scrollable image) viewer */
    Vue.component('PosterViewer', PosterViewer)
    /* tablet optimized Web page viewer */
    Vue.component('TabletViewer', TabletViewer)
    /* Unity application player */
    Vue.component('UnityPlayer', UnityPlayer)
    /* video player */
    Vue.component('VideoPlayer', VideoPlayer)
    /* Web page viewer */
    Vue.component('WebViewer', WebViewer)
  }
}
