var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "leaderboard", staticClass: "c-leaderboard-ads" },
    [
      _vm.showAds
        ? _c("AdSpot", {
            staticClass: "c-carousel",
            attrs: {
              carousel: "",
              max: 3,
              optimize: "",
              zone: "leaderboard",
              show: _vm.showAds,
            },
            on: {
              "update:show": function ($event) {
                return _vm.onClose($event)
              },
            },
          })
        : _vm._e(),
      _vm.hero
        ? _c("AdSpot", {
            staticClass: "c-hero",
            attrs: { max: 1, rollup: "", zone: "hero" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }