import client from '@/services/real'
class UserService {
  constructor() {
    this.service = 'userService'
  }

  fetchUserTenants = async (userId) => {
    const tenants = await client.fetchUserTenants(userId)
    return tenants
  }

  fetchUser = async (userId) => {
    const user = await client.fetchUser(userId)
    // return user.userId !== undefined ? user : null
    return user
  }

  addUser = async (user) => {
    const response = await client.addUser(user)
    return response
  }

  updateUser = async (user) => {
    const response = await client.updateUser(user)
    return response
  }

  verifyUser = async (userId) => {
    const response = await client.verifyUser(userId)
    return response
  }
}

export default UserService
export const userService = new UserService()
