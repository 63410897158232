var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-carousel",
    {
      staticClass: "c-carousel",
      attrs: {
        cycle: "",
        height: "100%",
        "hide-delimiters": true,
        "hide-delimiter-background": "",
        interval: "10000",
        "show-arrows": !_vm.isSingle,
        "show-arrows-on-hover": "",
        touch: {
          left: () => _vm.slide--,
          right: () => _vm.slide++,
        },
      },
      model: {
        value: _vm.slide,
        callback: function ($$v) {
          _vm.slide = $$v
        },
        expression: "slide",
      },
    },
    _vm._l(_vm.ads, function (ad, index) {
      return _c(
        "v-carousel-item",
        { key: ad.id, staticClass: "c-item" },
        [_vm._t("ad", null, { ad: ad, index: index })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }