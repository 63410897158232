// parse the URL to get the tenantKey, portalKey and orgKey values
export const parseURL = () => {
  const hostname = window.location.hostname.toLowerCase().replace(/www./g, '')
  const hostSplit = hostname.split('.') // convert host into an array of strings
  const hostSplitReversed = hostSplit.slice().reverse()
  const hostParts = hostSplit.length

  const isLocalhost = hostname === 'localhost'
  const isStaging = hostname.startsWith('staging')
  const useOverrides = isLocalhost || isStaging

  const isHosted = (hostname) => {
    return (
      hostname.endsWith('lumensify.com') ||
      hostname.endsWith('medrealms.com') ||
      hostname.endsWith('netlify.app') ||
      hostname.endsWith('azurewebsites.net')
    )
  }

  // parse out the tenantKey
  // (tenant key is defined by the second last string in the host's domain)
  const tenantKey =
    hostParts < 2
      ? hostSplitReversed[0]
      : isHosted(hostname)
      ? hostSplitReversed[2]
      : hostSplitReversed[1]

  // parse out the orgKey
  // (URL is parsed as 'portal.org' (where portal is a single string with a default value of 'app')
  const portalKey =
    (hostParts <= 2 && !isHosted(hostname)) || (hostParts <= 3 && isHosted(hostname))
      ? 'app'
      : hostSplit.shift()

  // parse out the orgKey
  const orgKey = hostSplit.join('.')

  // override keys
  const altTenantKey = process.env.VUE_APP_TENANT_KEY || 'healthplexus'
  const altOrgKey = process.env.VUE_APP_ORG_KEY || 'localhost'
  const altPortalKey = process.env.VUE_APP_PORTAL_KEY || 'app'

  const result = {
    tenantKey: useOverrides ? altTenantKey : tenantKey,
    orgKey: useOverrides ? altOrgKey : orgKey,
    portalKey: useOverrides ? altPortalKey : portalKey
  }

  return result
}
