<template>
  <v-menu close-on-click :close-on-content-click="false" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        :class="activatorClass"
        fab
        small
        v-bind="attrs"
        v-on="on"
        @click.stop
      >
        <v-avatar v-if="isAuthenticated" size="40">
          <img :src="avatarURL" />
        </v-avatar>
        <v-icon v-else color="accent" large> mdi-account </v-icon>
      </v-btn>
    </template>
    <v-list rounded>
      <!-- active account -->
      <v-list-item v-if="isAuthenticated">
        <v-list-item-avatar size="40">
          <a class="c-anchor" :href="$appConfig.avatarBaseURL" target="_blank" rel="noopener noreferrer">
            <v-avatar size="40">
              <img :src="avatarURL" />
            </v-avatar>
          </a>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-left">{{ userDisplayName }}</v-list-item-title>
          <v-list-item-subtitle class="text-left">{{ userEmail }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <!-- mode switcher -->
      <v-list-item @click.stop="updateMode">
        <!-- make the menu item icon itself clickable -->
        <v-list-item-action>
          <ModeSwitcher ref="modeSwitcher1" :activator="false" :mode="isDark" reverse />
        </v-list-item-action>
        <!-- use a toggle switch as the menu item value -->
        <v-list-item-title class="d-flex align-center justify-space-between text-h6 text-left">
          {{ $t('account.mode') }}
          <ModeSwitcher ref="modeSwitcher2" :activator="false" :mode="isDark" switch />
        </v-list-item-title>
      </v-list-item>

      <!-- locale switcher -->
      <v-list-group class="c-list-group" @click.stop>
        <template #activator>
          <v-list-item-action>
            <v-icon color="accent" x-large> mdi-translate </v-icon>
          </v-list-item-action>
          <v-list-item-title class="text-h6 text-left">
            {{ currentLocaleName }}
          </v-list-item-title>
        </template>
        <LocaleSelector />
      </v-list-group>

      <AccountSettings v-model="showSettings" />

      <!-- remaining items -->
      <v-list-item v-for="item in enabledMenuItems" :key="item.key" @click="item.action">
        <v-list-item-action>
          <v-icon color="accent" x-large>
            {{ item.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-title class="text-h6">
          {{ $t(item.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountSettings from '@/components/account/AccountSettings'
import LocaleSelector from '@/components/account/LocaleSelector'
import ModeSwitcher from '@/components/account/ModeSwitcher'

export default {
  name: 'AccountManager',

  components: {
    AccountSettings,
    LocaleSelector,
    ModeSwitcher
  },

  props: {
    activatorClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      showLocales: false,
      showProfile: false,
      showSettings: false,
      menuItems: [
        {
          key: 'profile',
          title: 'account.profile',
          icon: 'mdi-badge-account-horizontal',
          action: this.displayProfile,
          enabled: this.$feature('accountProfile')
        },
        {
          key: 'settings',
          title: 'account.settings',
          icon: 'mdi-cog',
          action: this.displaySettings,
          enabled: this.$feature('accountSettings')
        },
        {
          key: 'signout',
          title: 'account.signout',
          icon: 'mdi-location-exit',
          action: this.signOut,
          enabled: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters('i18nStore', ['getLocaleName']),

    avatarURL() {
      return this.$auth.getUserAvatar()
    },

    currentLocaleName() {
      return this.getLocaleName(this.$store.state.i18nStore.locale)
    },

    enabledMenuItems() {
      return this.menuItems.filter((item) => item.enabled)
    },

    isAuthenticated() {
      return this.$auth.isAuthenticated()
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    userEmail() {
      return this.$auth.getUserEmail()
    },

    userId() {
      return this.$auth.getUserId()
    },

    userDisplayName() {
      return this.$auth.getUserDisplayName()
    }
  },

  methods: {
    displayProfile() {
      // create a dialog popup with the profile information:
      // <ProfileModal v-if='showProfile' @close='showProfile=false' />
    },

    displaySettings() {
      // e.g. primary tenant, dark/light mode, primary lang: english/french, etc
      // <SettingsModal v-if='showSettings' @close='showSettings=false' />
      this.showSettings = true
    },

    signOut() {
      this.$auth.signOut({})
    },

    updateMode() {
      this.$refs.modeSwitcher1.toggleMode()
      this.$refs.modeSwitcher2.toggleMode()
    }
  }
}
</script>

<style lang="css" scoped>
.c-anchor {
  width: 100%;
  height: 100%;
}

.v-list-item__action {
  min-width: 32px !important;
}
.c-list-group .v-list-item--active.theme--dark {
  color: white !important;
}
.c-list-group .v-list-item--active.theme--light {
  color: black !important;
}
</style>
