var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("react-web-chat", {
        staticClass: "c-webchat mx-auto",
        class: { "c-webchat--dark": _vm.isDark },
        attrs: {
          directLine: _vm.directLine,
          locale: _vm.locale,
          sendTypingIndicator: true,
          selectVoice: _vm.selectVoice,
          styleOptions: _vm.styleOptions,
          store: _vm.webchatStore,
          webSpeechPonyfillFactory: _vm.speechPonyfill,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }