import { logger } from '@/services/loggerService.js'

export default {
  // implements the "errorCaptured" component lifecycle hook
  errorCaptured: function (err, vm, info) {
    const message = `VueComponent: ${vm.$options.name}: ${err.name} (in ${info})`
    const stack = err.stack
    logger.logError({ message, stack })
    return false // don't propogate error up the tree
  }
}
