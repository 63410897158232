import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const appPortal = {
  key: 'app',
  contentFilter: '',
  personFilter: ''
}

const initialState = {
  // persistent state
  portals: [],
  // transient state
  activePortal: null
}

export default {
  namespaced: true,

  state: () => initialState,

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.portals = [...newState.portals]
    },

    /* specific mutations */

    setPortals(state, portals) {
      state.portals = portals
    },

    setActivePortal(state, portal) {
      state.activePortal = portal
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* specific actions */

    updatePortals({ commit }, { portals, activePortalKey }) {
      commit('setPortals', portals)
      const activePortal = portals.find((portal) => portal.key === activePortalKey)
      commit('setActivePortal', activePortal || appPortal)
    }
  }
}
