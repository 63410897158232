<template>
  <WidgetWrapper
    class="c-quiz-widget"
    :title="$t('content.quiz')"
  >
    <QuizCard v-if="quizContent" :content="quizContent" />
  </WidgetWrapper>
</template>

<script>
import QuizCard from '@/components/quiz/QuizCard'
import WidgetWrapper from '@/components/base/WidgetWrapper'

export default {
  name: 'QuizWidget',

  components: {
    QuizCard,
    WidgetWrapper
  },

  props: {
    qid: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      quizContent: null
    }
  },

  created: function () {
    // get the quizById
    this.quizContent = 'getQuizById(qid)'
  }
}
</script>

<style lang="css" scoped>
</style>
