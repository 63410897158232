import Vue from 'vue'
import Vuex from 'vuex'
import { tenantService } from '@/services/tenantService.js'
import { parseURL } from '@/utilities/urlUtils.js'

Vue.use(Vuex)

const displayState = ({ tenantKey, orgKey, portalKey }) => {
  const hostname = window.location.hostname
  if (hostname === 'localhost' && tenantKey !== 'localhost') {
    console.warn('[tenantStore]: Using "localhost" env overrides...')
  }

  console.info('[tenantStore]: tenantKey=', tenantKey)
  console.info('[tenantStore]: orgKey=', orgKey)
  console.info('[tenantStore]: portalKey=', portalKey)
}

const initialState = {
  tenantKey: '',
  orgKey: '',
  portalKey: '',
  tenant: {},
  loading: false,
  promise: null
}

export default {
  namespaced: true,

  state: () => {
    const { tenantKey, orgKey, portalKey } = parseURL()
    return { ...initialState, tenantKey, orgKey, portalKey }
  },

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // only update tenant object
      state.tenant = newState.tenant
    },

    /* specific mutations */

    setTenantKey(state, tenantKey) {
      state.tenantKey = tenantKey.toLowerCase()
    },

    setPortalKey(state, portalKey) {
      console.warn('[tenantStore]: setPortalKey portalKey=', portalKey)
      state.portalKey = portalKey.toLowerCase()
    },

    setOrgKey(state, orgKey) {
      state.orgKey = orgKey.toLowerCase()
    },

    setAuthorizedDomains(state, authorizedDomains) {
      const lowercaseDomains = authorizedDomains.map((domain) => domain.toLowerCase())
      state.authorizedDomains = lowercaseDomains || []
    },

    setTenant(state, tenant) {
      state.tenant = { ...tenant }
    },

    setLoading(state, { loading, promise = null }) {
      state.loading = loading
      state.promise = promise
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      const mergedState = {
        ...state,
        ...sessionState,
        tenantKey: state.tenantKey,
        orgKey: state.orgKey,
        portalKey: state.portalKey
      }
      commit('syncState', mergedState)

      displayState(state)
    },

    /* specific actions */

    configureTenant({ commit }, { tenantKey, orgKey, portalKey }) {
      // configure elements extracted from URL
      commit('setTenantKey', tenantKey)
      commit('setOrgKey', orgKey)
      commit('setPortalKey', portalKey)
    },

    async fetchTenant({ state, commit }) {
      try {
        // handle case where multiple components fetch tenant
        let promise
        if (!state.loading) {
          console.debug(`[tenantStore]: Creating new promise to fetch tenant.`)
          promise = tenantService.fetchTenant(state.tenantKey)
          commit('setLoading', { loading: true, promise })
        } else {
          console.debug(`[tenantStore]: Using existing promise to fetch tenant.`)
          promise = state.promise
        }
        const tenant = await promise
        commit('setTenant', tenant)
        return tenant
      } finally {
        commit('setLoading', { loading: false })
      }
    },

    updateTenant({ state, commit }, { tenant }) {
      // configure elements from tenant database
      if (state.tenantKey === tenant.tenantKey) {
        commit('setAuthorizedDomains', [...tenant.authorizedDomains, tenant.domain])
      }
    }
  }
}
