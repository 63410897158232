import Vue from 'vue'

const bus = new Vue()

/*
import emitter from 'tiny-emitter/instance'

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}
*/

export class BusPlugin {
  static install(Vue, _options) {
    Vue.prototype.$bus = bus
  }
}

export const $bus = bus
