<template>
  <!-- mode switcher as a toggle switch -->
  <div v-if="isSwitch" class="d-flex justify-end align-center">
    <v-icon color="amber darken-4" large> mdi-white-balance-sunny </v-icon>
    <v-switch class="ml-4" color="accent" :input-value="currentMode" inset />
    <v-icon :color="isDark ? 'grey lighten-2' : 'grey darken-2'" large>
      mdi-moon-waxing-crescent
    </v-icon>
  </div>
  <!-- mode switcher as clickable icon (that shows the "to be" state) -->
  <div v-else>
    <v-btn fab :plain="!activator" :icon="!activator" small @click.stop="activate">
      <v-icon v-if="reverse ? !isDark : isDark" color="amber darken-4" large>
        mdi-white-balance-sunny
      </v-icon>
      <v-icon v-else color="white" large> mdi-moon-waxing-crescent </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModeSwitcher',

  components: {},

  props: {
    activator: {
      type: Boolean,
      required: false,
      default: true
    },

    mode: {
      type: Boolean,
      required: false,
      default: false
    },

    // renders icon showing the "to be" state
    reverse: {
      type: Boolean,
      required: false,
      default: false
    },

    // renders toggle switch
    switch: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      currentMode: this.$store.state.themeStore.isDark
    }
  },

  computed: {
    colour() {
      const dark = this.reverse ? !this.isDark : this.isDark
      return dark ? 'grey lighten-2' : 'grey darken-2'
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isSwitch() {
      return this.switch
    }
  },

  watch: {
    mode: {
      immediate: true,
      handler: function (newValue, _oldValue) {
        // ignore "mode" if internal activation is requested
        if (newValue !== this.currentMode && !this.activator) {
          this.setMode(newValue)
        }
      }
    }
  },

  created: function () {
    this.currentMode = this.isDark
  },

  methods: {
    ...mapActions('themeStore', ['updateMode']),

    activate() {
      if (this.activator) {
        this.toggleMode()
      }
    },

    setMode(mode) {
      this.currentMode = mode
      this.updateMode({ isDark: this.currentMode, vm: this })
      this.$emit('update:dark', this.currentMode)
    },

    toggleMode() {
      this.setMode(!this.currentMode)
    }
  }
}
</script>
