var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.revealBack
    ? _c(
        "v-card",
        {
          staticClass:
            "c-card--back c-card--reveal g-skinny-scrollbars transition-fast-in-fast-out",
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                { staticClass: "align-self-start py-0" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "px-0 text-xs-caption text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h6 text-left",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.author
                              ? _vm.$t("post.aboutAuthor")
                              : _vm.$t("post.aboutSponsor")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "align-self-start mt-3",
                  attrs: { icon: "", large: "", color: "teal accent-4" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("click:reveal", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _vm.author
            ? _c(
                "v-card-text",
                { staticClass: "text-left" },
                [
                  _c("PersonBanner", { attrs: { person: _vm.author } }),
                  _c("ClampedPortableText", {
                    staticClass: "mt-4",
                    attrs: { "localized-blocks": _vm.author.bio },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.sponsor
            ? _c(
                "v-card-text",
                { staticClass: "text-left" },
                [
                  _c("CompanyAvatar", { attrs: { company: _vm.sponsor } }),
                  _c("ClampedPortableText", {
                    staticClass: "mt-4",
                    attrs: { "localized-blocks": _vm.sponsor.bio },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }