var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c-figure d-flex flex-column justify-start align-center mb-4",
    },
    [
      _c("v-img", {
        staticClass: "c-image",
        attrs: { src: _vm.src, alt: _vm.caption ? "" : _vm.alt, contain: "" },
      }),
      _vm.attribution
        ? _c("div", { staticClass: "text-subtitle-1 font-italic" }, [
            _vm._v(_vm._s(_vm.attribution)),
          ])
        : _vm._e(),
      _vm.caption
        ? _c("div", { staticClass: "text-h5 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.caption)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }