<template>
  <div
    v-if="open"
    v-show="windowLoaded"
  >
    <slot />
  </div>
</template>

<script>
import uuid from 'uuid-random'

export default {
  name: 'PortalWindow',

  components: {},

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: 'Portal'
    },

    path: {
      type: String,
      required: false,
      default: ''
    },

    width: {
      type: Number,
      required: false,
      default: 300
    },

    height: {
      type: Number,
      required: false,
      default: 420
    },

    left: {
      type: Number,
      required: false,
      default: 100
    },

    top: {
      type: Number,
      required: false,
      default: 100
    },

    noStyle: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      windowRef: null,
      windowLoaded: false
    }
  },

  computed: {
    windowStyle() {
      return `
        width=${this.width},
        height=${this.height},
        left=${this.left},
        top=${this.top}
      `
    },

    windowOptions() {
      return `
        fullscreen=no,
        location=no,
        menubar=no,
        status=no,
        titlebar=no,
        toolbar=no
      `
    }
  },

  watch: {
    open: {
      immediate: false, // initial response handled via mounted hook
      handler(newValue, _oldValue) {
        console.debug('[PortalWindow]: open=', newValue)
        newValue ? this.openPortal() : this.closePortal()
      }
    }
  },

  mounted: function () {
    console.debug('[PortalWindow]: mounted!')
    if (this.open) {
      this.openPortal()
    }
    window.addEventListener('beforeunload', this.closePortal)
  },

  beforeDestroy() {
    console.debug('[PortalWindow]: beforeDestroy!')
    this.closePortal()
    window.removeEventListener('beforeunload', this.closePortal)
  },

  methods: {
    openPortal() {
      console.debug('[PortalWindow]: inside openPortal()')
      // only allow a single portal
      if (this.windowRef) {
        console.debug('[PortalWindow]: portal already open.')
        return
      }

      // open an empty window (and replace the content later)
      const windowURL = this.path
        ? `${window.location.origin}${this.path}`
        : `${window.location.origin}${window.location.pathname}_window`
      console.debug('[PortalWindow]: url=', windowURL)

      try {
        this.windowRef = window.open(windowURL, uuid(), `${this.windowOptions},${this.windowStyle}`)
      } catch (error) {
        console.error('[PortalWindow]: Unable to open portal.', error)
        throw error
      } finally {
        if (!this.windowRef) {
          const error = '[PortalWindow]: Portal did not open.'
          console.error(error)
        }
      }

      // detect if popups are blocked
      try {
        this.windowRef.focus()
      } catch (e) {
        this.$alert({
          icon: 'warning',
          title: this.$t(`error.PopupError.title`),
          text: this.$t(`error.PopupError.message`),
          showConfirmButton: true
        })
        this.$bus.$emit('reset')
        return
      }

      // add event listeners
      this.windowRef.addEventListener('load', () => {
        console.debug('[PortalWindow]: load handler - portal window loaded.')
        this.windowRef.document.title = this.title
        // this.fillSlot()
      })
      this.windowRef.addEventListener('unload', () => {
        console.debug('[PortalWindow]: unload handler - portal window closed.')
      })
      this.windowRef.addEventListener('beforeunload', (e) => {
        console.debug('[PortalWindow]: beforeunload handler - portal window closing.')
        const result = this.$alert({
          icon: 'warning',
          title: this.$t(`error.MeetingExitWarning.title`),
          text: this.$t(`error.MeetingExitWarning.message`),
          showConfirmButton: true,
          showDenyButton: true
        })
        if (result.isConfirmed) {
          this.closePortal()
        } else if (result.isDenied) {
          e.preventDefault()
          e.returnValue = 'Close meeting window?'
        }
      })
    },

    fillSlot() {
      console.debug('[Portal]: portal is ready for slot contents...')
      this.windowLoaded = true

      // clear any existing content
      this.windowRef.document.body.innerHTML = ''

      // make a copy of the component page
      const app = document.createElement('div')
      app.id = 'app'
      app.appendChild(this.$el)
      console.debug('div=', app)

      // insert the copy into the new window
      this.windowRef.document.body.appendChild(app)
      this.$emit('update:open', true)
      this.$emit('opened', this.windowRef)

      // clone all the stylesheet nodes
      if (!this.noStyle) {
        for (const css of document.head.querySelectorAll('style, link[rel=stylesheet]')) {
          const clone = css.cloneNode(true)
          this.windowRef.document.head.appendChild(clone)
        }
      }
    },

    closePortal() {
      console.debug('[PortalWindow]: inside closePortal(), this.windowRef=', this.windowRef)
      // return if the portal doesn't exist
      if (!this.windowRef) return

      // cleanup the portal state
      this.windowLoaded = false
      this.windowRef.close()
      this.windowRef = null
      this.$emit('update:open', false)
      this.$emit('closed')
    }
  }
}
</script>
