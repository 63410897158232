var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-references-card pt-4 px-4", attrs: { elevation: "4" } },
    [
      _c("v-card-title", { staticClass: "text-h6 text-left pa-0 pb-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("quiz.related")) + " "),
      ]),
      _c(
        "v-card-text",
        _vm._l(_vm.references, function (reference) {
          return _c(
            "div",
            { key: reference.id, staticClass: "c-references mb-4" },
            [_c("ReferenceCard", { attrs: { reference: reference } })],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }