<template>
  <v-expansion-panel ref="panel" class="c-section-panel" expand>
    <v-expansion-panel-header
      ref="panelHeader"
      class="c-section-panel-header text-h6"
      color="secondary"
    >
      <div class="c-header">
        <slot name="header">
          {{ title }} {{ loading ? '' : `(${count})` }}
        </slot>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="c-section-panel-content" color="sheet">

      <div class="c-toolbar">
        <slot name="toolbar" />
      </div>
      <v-progress-linear
        class=""
        :active="loading"
        color="red"
        :height="8"
        indeterminate
        striped
      />
      <div v-if="!loading && count === 0" class="c-text">
        <slot name="empty" class="pa-4">
          {{ $t('ui.empty') }}
        </slot>
      </div>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'SectionPanel',

  components: {},

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    count: {
      type: Number,
      required: false,
      default: -1
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      layout: 'tree'
    }
  },

  computed: {},

  mounted: function () {
    // window.onscroll = () => console.error('[SectionPanel]: scroll=', this.$refs.panel.$el.scrollTop)
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-section-panel {
  height: 100%;
  margin-top: 0px !important;
}
.c-section-panel-header {
  position: sticky;
  top: 0;
}
.c-section-panel-header,
.c-section-panel-header.v-expansion-panel-header--active {
  padding: 0px 12px;
  min-height: 48px;
}
.c-section-panel-content :deep(.v-expansion-panel-content__wrap) {
  height: 100%;
  padding: 0px;
}
.c-text {
  padding: 16px;
}
</style>
