var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-player-modal",
        fullscreen: "",
        transition: "fade",
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closePlayer.apply(null, arguments)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c("ContentPlayer", {
        attrs: { item: _vm.item },
        on: {
          close: _vm.closePlayer,
          "add:favourite": function ($event) {
            return _vm.$emit("add:favourite")
          },
          "remove:favourite": function ($event) {
            return _vm.$emit("remove:favourite")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }