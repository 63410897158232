// https://sweetalert2.github.io/

// import VueSweetalert2 from 'vue-sweetalert2'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vue from 'vue'

const $t = (msg, ...args) => Vue.prototype.$_i18n.t(msg, ...args)
const $te = (msg, ...args) => Vue.prototype.$_i18n.te(msg, ...args)

// global styling classes (g-*) are defined in App.vue

const Alert = Swal.mixin({
  allowEscapeKey: false,
  allowOutsideClick: false,
  background: 'var(--v-background-base)',
  customClass: {
    popup: 'g-alert-popup',
    title: 'g-alert-title',
    footer: 'g-alert-footer',
    htmlContainer: 'g-alert-body',
    actions: 'g-alert-actions',
    confirmButton: 'g-alert-confirm',
    cancelButton: 'g-alert-cancel',
    denyButton: 'g-alert-deny'
  }
})

/*
    // Usage:
    this.$alert({
      icon: 'error' | 'warning' | 'info' | 'success',
      title: <titleText>,
      text: <detailsText>,
      footer: <auxillaryTextOrHTML>
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: <buttonText>,
      denyButtonText: <buttonText>,
      cancelButtonText: <buttonText>
      ...
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      } else if (result.dismissed) {
        console.debug('Alert dismissed.')
      } else {
        console.debug('Alert default.')
      }
*/

const Toast = Swal.mixin({
  toast: true,
  background: 'var(--v-background-base)',
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

/*
    // Usage:
    this.$toast({
      icon: 'success',
      title: 'Signed in successfully!'
    })
*/

const showErrorAlert = async (error, params = {}) => {
  let title = ''
  let message = ''
  let iconKey = ''
  let footer = ''

  if ($te(`error.${error.name}.title`)) {
    title = $t(`error.${error.name}.title`)
    message = $t(`error.${error.name}.message`, params)
    iconKey = $te(`error.${error.name}.icon`)
      ? $t(`error.${error.name}.icon`)
      : (iconKey = $t(`error.SystemError.icon`))
    footer = error.message
  } else {
    title = $t(`error.SystemError.title`)
    message = $t(`error.SystemError.message`)
    iconKey = $t(`error.SystemError.icon`)
    footer = error.message
  }

  const icon = iconKey.indexOf('.') >= 0 ? 'error' : iconKey

  const result = await Alert.fire({
    icon: icon,
    title: title,
    text: message,
    footer: footer,
    showConfirmButton: true,
    confirmButtonText: $t('ui.restart'),
    showDenyButton: true,
    denyButtonText: $t('ui.exit')
  })

  if (result.isConfirmed) {
    // restart the app in the same browser window
    window.open(window.location.origin, '_self')
  } else if (result.isDenied) {
    // attempt to close the browser window
    const w = window.open(`${window.location.origin}/splash?notice=exit`, '_self')
    w.close()
  }
}

export class AlertPlugin {
  static install(Vue, _options) {
    // Vue.use(VueSweetalert2) // this.$swal
    Vue.prototype.$alert = (options) => Alert.fire(options)
    Vue.prototype.$toast = (options) => Toast.fire(options)
    Vue.prototype.$error = (error, params = {}) => showErrorAlert(error, params)
  }
}
