import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import modules from './modules'
import { createPolyglotPlugin } from '@/store/plugins/polyglotPlugin.js'

Vue.use(Vuex)

export function useStore() {
  return store
}

const polyglotPlugin = createPolyglotPlugin()

const store = new Vuex.Store({
  modules,

  // plugins: [vuexLocal.plugin, vuexIndexDB.plugin],
  plugins: [polyglotPlugin],

  actions
})

export default store
