// import client from '@client'
import client from '@/services/mock'

class CompanyService {
  constructor() {
    this.type = ''
  }

  getCompanies = () => {}

  fetchCompanies = async () => {
    const companies = await client.fetchCompanies()
    return companies
  }
}

export default CompanyService
export const companyService = new CompanyService()
