export default class AbstractHandler {
  constructor() {
    this.name = ''
    this.segmentLog = []
    this.x = 0
    this.y = 0

    // context related properties
    this.mainContext = null
    this.tempContext = null
    this.canvas = null
    this.offsetX = 0
    this.offsetY = 0
    this.options = {
      colour: 'red',
      font: 'regular',
      shape: 'line',
      width: '24'
    }
    this.isEraseMode = false
  }

  configure(context) {
    this.mainContext = context.mainContext
    this.tempContext = context.tempContext
    this.canvas = context.mainContext.canvas
    this.offsetX = context.offsetX ?? this.offsetX
    this.offsetY = context.offsetY ?? this.offsetY
    this.options = context.options ?? this.options
    this.isEraseMode = context.isEraseMode ?? this.isEraseMode
    this.setContextAttributes(this.options)
  }

  // manage context

  updateOptions(options) {
    this.options = { ...this.options, ...options }
    this.setContextAttributes(this.options)
  }

  getContextAttributes() {
    return {
      globalAlpha: 1,
      globalCompositeOperation: this.isEraseMode ? 'destination-out' : 'source-over',
      lineWidth: JSON.parse(JSON.stringify(this.options.width)),
      fillStyle: JSON.parse(JSON.stringify(this.options.colour)),
      strokeStyle: JSON.parse(JSON.stringify(this.options.colour))
    }
  }

  setContextAttributes(options) {
    for (const ctx of [this.mainContext, this.tempContext]) {
      ctx.lineWidth = options.width
      ctx.fillStyle = options.colour
      ctx.strokeStyle = options.colour
    }
  }

  getCoordinates(e) {
    const rect = e.currentTarget.getBoundingClientRect()
    /* notes:
     * 1)pageX is X position within the html document
     * 2)clientX is X position within the viewport
     * 3)rectX is X top/left position of the element
     * 4)scrollX is
     * 5)
     */

    const x = e.pageX - rect.left // pageX = position within the document
    const y = e.pageY - rect.top // y position within the element
    // const x = rect.left // pageX = position within the document
    // const y = rect.top // y position within the element

    console.log('*****')
    console.log(`(pageX,pageY)=(${e.pageX},${e.pageY})`)
    console.log(`(offsetX,offsetY)=(${this.canvas.offsetLeft},${this.canvas.offsetTop})`)
    console.log(`(targetX,targetY)=(${e.currentTarget.offsetLeft},${e.currentTarget.offsetTop})`)
    console.log(`(rectX,rectY)=(${x},${y})`)
    console.log(`(scrollX,scrollY)=(${window.scrollX},${window.scrollY})`)
    console.log(
      `(scrollX,scrollY)=(${e.currentTarget.scrollWidth},${e.currentTarget.scrollHeight})`
    )
    console.log('*****')

    // const newX = e.pageX - this.canvas.offsetLeft - this.offsetX
    // const newY = e.pageY - this.canvas.offsetTop - this.offsetY
    const newX = x
    const newY = y

    return { newX, newY }
  }

  setEraseMode(isEraseMode) {
    this.isEraseMode = isEraseMode
  }

  // manage drawing

  mousedown(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.x = newX
    this.y = newY
  }

  mousemove(_e) {}

  mouseup(_e) {}
}
