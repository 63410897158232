class LoggerService {
  /**
   * @constructor Logger
   */
  constructor() {
    this.log = console.log.bind(console)
    this.debug = console.debug.bind(console)
    this.info = console.info.bind(console)
    this.warn = console.warn.bind(console)
    this.error = console.error.bind(console)

    /* suppress general logging in production */
    const isProd = process.env.NODE_ENV === 'production'
    const isLocalhost = window.location.hostname === 'localhost'
    if (isProd && !isLocalhost) {
      this.log = this.debug = () => {}
    }
  }

  logError(err) {
    this.error('[Lumenii Error]: ' + err.message + '\n' + err.stack)
  }

  logWarning(warn) {
    this.warn('[Lumenii Warning]: ' + warn.message + '\n' + warn.stack)
  }

  logTrace(trace) {
    this.debug(`[${trace.component}]: ${trace.message}`)
  }
}

export default LoggerService
export const logger = new LoggerService()
