export default {
  methods: {
    /*
     * There are three "close" scenarios:
     * 1)If arrived via a (unauthenticated) share link -> Exit the app.
     * 2)If arrived via a (authenticated) bookmark -> Return to "home".
     * 3)If arrived via navigation -> Return to "previous" page
     *   ...where "previous" may required a forward route.
     */
    exitMixin_close({ from, to = null }) {
      const isFirstPage = from && !from.name

      if (isFirstPage) {
        // send authenticated users "Home" but others must exit the app
        this.$auth.isAuthenticated() ? this.$router.push({ name: 'home' }) : this.exitMixin_exit()
      } else {
        // go ahead and backup?
        to ? this.$router.push(to) : this.$router.back()
      }
    },

    exitMixin_exit() {
      // close the window (but first, ensure the app owns the window)
      const url = `${window.location.origin}/splash?notice=exit`
      const w = window.open(url, '_self')
      w.close()
      // return false if the window didn't close
      return false
    }
  }
}
