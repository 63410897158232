<template>
  <SectionPanels
    ref="homeview"
    class="c-home-view"
    :depth="depth"
    :initial="[0, 1, 2]"
  >
    <!-- Favourite Content -->
    <ContentPanel
      v-show="showFavourites"
      class="c-panel"
      colour="secondary"
      :items="userFavouriteItems"
      :layouts="['grid', 'carousel']"
      :loading="isContentLoading"
      :title="$t('home.favourites')"
      @share="shareItem($event)"
      @view:item="viewItem($event)"
      @view:parent="viewParent($event)"
    />

    <!-- Favourite Posts -->
    <PostPanel
      v-show="showPosts"
      class="c-panel"
      colour="secondary"
      :posts="userFavouritePosts"
      :layouts="['grid', 'carousel']"
      :loading="arePostsLoading"
      :title="$t('home.posts')"
      @share="sharePost($event)"
      @view:post="viewPost($event)"
    />

    <!-- Favourite Channels -->
    <ChannelPanel
      v-show="showChannels"
      class="c-panel"
      colour="secondary"
      :channels="userFavouriteChannels"
      :layouts="['grid', 'carousel']"
      :loading="areChannelsLoading"
      :title="$t('home.channels')"
      @share="shareChannel($event)"
      @view:channel="viewChannel($event)"
    />

    <!-- Calendar -->
    <SectionPanel
      v-if="showCalendar"
      class="c-panel"
      colour="secondary"
      :title="$t('home.today')"
      :count="userEventsCount"
      :loading="areEventsLoading"
    >
      <CalendarViewer :events="userEvents" period="day" @update:period="fetchCalendarToday" />
    </SectionPanel>

    <!-- Contacts -->
    <SectionPanel
      v-if="showContacts"
      class="c-panel"
      colour="secondary"
      :title="$t('home.contacts')"
      :count="userContactsCount"
      :loading="areContactsLoading"
    >
      <CardCarousel
        title="home.contacts"
        :items="userContacts"
        :card-width="300"
      >
        <template #card="props">
          <ClientCardSkeleton v-if="areContactsLoading" />
          <ContactCard v-else :contact="props.item" />
        </template>
      </CardCarousel>
    </SectionPanel>

    <!-- Companies -->
    <SectionPanel
      v-if="showCompanies"
      class="c-panel"
      colour="secondary"
      :title="$t('home.companies')"
      :count="userCompaniesCount"
      :loading="areCompaniesLoading"
    >
      <CardCarousel
        title="home.companies"
        :items="userCompanies"
        :card-width="300"
        :rerender="areCompaniesLoading"
      >
        <template #card="props">
          <ClientCardSkeleton v-if="areCompaniesLoading" />
          <CompanyCard v-else :company="props.item" />
        </template>
      </CardCarousel>
    </SectionPanel>

    <!-- Modals -->
    <ContentModal v-if="showContentModal" v-model="showContentModal" :item="item" />
    <PostModal v-if="showPostModal" v-model="showPostModal" :post="post" />
    <ShareModal v-if="showSharingModal" v-model="showSharingModal" :entity="sharedEntity" />
  </SectionPanels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// contacts
import ContactService from '@/services/contactService'
import CompanyService from '@/services/companyService'
import CalendarService from '@/services/calendarService'
// content
import ContentModal from '@/components/content/ContentModal'
import ContentPanel from '@/components/content/ContentPanel'
// clients
import ContactCard from '@/components/contact/ContactCard'
import CompanyCard from '@/components/company/CompanyCard'
import ClientCardSkeleton from '@/components/client/ClientCardSkeleton'
// events
import CalendarViewer from '@/components/calendar/CalendarViewer'
// posts
import PostPanel from '@/components/post/PostPanel'
import PostModal from '@/components/post/PostModal'
// channels
import ChannelPanel from '@/components/channel/ChannelPanel'
// base
import CardCarousel from '@/components/base/CardCarousel'
import SectionPanel from '@/components/base/SectionPanel'
import SectionPanels from '@/components/base/SectionPanels'
import ShareModal from '@/components/base/ShareModal'
import shareMixin from '@/mixins/shareMixin.js'
import viewMixin from '@/mixins/viewMixin.js'

export default {
  name: 'HomeView',

  components: {
    // content components
    ContentModal,
    ContentPanel,
    // client components
    ContactCard,
    CompanyCard,
    ClientCardSkeleton,
    // event components
    CalendarViewer,
    // post components
    PostPanel,
    PostModal,
    // channel components
    ChannelPanel,
    // base components
    CardCarousel,
    SectionPanel,
    SectionPanels,
    ShareModal
  },

  mixins: [shareMixin, viewMixin],

  data: function () {
    return {
      // page
      depth: 0,
      // content
      activeItem: null,
      showContentModal: false,
      // posts
      activePost: null,
      showPostModal: false,
      // channels
      activeChannel: null,
      // contacts
      contacts: [],
      areContactsLoading: true,
      // companies
      companies: [],
      areCompaniesLoading: true,
      // events
      userEvents: [],
      areEventsLoading: true,
      // sharing
      sharedEntity: {},
      showSharingModal: false
    }
  },

  computed: {
    ...mapGetters('contentStore', ['libraryItems', 'isLibraryPrimed']),
    ...mapGetters('channelStore', ['areChannelsPrimed']),
    ...mapGetters('postStore', ['arePostsPrimed']),

    menuRoutes() {
      return this.$store.state.menuStore.menuRoutes
    },

    /* panel controls */

    showFavourites() {
      return (
        this.menuRoutes.includes('content') ||
        this.menuRoutes.includes('collection') ||
        this.menuRoutes.includes('channels')
      )
    },

    showPosts() {
      return this.menuRoutes.includes('blog') || this.menuRoutes.includes('channels')
    },

    showChannels() {
      return this.menuRoutes.includes('channels')
    },

    showCalendar() {
      return this.menuRoutes.includes('schedule')
    },

    showCompanies() {
      return this.menuRoutes.includes('companies')
    },

    showContacts() {
      return this.menuRoutes.includes('contacts')
    },

    /* items */

    isContentLoading() {
      return !this.isLibraryPrimed
    },

    userFavouriteItemIds() {
      return this.$store.state.userStore.favourites
    },

    userFavouriteItems() {
      return this.libraryItems
        .filter((item) => this.userFavouriteItemIds.includes(item.id))
        .sort(
          (a, b) =>
            this.userFavouriteItemIds.indexOf(a.id) - this.userFavouriteItemIds.indexOf(b.id)
        )
    },

    /* posts */

    arePostsLoading() {
      return !this.arePostsPrimed
    },

    userFavouritePostIds() {
      return this.$store.state.userStore.posts
    },

    userFavouritePosts() {
      return this.$store.state.postStore.posts
        .filter((post) => this.userFavouritePostIds.includes(post.id))
        .sort(
          (a, b) =>
            this.userFavouritePostIds.indexOf(a.id) - this.userFavouritePostIds.indexOf(b.id)
        )
    },

    /* channels */

    areChannelsLoading() {
      return !this.areChannelsPrimed
    },

    userFavouriteChannelIds() {
      return this.$store.state.userStore.channels
    },

    userFavouriteChannels() {
      return this.$store.state.channelStore.channels
        .filter((channel) => this.userFavouriteChannelIds.includes(channel.id))
        .sort(
          (a, b) =>
            this.userFavouriteChannelIds.indexOf(a.id) - this.userFavouriteChannelIds.indexOf(b.id)
        )
    },

    /* calendar events */

    userEventsCount() {
      return this.userEvents.length
    },

    /* companies */

    userCompanyIds() {
      return this.$store.state.userStore.companies
    },

    userCompanies() {
      return this.companies.filter((company) => this.userCompanyIds.includes(company.id))
    },

    userCompaniesCount() {
      return this.userCompanies.length
    },

    /* contacts */

    userContactIds() {
      return this.$store.state.userStore.contacts
    },

    userContacts() {
      return this.contacts.filter((contact) => this.userContactIds.includes(contact.id))
    },

    userContactsCount() {
      return this.userContacts.length
    }
  },

  watch: {
    showContentModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    },

    showPostModal: {
      immediate: false, // no processing required until activated
      handler: function (newValue, _oldValue) {
        this.suspendScrolling(newValue)
      }
    }
  },

  created: async function () {
    /* retrieve all home page data in "parallel" */

    // ensure content store is primed
    if (this.showFavourites) {
      this.fetchLibrary()
    }

    // ensure post store is primed
    if (this.showPosts) {
      this.fetchPosts()
    }

    // ensure channel store is primed
    if (this.showChannels) {
      this.fetchChannels()
    }

    /* retrieve all non-store data */

    if (this.showContacts) {
      this.fetchContacts().then((contacts) => {
        this.contacts = contacts
        this.areContactsLoading = false
      })
    }

    if (this.showCompanies) {
      this.fetchCompanies().then((companies) => {
        this.companies = companies
        this.areCompaniesLoading = false
      })
    }

    if (this.showCalendar) {
      this.fetchCalendarToday().then((events) => {
        this.userEvents = events
        this.areEventsLoading = false
      })
    }
  },

  mounted: function () {
    const panels = this.$refs.homeview.$el.querySelectorAll('.c-panel')
    this.depth = panels.length || 0
  },

  updated: function () {},

  methods: {
    // TODO: eventually, all data should come from a "store"
    ...mapActions('contentStore', ['fetchLibrary']),
    ...mapActions('postStore', ['fetchPosts']),
    ...mapActions('channelStore', ['fetchChannels']),

    /* sharing */

    async shareChannel(channel) {
      this.activeChannel = channel
      this.sharedEntity = await this.shareMixin_shareChannel(channel)
      this.showSharingModal = !!this.sharedEntity
    },

    async shareItem(item) {
      this.activeItem = item
      this.sharedEntity = await this.shareMixin_shareItem(item)
      this.showSharingModal = !!this.sharedEntity
    },

    async sharePost(post) {
      this.activePost = post
      this.sharedEntity = await this.shareMixin_sharePost(post)
      this.showSharingModal = !!this.sharedEntity
    },

    /* viewing */

    viewChannel(channel) {
      // this.activeChannel = channel
      this.viewMixin_viewChannel(channel)
    },

    viewItem(item) {
      // this.activeItem = item
      // this.showContentModal = true
      this.viewMixin_viewItem(item)
    },

    viewParent(parent) {
      this.viewMixin_viewReference(parent)
    },

    viewPost(post) {
      // this.activePost = post
      // this.showPostModal = true
      this.viewMixin_viewPost(post)
    },

    /* calendar */

    async fetchCalendarToday() {
      const calendarService = new CalendarService()
      try {
        const d = new Date()
        const today = d.toJSON().slice(0, 10)
        const events = await calendarService.fetchCalendarEvents(today, today)
        return events
      } catch (error) {
        console.error(`[Home]: Unable to fetch calendar. ${error}`)
        return []
      }
    },

    /* contacts */

    async fetchContacts() {
      const contactService = new ContactService()
      try {
        const contacts = await contactService.fetchContacts()
        return contacts.data
      } catch (error) {
        console.error(`[Home]: Unable to fetch contacts. ${error}`)
        return []
      }
    },

    /* companies */

    async fetchCompanies() {
      const companyService = new CompanyService()
      try {
        const companies = await companyService.fetchCompanies()
        return companies.data
      } catch (error) {
        console.error(`[Home]: Unable to fetch companies. ${error}`)
        return []
      }
    },

    /* scrolling */

    suspendScrolling(suspend) {
      // prevent scrolling of underlying content grid/tree
      // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
      if (suspend) {
        document.body.style.top = `-${window.scrollY}px` // capture before setting position to fixed
        document.body.style.position = 'fixed'
      } else {
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
      // document.documentElement.addEventListener('touchmove', (e) => e.preventDefault())
    }
  }
}
</script>

<style lang="css" scoped>
.c-home-view {
  background-color: var(--v-sheet-base);
  min-height: 100%;
}
</style>
