var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-glb-viewer" },
    [
      _c(
        "model-viewer",
        {
          ref: "modelViewer",
          staticClass: "c-model-viewer",
          attrs: {
            loading: "auto",
            xposter: _vm.poster,
            src: _vm.modelURL,
            "camera-controls": "",
            "camera-orbit": _vm.cameraOrbit,
            "camera-target": _vm.defaultCameraTarget,
            exposure: _vm.exposure,
            "min-camera-orbit": "-Infinity 0deg auto",
            "max-camera-orbit": "Infinity 360deg auto",
            "interaction-prompt": _vm.prompt,
            "quick-look-browsers": "safari chrome",
            "touch-action": "pan-y",
            ar: "",
            "ar-placement": "wall",
            "ar-scale": "auto",
            "ar-modes": "webxr scene-viewer quick-look fallback",
            "animation-name": _vm.animation,
            autoplay: false,
            "disable-pan": _vm.disablePan,
            "disable-tap": false,
            "disable-zoom": _vm.disableZoom,
            "auto-rotate": _vm.autoRotate,
            "auto-rotate-delay": "0",
            "rotation-per-second": "45deg",
          },
          on: {
            load: function ($event) {
              return _vm.onModelLoad($event)
            },
            progress: function ($event) {
              return _vm.onProgress($event)
            },
            error: function ($event) {
              return _vm.onError($event)
            },
          },
        },
        [
          _vm.showProgress
            ? _c(
                "div",
                {
                  staticClass: "c-progress text-center",
                  attrs: { slot: "progress-bar" },
                  slot: "progress-bar",
                },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: 360,
                        size: 100,
                        width: 25,
                        value: _vm.percentage,
                        color: "primary",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.percentage + "%") + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "c-hotspot",
              attrs: {
                slot: "xhotspot-bladder",
                "data-position": "0 0 -10",
                "data-normal": "1 0 0",
              },
              slot: "xhotspot-bladder",
            },
            [
              _c("div", { staticClass: "c-annotation" }, [
                _vm._v("This is a hotspot."),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }