<template>
  <p
    class="text--secondary text-caption text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1 text-justify"
  >
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
    eirmod tempor invidunt ut...
  </p>
</template>

<script>
export default {
  name: 'ComponentName',

  components: {},

  model: {
    prop: 'prop1',
    event: 'event:custom'
  },

  props: {
    prop1: {
      type: Array,
      required: true
    },
    prop2: {
      type: String,
      required: false,
      default: 'hello'
    }
  },

  data: function () {
    return {
      loading: true,
      var1: false
    }
  },

  computed: {
    comp1() {
      return this.$store.state.storeModule.property
    },

    comp2: {
      get() {
        return this.$store.state.i18nStore.locale
      },
      set(value) {
        this.$store.dispatch('i18nStore/updateLocale', { value, vm: this })
      }
    }
  },

  watch: {
    prop1: {
      // for some reason, always need to watch a dynamic prop
      immediate: false, // initial response handled via data(), created or mounted
      handler(newValue, oldValue) {
        this.var1 = newValue || oldValue
      }
    }
  },

  created: function () {
    this.fetchData()
  },

  mounted: function () {},

  methods: {
    fetchData() {
      this.loading = true
      this.$store.dispatch('fetchServerData').then((_serverData) => {
        this.loading = false
      })
    },

    method1(value) {
      this.var1 = value
      this.$emit('update:value', value)
    }
  }
}
</script>

<style lang="css" scoped>
.c-text {
  hyphens: none;
}
</style>
