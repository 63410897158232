var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "video", staticClass: "c-video-player c-video-wrapper" },
    [
      _c(
        "video",
        {
          attrs: {
            controlsList: "nodownload noremoteplayback",
            controls: "",
            preload: "metadata",
            poster: _vm.poster,
          },
        },
        [
          _c("source", { attrs: { src: _vm.url, type: "video/mp4" } }),
          _vm._v(
            " " + _vm._s(_vm.$t("notice.browser.videoNotSupported")) + " "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }