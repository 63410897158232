import { logger } from '@/services/loggerService.js'

const ignoreList = [
  'button-group',
  'ContentCard',
  'ContentCardBack',
  'CssGroup',
  'transition',
  'transition-group'
]

const ignore = (component) => {
  return component?.startsWith('v-') || ignoreList.includes(component)
}

const getName = (vm) => {
  return vm === vm.$root
    ? 'root'
    : vm._isVue
    ? vm.$options.name || vm.$options._componentTag
    : vm.name || 'anonymous'
}

export default {
  created: function () {
    const component = getName(this)
    if (!ignore(component)) logger.logTrace({ component, message: 'Created' })
  },

  mounted: function () {
    const component = getName(this)
    if (!ignore(component)) logger.logTrace({ component, message: 'Mounted' })
  },

  unmounted: function () {
    const component = getName(this)
    if (!ignore(component)) logger.logTrace({ component, message: 'Unmounted' })
  }
}
