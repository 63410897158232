import orgs from './orgs.json'
import { fakeFetch } from '@/services/mock/fakeFetch'

export const fetchOrgs = () => {
  return fakeFetch(orgs, 500) // wait 500ms before returning
}

export const fetchOrg = (key) => {
  const org = orgs.find((org) => org.key === key || org.aliases.includes(key))
  if (!org) throw new Error(`Cannot find org with key=${key}.`)
  return fakeFetch(org, 500) // wait 500ms before returning
}
