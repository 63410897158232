import ICS from 'vue-ics'
import { VuePlugin } from 'vuera'

export class MiscPlugin {
  static install(Vue, _options) {
    // generate .ics calendar files
    Vue.use(ICS, {
      uidDomain: 'metaspark',
      prodId: 'lumenii'
    })

    // call React components from Vue
    Vue.use(VuePlugin)
  }
}
