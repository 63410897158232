// layouts
import AppLayout from '@/layouts/AppLayout'
import PageLayout from '@/layouts/PageLayout'
import PlayerLayout from '@/layouts/PlayerLayout'

export class LayoutPlugin {
  static install(Vue, _options) {
    Vue.component('AppLayout', AppLayout)
    Vue.component('PageLayout', PageLayout)
    Vue.component('PlayerLayout', PlayerLayout)
  }
}
