var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-modal",
        "overlay-opacity": "0.75",
        transition: "dialog-top-transition",
        width: "75%",
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal()
        },
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("v-card", { staticClass: "c-modal-content" }, [
        _c("iframe", { attrs: { id: "popup", src: _vm.src } }),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "c-btn-close",
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        },
        [_c("v-icon", { attrs: { "x-large": "" } }, [_vm._v(" mdi-close ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }