<template>
  <v-avatar
    class="d-flex justify-center align-center my-auto"
    :class="{ 'c-clickable': clickable }"
    :color="colour"
    :size="size"
    @click="clickable && $emit('click')"
  >
    <v-img v-if="avatar" :lazy-src="require('@/assets/images/avatars/avatar.png')" :src="avatar" />
    <span v-else class="white--text text-h5">
      {{ personInitials }}
    </span>
  </v-avatar>
</template>

<script>
import { getImage } from '@/config/sanityConfig'

export default {
  name: 'PersonAvatar',

  components: {},

  props: {
    person: {
      type: Object,
      required: true
    },

    center: {
      type: Boolean,
      required: false,
      default: false
    },

    clickable: {
      type: Boolean,
      required: false,
      default: false
    },

    colour: {
      type: String,
      required: false,
      default: 'purple'
    },

    size: {
      type: Number,
      required: false,
      default: 72
    }
  },

  data: function () {
    return {}
  },

  computed: {
    avatar() {
      return this.person.picture
        ? getImage(this.person.picture).width(this.size).height(this.size).url()
        : this.person.avatar
    },

    personInitials() {
      return (this.person.firstName?.[0] || '?') + (this.person.lastName?.[0] || '?')
    }
  }
}
</script>

<style lang="css" scoped>
.c-clickable {
  cursor: pointer;
}
</style>
