var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-popup",
        "overlay-opacity": "0.75",
        transition: "dialog-top-transition",
        width: _vm.width,
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closePopup()
        },
      },
      model: {
        value: _vm.showPopup,
        callback: function ($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup",
      },
    },
    [_vm._t("ad", null, { ad: _vm.ad, index: 0 })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }