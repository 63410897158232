export default {
  computed: {
    viewMixin_isShared() {
      return this.$route.name === 'player' || this.$route.name === 'channel'
    }
  },

  methods: {
    viewMixin_viewChannel(channel) {
      this.$router.push({
        name: this.viewMixin_isShared ? 'channel' : 'ichannel',
        params: {
          id: channel.id,
          slug: channel.slug
        }
      })
    },

    viewMixin_viewItem(item) {
      this.$router.push({
        name: this.viewMixin_isShared ? 'player' : 'iplayer',
        params: {
          id: item.id,
          slug: item.slug
        }
      })
    },

    viewMixin_viewPost(post) {
      this.$router.push({
        name: this.viewMixin_isShared ? 'post' : 'ipost',
        params: {
          id: post.id,
          slug: post.slug
        }
      })
    },

    viewMixin_viewReference(reference) {
      console.warn('reference=', reference)
      // content
      if (reference.type === 'content') {
        this.viewMixin_viewItem(reference)
      }
      // post
      if (reference.type === 'post') {
        this.viewMixin_viewPost(reference)
      }
      // channel
      if (reference.type === 'channel') {
        this.viewMixin_viewChannel(reference)
      }
      // otherwise, do nothing
    }
  }
}
