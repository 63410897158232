/*
 * Icons:
 *   - generated by https://realfavicongenerator.net
 *   - alternatives:
 *     - https://www.pwabuilder.com/imageGenerator
 *     - maskable icons generated by https://maskable.app/editor
 *     - https://favicon.io/favicon-converter/
 */
import { isPWA } from '@/pwa.js'

export default {
  computed: {
    pwaMixin_isPWA() {
      return isPWA()
    }
  },

  methods: {
    /* index.html methods */

    pwaMixin_updateMetaTag(tagName, tagValue) {
      document.querySelector(`meta[name="${tagName}"]`)?.setAttribute('content', tagValue)
    },

    pwaMixin_updateLinkTag(tagRel, attribute, value) {
      document.querySelectorAll(`link[rel="${tagRel}"]`)?.[0].setAttribute(attribute, value)
    },

    pwaMixin_updateHeadTags({ appName, assetRoot, themeColour }) {
      const assetPath = assetRoot.replace(/^\/|\/$/g, '')
      const iconRoot = `/${assetPath}/icons`

      // application name
      this.pwaMixin_updateMetaTag('application-name', appName)

      // favicons
      document.getElementById('faviconICO').href = `${iconRoot}/favicon.ico`
      document.getElementById('faviconSVG').href = `${iconRoot}/favicon.svg`
      // document.getElementById('favicon48').href = `${iconRoot}/favicon-48x48.png`
      document.getElementById('favicon32').href = `${iconRoot}/favicon-32x32.png`
      document.getElementById('favicon16').href = `${iconRoot}/favicon-16x16.png`

      // Android
      // this.pwaMixin_updateMetaTag('mobile-web-app-capable', 'yes')
      document.getElementById('android192').href = `${iconRoot}/android-chrome-192x192.png`

      // Apple
      // this.pwaMixin_updateMetaTag('apple-mobile-web-app-capable', 'yes')
      // this.pwaMixin_updateMetaTag('apple-mobile-web-app-status_bar_style', 'default')
      this.pwaMixin_updateMetaTag('apple-mobile-web-app-title', appName)
      document.getElementById('appleTouchIcon').href = `${iconRoot}/apple-touch-icon-180x180.png`
      // document.getElementById('safariPinnedTab').href = `${iconRoot}/safari-pinned-tab.svg`
      document.getElementById('safariPinnedTab').href = `${iconRoot}/favicon.svg`
      document.getElementById('safariPinnedTab').color = themeColour
      // this.pwaMixin_updateLinkTag('mask-icon', 'color', themeColour)

      // Microsoft
      this.pwaMixin_updateMetaTag('msapplication-TileImage', `${iconRoot}/msapp-icon-144x144.png`)
      this.pwaMixin_updateMetaTag('msapplication-TileColor', themeColour)
      this.pwaMixin_updateMetaTag('msapplication-config', `${iconRoot}/browserconfig.xml`)
    },

    /* manifest methods */

    pwaMixin_generateManifest({ appName, appDescription, assetRoot, orgName, themeColour }) {
      const isLocalhost = window.location.hostname === 'localhost'
      const fqdn = isLocalhost ? window.location.host : window.location.hostname
      const assetPath = assetRoot.replace(/^\/|\/$/g, '')
      return {
        // pwa installation configuration
        id: appName,
        name: this.$t('app.name', { appName, orgName }),
        short_name: appName,
        description: appDescription,
        related_applications: [],
        prefer_related_applications: false,
        screenshots: [
          {
            src: `https://${fqdn}/${assetPath}/screenshots/screenshot-narrow.png`,
            sizes: '1280x720',
            type: 'image/png',
            // form_factor: 'narrow',
            label: 'Homescreen'
          },
          {
            src: `https://${fqdn}/${assetPath}/screenshots/screenshot-wide.png`,
            sizes: '1280x720',
            type: 'image/png',
            form_factor: 'wide',
            label: 'Homescreen'
          }
        ],

        // pwa runtime configuration
        scope: `https://${fqdn}`,
        start_url: `https://${fqdn}`,

        // pwa rendering
        background_color: '#293132',
        dir: 'ltr',
        display: 'standalone', // browser looks at display_override list first
        display_override: ['minimal-ui'],
        orientation: 'any',
        theme_color: themeColour, // sets the colour of the toolbar

        // pwa icons
        icons: [
          {
            src: `https://${fqdn}/${assetPath}/icons/android-chrome-192x192.png`,
            type: 'image/png',
            sizes: '192x192'
          },
          {
            src: `https://${fqdn}/${assetPath}/icons/android-chrome-512x512.png`,
            type: 'image/png',
            sizes: '512x512'
          },
          {
            src: `https://${fqdn}/${assetPath}/icons/android-chrome-maskable-512x512.png`,
            type: 'image/png',
            sizes: '512x512',
            purpose: 'maskable'
          }
        ]
      }
    },

    pwaMixin_injectManifest(manifestObject) {
      console.warn('[pwaMixin]: manifest=', manifestObject)
      const stringManifest = JSON.stringify(manifestObject)
      const blob = new Blob([stringManifest], { type: 'application/json' })
      const manifestURL = URL.createObjectURL(blob)
      document.getElementById('manifest').setAttribute('href', manifestURL)
      // this.pwaMixin_updateLinkTag('manifest', 'href', manifestURL)
    },

    pwaMixin_updateManifestAndHeadTags(options) {
      this.pwaMixin_injectManifest(this.pwaMixin_generateManifest(options))
      this.pwaMixin_updateHeadTags(options)
    }
  }
}
