<template>
  <v-dialog
    v-model="dialog"
    content-class="c-share-dialog"
    :fullscreen="$vuetify.breakpoint.xsAndDown"
    max-width="208px"
    min-width="208px"
    overlay-opacity="0.2"
    scrollable
    transition="scale-transition"
    width="80%"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    <v-card align="center">
      <v-toolbar class="c-share-toolbar" dark dense color="primary">
        <v-btn icon dark @click="closeModal">
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('dialog.sharing.banner') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-actions class="px-0">
        <v-list rounded>
          <v-list-item-group color="accent">
            <v-list-item
              v-for="(item, i) in sharingList"
              :key="i"
              class="c-share-item"
              :href="item.href"
              target="item.target"
              @click="item.click"
            >
              <v-list-item-icon class="c-share-icon">
                <v-btn bottom :color="item.colour" dark fab small>
                  <v-icon>{{ `${item.icon}` }}</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content class="text-left">
                <v-list-item-title class="text-h6">
                  {{ $t(item.text) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import shareMixin from '@/mixins/shareMixin'

export default {
  name: 'ShareModal',

  components: {},

  mixins: [shareMixin],

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    entity: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      dialog: this.show,
      sharingList: []
    }
  },

  computed: {
    entityId() {
      return this.entity.id
    },

    subject() {
      return this.entity.subject
    },

    body() {
      return this.entity.body
    },

    routeName() {
      return this.entity.routeName
    }
  },

  mounted: function () {
    this.sharingList = [...this.generateSharingList()]
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy: function () {
    document.removeEventListener('keydown', this.onKeydown)
  },

  methods: {
    generateSharingList() {
      const shareableId = this.shareMixin_prepId(this.entityId)
      const encodedSubject = encodeURIComponent(this.subject)
      const encodedBody = encodeURIComponent(this.body)
      const encodedLink = `${window.location.origin}/${this.routeName}/${shareableId}`
      const encodedSpacer = encodeURIComponent(`\n\n`)
      return [
        {
          icon: 'mdi-chat-processing',
          text: 'dialog.sharing.sms',
          colour: 'green darken-7',
          href: `sms://?body=${encodedSubject}${encodedSpacer}${encodedBody}${encodedSpacer}${encodedLink}`,
          target: '_blank',
          click: this.doNothing
        },
        {
          icon: 'mdi-whatsapp',
          text: 'dialog.sharing.whatsapp',
          colour: 'green',
          href: `https://wa.me/?text=${encodedSubject}${encodedSpacer}${encodedLink}`,
          target: '_blank',
          click: this.doNothing
        },
        {
          icon: 'mdi-mail',
          text: 'dialog.sharing.mail',
          colour: 'blue',
          href: `mailto:?subject=${encodedSubject}&body=${encodedBody}${encodedSpacer}${encodedLink}`,
          target: '_blank',
          click: this.doNothing
        },
        {
          icon: 'mdi-link-variant',
          text: 'dialog.sharing.copy',
          colour: 'orange',
          href: '',
          target: '',
          click: () => this.copyToClipboard(encodedLink)
        }
      ]
    },

    async copyToClipboard(text) {
      try {
        if (window.navigator) {
          await window.navigator.clipboard.writeText(text)
        }
      } catch (error) {
        console.error('[ShareModal]: Failed to copy link: ', error)
      }
    },

    closeModal() {
      this.dialog = false
      this.$emit('close', false)
    },

    doNothing() {
      return true
    },

    fallbackCopyToClipboard(text) {
      const dummy = document.createElement('input')
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      dummy.focus()
      document.execCommand('copy')
      document.body.removeChild(dummy)
    },

    onKeydown(e) {
      if (e.key === 'Escape') {
        console.debug('[ShareModal]: Escape key pressed!')
        // this.closeModal()
      }
    }
  }
}
</script>

<style lang="css">
.c-share-dialog {
  border: 4px solid var(--v-primary-base);
  border-radius: 32px;
}

.c-share-dialog:not(.v-dialog--fullscreen) {
  max-height: 50%;
  min-height: 200px;
}

.c-share-toolbar {
  flex-grow: 0;
}

.c-share-icon {
  margin-right: 16px !important;
  margin-top: 8px;
  margin-bottom: 8px;
}

.c-share-item {
  height: 56px;
}
</style>
