import enCustom from '@/locales/en.json'
import enVuetify from 'vuetify/lib/locale/en.js'
import { logger } from '@/services/loggerService.js'

// maintain the set of loaded messages
const loadedMessages = { en: { ...enCustom, $vuetify: enVuetify } }
const loadedLocales = ['en']
const localeContext = require.context('./', false, /^.*\.json$/)
const availableLocales = localeContext.keys().forEach(localeContext)

const loadVuetifyMessageModule = async (lang) => {
  let langModule

  // using [request] inside chunkname imports *all* files in directory
  // e.g. /* webpackChunkName: "vuetify-msgs-[request]" */ `vuetify/lib/locale/${language}.js`

  switch (lang) {
    case 'en':
      langModule = await import(
        /* webpackChunkName: "vuetify-msgs-en" */ 'vuetify/lib/locale/en.js'
      )
      break
    case 'fr':
      langModule = await import(
        /* webpackChunkName: "vuetify-msgs-fr" */ 'vuetify/lib/locale/fr.js'
      )
      break
    default:
      langModule = await import(
        /* webpackChunkName: "vuetify-msgs-en.js" */ 'vuetify/lib/locale/en.js'
      )
      break
  }

  return langModule
}

export async function loadMessages(locale) {
  if (loadedLocales.includes(locale)) {
    return loadedMessages[locale]
    // else lazy load the messages for this locale
  } else {
    try {
      // load custom messages
      const customModule = await import(
        /* webpackChunkName: "custom-msgs-[request]", webpackInclude: /\[en|fr].js$/ */ `@/locales/${locale}.json`
      )
      const customMessages = customModule.default

      // load vuetify messages
      const language = extractLanguage(locale)
      const vuetifyModule = await loadVuetifyMessageModule(language)
      const vuetifyMessages = vuetifyModule.default

      // save and return the combined vue-i18n message set for specified locale
      loadedLocales.push(locale)
      loadedMessages[locale] = { ...customMessages, $vuetify: vuetifyMessages }
      return loadedMessages[locale]
    } catch (err) {
      logger.logError(err)
      return loadedMessages.en
    }
  }
}

export const getAvailableLocales = () => availableLocales

export const extractLanguage = (locale) => {
  const languages = {
    zh: 'zh-Hans',
    sr: 'sr-Cyrl'
  }

  let language = locale.substring(0, 2)
  language = languages[language] || language

  return language
}
