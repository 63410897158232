var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.overlapMode === "stack"
    ? _c(
        "v-btn",
        { attrs: { color: "icon", icon: "" }, on: { click: _vm.toggleMode } },
        [
          _c("v-icon", { attrs: { "x-large": "" } }, [
            _vm._v(" mdi-view-column "),
          ]),
        ],
        1
      )
    : _c(
        "v-btn",
        { attrs: { color: "icon", icon: "" }, on: { click: _vm.toggleMode } },
        [
          _c("v-icon", { attrs: { "x-large": "" } }, [
            _vm._v(" mdi-checkbox-multiple-blank "),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }