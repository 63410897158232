<template>
  <div ref="leaderboard" class="c-leaderboard-ads">
    <AdSpot
      v-if="showAds"
      class="c-carousel"
      carousel
      :max="3"
      optimize
      zone="leaderboard"
      :show="showAds"
      @update:show="onClose($event)"
    />
    <AdSpot
      v-if="hero"
      class="c-hero"
      :max="1"
      rollup
      zone="hero"
    />
  </div>
</template>

<script>
import AdSpot from '@/components/ad/AdSpot'
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'AdLeaderboard',

  components: {
    AdSpot
  },

  mixins: [mobileMixin],

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    hero: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      observer: null,
      showAds: this.show
    }
  },

  computed: {
    isMobile() {
      return this.mobileMixin_isMobile
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    isViewportSmall() {
      return this.mobileMixin_isViewportSmall
    },

    orientation() {
      return this.mobileMixin_orientation
    }
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.showAds = newShow
      }
    }
  },

  created: function () {
    this.observer = new ResizeObserver(this.onResize)
  },

  mounted: function () {
    this.observer.observe(this.$refs.leaderboard)
  },

  beforeDestroy: function () {
    if (this.observer) this.observer.disconnect()
  },

  methods: {
    onClose() {
      this.showAds = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onResize(entries) {
      const height = entries[0].contentRect.height
      if (height > 0 && height !== this.headerHeight) {
        this.$emit('height', height)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-leaderboard-ads {
  width: 100%;
  max-width: 100%; /* flex item: so it doesn't overflow */
  min-width: 0; /* flex item: so it can shrink */
}
</style>
