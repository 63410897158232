<template>
  <div class="c-content-player">
    <div v-if="overlay" class="c-content-player__overlay"></div>

    <slot name=player :params="params" :player-class="'c-content-player__player'" />

    <DrawCanvas
      class="c-content-player__canvas"
      :class="{'c-canvas-active': isCanvasActivated}"
      :activated="isCanvasActivated"
      :type="item.mediaType"
      @capture="capture"
      @download="download"
    />
  </div>
</template>

<script>
import DrawCanvas from '@/components/draw/DrawCanvas'
import { toPng } from 'html-to-image'

export default {
  name: 'ContentPlayer',

  components: {
    DrawCanvas
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    canvas: {
      type: Boolean,
      required: false,
      default: false
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    overlay: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      // canvas
      canvasHeight: 0,
      canvasWidth: 0,
      isCanvasActivated: false,
      wasCanvasActivated: false,
      offsetX: 0,
      offsetY: 0,

      // content
      context: {},
      params: [],

      // preview
      previewSrc: '',
      showPreview: false
    }
  },

  computed: {
    isFullscreen() {
      return this.fullscreen
    }
  },

  watch: {
    canvas: {
      handler: function (newValue, _oldValue) {
        this.isCanvasActivated = newValue
      }
    },

    isFullscreen: {
      immediate: false,
      handler: function (newFullscreen, _oldFullscreen) {
        if (newFullscreen) {
          this.wasCanvasActivated = this.isCanvasActivated
          this.isCanvasActivated = false
        } else {
          this.isCanvasActivated = this.wasCanvasActivated
        }
      }
    }
  },

  mounted: function () {
    console.debug('[ContentPlayer]: item=', this.item)
    this.params = this.parseParams(this.item.params)
    // console.debug('[ContentPlayer]: params=', JSON.stringify(this.item.params || {}))
    this.isCanvasActivated = this.canvas
  },

  methods: {
    async capture() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.createElement('video')

      /* capture screen */
      try {
        // start RTC video stream
        const captureStream = await navigator.mediaDevices.getDisplayMedia()
        video.srcObject = captureStream

        // capture an data image of the video frame
        context.drawImage(video, 0, 0, window.width, window.height)
        const dataURI = canvas.toDataURL('image/png')
        console.log('dataURI=', dataURI)

        // create a blob from the data image
        const dataBlob = await (await fetch(dataURI)).blob()
        console.log('blob=', dataBlob)

        // close the RTC video stream
        captureStream.getTracks().forEach((track) => track.stop())

        // paste the blob into the clipboard and download it
        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': dataBlob })])
        window.saveAs(dataBlob, 'lumenii.png')

        // render the img in a preview popup
        this.previewSrc = dataURI
        this.showPreview = true
      } catch (error) {
        console.error('[DrawCanvas]: Error capturing screen.', error)
      }
    },

    async download() {
      // generate a DOM image
      try {
        const dataURI = await toPng(this.$refs.contentPlayer)
        this.previewSrc = dataURI
      } catch (error) {
        console.error('[ContentPlayer]: Oops, something went wrong!', error)
      }

      // render the img in a preview popup
      this.showPreview = true

      // download image
      const link = document.createElement('a')
      link.download = 'lumenii.png'
      link.href = this.previewSrc
      link.click()
    },

    parseParams(params) {
      console.debug('[ContentPlayer]: params=', params)
      if (!params) return []

      return params.map((param) => {
        const key = param.name
        const value = param.value === 'false' ? false : param.value === 'true' ? true : param.value
        return { [key]: value }
      })
    }
  }
}
</script>

<style lang="css" scoped>
/* Layers:
 *  -1: Drawing Canvas (inactive)
 *   0: Player Viewport
 *   1: Drawing Canvas (active)
 *   2: Content Details Drawer
 *   3: Header Bar
 *   3: Controls
 */

.c-content-player {
  position: relative;
  z-index: 0;
}

.c-content-player__overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.c-content-player__canvas {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  min-height: 100%;
}
.c-content-player__canvas.c-canvas-active {
  display: block;
  z-index: 1;
}
</style>
