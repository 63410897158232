var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "c-share-dialog",
        fullscreen: _vm.$vuetify.breakpoint.xsAndDown,
        "max-width": "208px",
        "min-width": "208px",
        "overlay-opacity": "0.2",
        scrollable: "",
        transition: "scale-transition",
        width: "80%",
      },
      on: {
        "click:outside": _vm.closeModal,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { align: "center" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "c-share-toolbar",
              attrs: { dark: "", dense: "", color: "primary" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.closeModal },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.$t("dialog.sharing.banner"))),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-0" },
            [
              _c(
                "v-list",
                { attrs: { rounded: "" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "accent" } },
                    _vm._l(_vm.sharingList, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          staticClass: "c-share-item",
                          attrs: { href: item.href, target: "item.target" },
                          on: { click: item.click },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "c-share-icon" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: item.colour,
                                    dark: "",
                                    fab: "",
                                    small: "",
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(`${item.icon}`)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-left" },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-h6" },
                                [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }