var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-prologue-card pa-2", attrs: { elevation: "4" } },
    [
      _c(
        "v-card-title",
        { staticClass: "c-question-text c-text font-italic text-h6 py-2" },
        [_vm._v(" " + _vm._s(_vm.$t("quiz.prologue")) + " ")]
      ),
      _c(
        "div",
        { staticClass: "c-quiz-prologue d-flex justify-center align-center" },
        [
          _vm.quiz.image
            ? _c(
                "v-card-text",
                { staticClass: "c-figure c-half ma-auto" },
                [
                  _c("v-img", {
                    staticClass: "ma-auto",
                    attrs: { contain: "", src: _vm.quiz.image.url },
                  }),
                  _c("div", { staticClass: "text-center ma-auto" }, [
                    _vm._v(" " + _vm._s(_vm.quiz.image.caption) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "c-prologue-text c-half ma-auto" },
            [_c("RichText", { attrs: { blocks: _vm.quiz.prologue } })],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-space-around pa-4 pt-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("start")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("quiz.action.start")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }