<template>
  <div>
    <!-- whose picture do we show? -->
    <div v-if="hasAuthor">
      <div v-if="hasGuests" class="text-h3">
        {{ isBlog ? $t('post.author') : $t('post.host') }}
      </div>
      <PersonBanner :person="post.author" />
    </div>

    <!-- are there guests / co-authors? -->
    <div v-if="hasGuests" class="mt-4">
      <div class="text-h3">
        {{ isBlog ? $t('post.coAuthors') : $t('post.guests') }}
      </div>
      <PersonBanner
        v-for="guest in post.guests"
        :key="guest.lastName"
        :person="guest"
      />
    </div>
  </div>
</template>

<script>
import PersonBanner from '@/components/person/PersonBanner'

export default {
  name: 'PostPrologue',

  components: {
    PersonBanner
  },

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasAuthor() {
      return !!this.post.author
    },

    hasHost() {
      return !!this.post.host
    },

    hasGuests() {
      return this.post.guests?.length > 0
    }
  },

  mounted: function () {}
}
</script>

<style lang="css" scoped>
</style>
