var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-text", { staticClass: "c-widget d-flex pa-2" }, [
        _c(
          "div",
          {
            staticClass: "c-thumbnail c-clickable pa-2",
            on: {
              click: function ($event) {
                return _vm.viewReference(_vm.reference)
              },
            },
          },
          [
            _c("v-img", {
              attrs: {
                "aspect-ratio": _vm.thumbnailAspectRatio,
                contain: "",
                src: _vm.reference.thumbnail,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-card-title",
              { staticClass: "px-2 py-0 text-h6 text-sm-h4 text-left" },
              [_vm._v(" " + _vm._s(_vm.reference.title) + " ")]
            ),
            _c(
              "v-card-actions",
              { staticClass: "c-actions" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.viewReference(_vm.reference)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("quiz.action.view")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }