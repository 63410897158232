<template>
  <v-card class="c-prologue-card pa-2" elevation="4">
    <v-card-title class="c-question-text c-text font-italic text-h6 py-2">
        {{ $t('quiz.prologue') }}
    </v-card-title>

    <div class="c-quiz-prologue d-flex justify-center align-center">
      <!-- image -->
      <v-card-text
        v-if="quiz.image"
        class="c-figure c-half ma-auto"
      >
        <v-img
          class="ma-auto"
          contain
          :src="quiz.image.url"
        />
        <div class="text-center ma-auto">
          {{ quiz.image.caption }}
        </div>
      </v-card-text>

      <!-- (rich) text -->
      <v-card-text class="c-prologue-text c-half ma-auto">
        <RichText :blocks="quiz.prologue"/>
      </v-card-text>
    </div>

    <v-card-actions
      class="d-flex justify-space-around pa-4 pt-0"
    >
      <v-btn
        class="mr-4"
        color="primary"
        @click="$emit('start')"
      >
      {{ $t('quiz.action.start') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RichText from '@/components/base/RichText'

export default {
  name: 'QuizPrologueCard',

  components: {
    RichText
  },

  props: {
    quiz: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    progress() {
      return (this.answers / this.questions) * 100
    },

    formattedProgress() {
      return this.progress.toFixed(0)
    }
  }
}
</script>

<style lang="css" scoped>
.c-prologue-card {
  width: 100%;
}

.c-prologue-text > p {
  font-size: 125%;
}

.c-half {
  flex: 1 1 50%;
}
.c-figure {
  max-width: 350px;
}
</style>
