<template>
  <div
    ref="card"
    class="c-quiz-card d-flex flex-column justify-start align-center mx-auto"
  >
    <QuizToolbar
      :current="currentQuestion"
      :questions="quiz.questions"
      :show-progress="isActive"
      :title="title || quiz.title"
      @select="currentQuestion = $event"
    />

    <v-card-text v-if="hasDescription">
      <ClampedText>
        {{ quiz.description }}
      </ClampedText>
    </v-card-text>

    <QuizProgressCard
      v-if="quizLength > 1"
      class="mb-4"
      :questions="quizLength"
      :answers="quizLength - unanswered()"
    />

    <div class="c-transition">
      <transition
        :name="transitionName"
        :mode="transitionMode"
      >
        <div
          :key="currentQuestion"
          class="c-carousel"
        >
          <QuizPrologueCard
            v-if="showPrologue"
            :quiz="quiz"
            @start="start()"
          />

          <QuizQuestionCard
            v-else-if="isActive"
            :key="currentQuestion"
            class="c-question-card g-skinny-scrollbars"
            :qno="currentQuestion + 1"
            :question="quiz.questions[currentQuestion].question"
            :choices="quiz.questions[currentQuestion].responses"
            :answer="userResponses[currentQuestion]"
            :first="isFirst"
            :last="currentQuestion === quizLength - 1"
            :review="isReview"
            @next="goForward($event)"
            @prev="goBack($event)"
            @exit="isReview = false"
          />

          <QuizResultCard
            v-else
            class="c-result-card"
            :quiz="quiz"
            :references="references"
            :source="source"
            :user-responses="userResponses"
            @retake="onRetake()"
            @review="onReview()"
          />
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import ClampedText from '@/components/base/ClampedText'
import QuizPrologueCard from '@/components/quiz/QuizPrologueCard'
import QuizProgressCard from '@/components/quiz/QuizProgressCard'
import QuizQuestionCard from '@/components/quiz/QuizQuestionCard'
import QuizResultCard from '@/components/quiz/QuizResultCard'
import QuizToolbar from '@/components/quiz/QuizToolbar'
import { quizService } from '@/services/quizService.js'

export default {
  name: 'QuizPlayer',

  components: {
    ClampedText,
    QuizPrologueCard,
    QuizProgressCard,
    QuizQuestionCard,
    QuizResultCard,
    QuizToolbar
  },

  props: {
    quiz: {
      type: Object,
      required: true
    },

    references: {
      type: Array,
      required: false,
      default: () => []
    },

    source: {
      type: Object,
      required: false,
      default: null
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      isReview: false,
      currentQuestion: this.quiz.prologue ? -1 : 0,
      userResponses: [],
      quizService: quizService,
      transitionName: 'slide-left',
      transitionMode: ''
    }
  },

  computed: {
    hasDescription() {
      return this.quiz.description?.length > 0
    },

    quizLength() {
      return this.quiz.questions.length
    },

    isActive() {
      return this.currentQuestion >= 0 && this.currentQuestion < this.quizLength
    },

    isFirst() {
      return this.quiz.prologue ? this.currentQuestion === -1 : this.currentQuestion === 0
    },

    showPrologue() {
      return this.quiz.prologue && this.currentQuestion < 0
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },

  watch: {
    currentQuestion: {
      immediate: false,
      handler: function (_newQuestion, _oldQuestion) {
        // scroll up to top of card (next card may be taller than viewport)
        this.$refs.card.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },

  created: async function () {
    this.resetAnswers()
  },

  methods: {
    onRetake() {
      this.currentQuestion = 0
      this.isReview = false
      this.resetAnswers()
    },

    onReview() {
      this.currentQuestion = 0
      this.isReview = true
    },

    resetAnswers() {
      this.userResponses = Array(this.quizLength).fill(null)
    },

    gotoQuestion(currentQuestion) {
      // allow the transition to complete before incrementing the question
      this.$nextTick(() => {
        this.currentQuestion = currentQuestion
      })
    },

    start() {
      this.transitionName = 'slide-left'
      this.gotoQuestion(0)
    },

    goForward(answer) {
      this.transitionName = 'slide-left'
      if (!this.review) this.userResponses[this.currentQuestion] = answer
      if (this.currentQuestion < this.quizLength) this.gotoQuestion(++this.currentQuestion)
    },

    goBack(answer) {
      this.transitionName = 'slide-right'
      if (!this.review) this.userResponses[this.currentQuestion] = answer
      this.$nextTick(() => {
        if ((this.quiz.prologue && this.currentQuestion === 0) || this.currentQuestion > 0)
          --this.currentQuestion
      })
    },

    unanswered() {
      return this.userResponses.reduce(
        (count, response) =>
          response == null || (Array.isArray(response) && response.length === 0) ? ++count : count,
        0
      )
    }
  }
}
</script>

<style lang="css" scoped>
.c-quiz-card {
  width: 100%;
}
.c-transition {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.c-carousel {
}

.c-question,
.c-result {
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 1;
}
</style>
