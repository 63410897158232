var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c-loader d-flex flex-column align-center justify-space-around",
    },
    [
      _c("CircularProgress", {
        attrs: { message: _vm.$t("message.loadingModel") },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c("div", { staticClass: "c-progress-bar" }, [
                  _c("div", {
                    staticClass: "c-progress-bar-fill",
                    style: _vm.progressBarFillStyle,
                  }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }