<template>
  <v-dialog
    v-if="device"
    v-model="showPopup"
    content-class="c-install-popup"
    eager
    :fullscreen="$vuetify.breakpoint.xsAndDown"
    overlay-opacity="0.2"
    transition="scale-transition"
    width="80%"
    max-width="600px"
    min-width="300px"
    @click:outside="cancelPopup"
    @keydown.esc="cancelPopup"
  >
    <v-card>
      <v-card-title>{{ $t('app.install') }}</v-card-title>

      <v-card-text>
        <ul v-if="device === 'iPad'">
          <li>Tap the <v-icon>mdi-export-variant</v-icon> button, which is usually found right beside the browser's address bar.</li>
          <li>A new menu should open. Find the "Add to Home Screen" button and tap it.</li>
          <li>Tap "Add".</li>
        </ul>
        <ul v-if="device === 'iPhone'">
          <li>Tap the <v-icon>mdi-export-variant</v-icon> button in the menu bar.</li>
          <li>Scroll down to find the "Add to Home Screen" button and tap it.</li>
          <li>On the next screen, tap "Add".</li>
        </ul>
      </v-card-text>

      <v-img
        height="250"
        :src=addToHomeScreenImage
      />

      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="cancelPopup">
          {{ $t('ui.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppInstallPopup',

  components: {},

  model: {
    prop: 'show',
    event: 'cancel'
  },

  props: {
    device: {
      type: String,
      required: false,
      default: ''
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      showPopup: false
    }
  },

  computed: {
    addToHomeScreenImage() {
      const images = {
        iPad: require(`@/assets/images/pwa/iPadAddToHomeScreen.png`),
        iPhone: require(`@/assets/images/pwa/iPhoneAddToHomeScreen.png`)
      }
      return images[this.device]
    }
  },

  watch: {
    show: {
      immediate: false, // initial response handled via data()
      handler: function (newValue, _oldValue) {
        this.showPopup = newValue
      }
    }
  },

  mounted: function () {
    this.showPopup = this.show
  },

  methods: {
    cancelPopup() {
      this.showPopup = false
      this.$emit('cancel', false)
    }
  }
}
</script>

<style lang="css" scoped>
.c-install-popup {
  border-color: var(--v-accent-base);
  border-style: solid;
}
</style>
