<!-- eslint-disable vue/attribute-hyphenation -->
<!-- (React components use camelCase attributes) -->
<template>
  <!-- note: property is PonyFill vs Ponyfill (lowercase 'f') -->
  <react-web-chat
    v-if="isReady"
    class="c-webchat mx-auto"
    :class="{ 'c-webchat--dark': isDark }"
    :directLine="directLine"
    :locale="locale"
    :sendTypingIndicator="true"
    :selectVoice="selectVoice"
    :styleOptions="styleOptions"
    :store="webchatStore"
    :webSpeechPonyfillFactory="speechPonyfill"
  />
</template>

<script>
// import the React component
import ReactWebChat, {
  createBrowserWebSpeechPonyfillFactory,
  createCognitiveServicesSpeechServicesPonyfillFactory,
  createDirectLine,
  createStore
  // renderWebChat - alternative to using the react-web-chat component
} from 'botframework-webchat'
import botConfig from './botConfig'
import ChatbotService from '@/services/chatbotService'

export default {
  name: 'ChatbotBase',

  components: {
    'react-web-chat': ReactWebChat
  },

  props: {
    corpus: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      avatarImage: require('@/assets/images/avatars/melissa-avatar-with-name.png'),
      chatbotService: null,
      conversationId: '',
      directLine: null,
      dlToken: null,
      speechPonyfill: null,
      voice: '',
      webchatStore: null
    }
  },

  computed: {
    /* environment */

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    isChrome() {
      return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    },

    isSafari() {
      return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    /* chatbot configuration */

    isReady() {
      return this.directLine && this.webchatStore && this.speechPonyfill
    },

    styleOptions() {
      return {
        botAvatarImage: botConfig.avatarImage,
        botAvatarInitials: botConfig.avatarInitials,
        avatarSize: 40,
        userAvatarInitials: this.$t('chatbot.you'),
        rootWidth: '100%',
        rootHeight: '100%',
        sendBoxHeight: 40,
        typingAnimationHeight: 40,
        hideUploadButton: true,
        bubbleBorder: 'solid 3px #E6E6E6'
      }
    }
  },

  watch: {
    isReady: {
      immediate: true,
      handler: function (newValue, _oldValue) {
        if (newValue) {
          this.$emit('ready')
        }
      }
    }
  },

  created: async function () {
    try {
      this.chatbotService = new ChatbotService()

      // create the DirectLine object
      this.dlToken = await this.chatbotService.getDirectLineToken()
      // FIXME: for some reason, only a local method returning the directLine works (for freezing)
      this.directLine = await this.getDirectLine({
        token: this.dlToken,
        locale: this.locale
      })

      // freeze the object to avoid a recursive loop in Vue reactivity
      this.directLine.conversationId = null
      this.directLine.referenceGrammarId = null
      this.directLine.streamUrl = null
      this.directLine.tokenRefreshSubscription = null
      Object.freeze(this.directLine)

      // Webchat automatically refreshes the token
    } catch (error) {
      console.error('[ChatBotPlayer]: Error creating chatbot.', error)
      this.$emit('error:loading')
    }
  },

  mounted: async function () {
    try {
      // create the speech Ponyfill
      const { region, token: ssToken } = await this.chatbotService.getSpeechServicesToken()
      this.speechPonyfill = await this.createHybridPonyfillFactory({
        region,
        ssToken
      })

      // create the webchat store
      this.webchatStore = this.getWebchatStore({
        locale: this.locale,
        corpus: this.corpus
      })
    } catch (error) {
      console.error('[ChatBotPlayer]: Error mounting chatbot.', error)
      this.$emit('error:loading')
    }
  },

  methods: {
    /* token methods */

    async getDirectLineToken() {
      try {
        const response = await fetch('/api/token/directline')
        if (response.ok) {
          const { token } = await response.json()
          return token
        } else {
          throw new Error(response.statusText)
        }
      } catch (error) {
        const errorMessage = `[ChatbotPlayer]: Error retrieving direct line token. ${error}`
        console.error(errorMessage)
        throw errorMessage
      }
    },

    async getSpeechServicesToken() {
      try {
        const response = await fetch('/api/token/speechservices')
        if (response.ok) {
          const { region, token } = await response.json()
          return { region, token }
        } else {
          throw new Error(response.statusText)
        }
      } catch (error) {
        const errorMessage = `[ChatbotPlayer]: Error retrieving speech services token: ${error}`
        console.error(errorMessage)
        throw errorMessage
      }
    },

    /* channel methods */

    async getDirectLine({ token: dlToken, locale }) {
      try {
        if (dlToken) {
          const directLine = createDirectLine({
            token: dlToken,
            // optional: properties to send to the bot on conversation start
            conversationStartProperties: {
              locale: locale
            }
          })

          // flesh out the directLine object properties so it can be frozen
          directLine.conversationId = null
          directLine.streamUrl = null
          directLine.referenceGrammarId = null
          directLine.tokenRefreshSubscription = null

          return directLine
        } else {
          const errorMessage = '[ChatBotPlayer]: Missing directLine token.'
          throw new Error(errorMessage)
        }
      } catch (error) {
        const errorMessage = `[ChatBotPlayer]: Unable to create direct line object. ${error}`
        console.error(errorMessage)
        throw errorMessage
      }
    },

    selectVoice(voices, activity) {
      console.debug('[ChatbotPlayer]: voices=', voices)
      console.debug('[ChatbotPlayer]: activity=', activity)
      if (activity.locale === 'en') {
        return voices.find(({ name }) => /ClaraNeural/.test(name))
      } else {
        return voices.find(({ name }) => /ClaraNeural/.test(name))
      }
    },

    async createHybridPonyfillFactory({ ssToken, region }) {
      try {
        const creds = {
          credentials: {
            authorizationToken: ssToken,
            region
          }
        }

        const speechServicesPonyfillFactory =
          await createCognitiveServicesSpeechServicesPonyfillFactory(creds)

        return (creds) => {
          const speechServicesPonyfill = speechServicesPonyfillFactory(creds)
          let webSpeechPonyfill = speechServicesPonyfill
          if (this.isChrome) {
            const webSpeechPonyfillFactory = createBrowserWebSpeechPonyfillFactory()
            webSpeechPonyfill = webSpeechPonyfillFactory(creds)
          }

          const speechSynthesis = speechServicesPonyfill.speechSynthesis
          speechSynthesis.getVoices = () => {
            return [
              {
                lang: botConfig.lang,
                gender: botConfig.gender,
                voiceURI: botConfig.voice
              }
            ]
          }

          return {
            SpeechGrammarList: webSpeechPonyfill.SpeechGrammarList,
            SpeechRecognition: webSpeechPonyfill.SpeechRecognition,

            speechSynthesis: speechSynthesis,
            SpeechSynthesisUtterance: speechServicesPonyfill.SpeechSynthesisUtterance
          }
        }
      } catch (error) {
        const errorMessage = `[ChatbotPlayer]: Unable to create hybrid ponfill speech factory. ${error}`
        console.error(errorMessage)
        throw errorMessage
      }
    },

    /* Webchat methods */

    clearChatHistory() {
      document.querySelector('.webchat__basic-transcript__transcript')?.remove()
    },

    getWebchatStore({ locale, corpus }) {
      return createStore({}, ({ dispatch }) => (next) => (action) => {
        switch (action.type) {
          // when a directLine connection occurs, notify the bot
          case 'DIRECT_LINE/CONNECT_FULFILLED': {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'webchat/join',
                value: { corpus, locale }
              }
            })
            break
          }
          // after each activity is fulfilled, scroll to show the most recent bubble
          case 'DIRECT_LINE/POST_ACTIVITY_FULFILLED': {
            document
              .querySelector('.webchat__basic-transcript__transcript')
              ?.lastChild?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            break
          }
        }
        return next(action)
      })
    }
  }
}
</script>

<style lang="css" scoped>
/* bubble size */
.c-webchat :deep(.webchat__bubble) {
  min-width: 40% !important;
  max-width: 800px !important;
}

/* dark mode colours */
.c-webchat--dark {
  --c-guidance-text-colour: #c0c0c0;
  --c-status-background-colour: #c0c0c0;
}

/* dark mode: toast message boxes */
.c-webchat--dark :deep(.webchat__toaster) {
  background-color: var(--v-sheet-base);
  border: 2px solid;
  border-color: var(--v-accent-base);
}
.c-webchat--dark :deep(svg).webchat__toast__icon {
  fill: var(--v-error-base);
}
.c-webchat--dark :deep(.webchat__toast__dismissButtonFocus) svg {
  fill: var(--c-guidance-text-colour);
}

/* dark mode: transcript area and avatar */
.c-webchat--dark :deep(.webchat__basic-transcript) {
  background-color: var(--v-sheet-base) !important;
}
.c-webchat--dark :deep(.webchat__bubble__content) {
  background-color: var(--v-background-base) !important;
  color: white !important;
}
.c-webchat--dark :deep(.webchat__imageAvatar__image) {
  border: white solid 1px;
  border-radius: 50%;
  background-color: black;
}

/* dark mode: text input box */
.c-webchat--dark :deep(.webchat__send-box__main) {
  background-color: var(--v-background-base) !important;
}
.c-webchat--dark :deep(.webchat__send-box-text-box__input) {
  background: var(--v-background-base) !important;
  color: white !important;
}
.c-webchat--dark :deep(.webchat__send-box-text-box) .webchat__send-box-text-box__input::placeholder,
.c-webchat--dark
  :deep(.webchat__send-box-text-box)
  .webchat__send-box-text-box__html-text-area::placeholder {
  color: var(--c-guidance-text-colour) !important;
}

/* dark mode: make timestamp messages brighter */
.c-webchat--dark :deep(.webchat__stacked-layout__status) span {
  color: var(--c-guidance-text-colour) !important;
}

/* dark mode: adjust the connectivity status message */
.c-webchat--dark :deep(.webchat__connectivityStatus) {
  background-color: var(--c-status-background-colour);
  margin: 0px !important;
  padding: 8px 14px !important;
}

/* dark mode: make icons brigher/white */
.c-webchat--dark :deep(.webchat__icon-button):not(:disabled):not([aria-disabled='true']) svg {
  fill: var(--c-guidance-text-colour) !important;
}
.c-webchat--dark :deep(.webchat__icon-button):not(:disabled):not([aria-disabled='true']):focus svg,
.c-webchat--dark :deep(.webchat__icon-button):not(:disabled):not([aria-disabled='true']):hover svg {
  fill: white !important;
}
.c-webchat--dark :deep(.webchat__icon-button__shade) {
  background-color: black !important;
}

/* make microphone icon red when listening (including during focus or hover) */
.c-webchat--dark
  :deep(.webchat__microphone-button).webchat__microphone-button--dictating
  .webchat__microphone-button__button
  .webchat__microphone-button__icon,
.c-webchat--dark
  :deep(.webchat__microphone-button).webchat__microphone-button--dictating
  .webchat__microphone-button__button:focus
  .webchat__microphone-button__icon,
.c-webchat--dark
  :deep(.webchat__microphone-button).webchat__microphone-button--dictating
  .webchat__microphone-button__button:hover
  .webchat__microphone-button__icon {
  fill: red !important;
}

/* adaptive cards */
.c-webchat--dark :deep(.ac-textBlock) {
  color: white !important;
}
.c-webchat :deep(button).ac-pushButton {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
</style>
