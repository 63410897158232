import Vue from 'vue'
import Vuex from 'vuex'

import appConfig from '@/config/appConfig'

Vue.use(Vuex)

const systemIsDark =
  typeof window !== 'undefined' && window.matchMedia
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
      ? true
      : window.matchMedia('(prefers-color-scheme: light)').matches
      ? false
      : appConfig.defaultIsDark // no browser preference defined
    : appConfig.defaultIsDark // rendered server side (?)

const handlers = {
  darkModeHandler: null,
  lightModeHandler: null
}

const initialState = {
  isAutoMode: false,
  isDark: systemIsDark,
  theme: appConfig.theme
}

const updateRootMode = (isDark) => {
  if (isDark) {
    document.documentElement.classList.add('theme--dark')
    document.documentElement.classList.remove('theme--light')
  } else {
    document.documentElement.classList.add('theme--light')
    document.documentElement.classList.remove('theme--dark')
  }
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      // spread operator breaks reactivity: state = {...state, ...newState}
      Object.keys(newState).forEach((key) => {
        // ignore any deprecated keys
        if (key in state) state[key] = newState[key]
      })
    },

    /* specific mutations */

    setAutoMode(state, autoMode) {
      state.isAutoMode = autoMode
    },

    setIsDark(state, isDark) {
      state.isDark = isDark
    },

    setTheme(state, theme) {
      state.theme = { ...theme }
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState, vm }) {
      // set automatic mode
      if (sessionState.isAutoMode === 'undefined') {
        sessionState.isAutoMode = state.isAutoMode
      }

      // set dark mode
      if (sessionState.isAutoMode) {
        sessionState.isDark = systemIsDark
      } else {
        if (sessionState.isDark == null) {
          sessionState.isDark = state.isDark
        }
      }
      vm.$vuetify.theme.dark = sessionState.isDark
      updateRootMode(sessionState.isDark)

      // FIXME: set theme (is this even necessary given theme is set via org config?)
      if (sessionState.theme === 'undefined') {
        sessionState.theme = { ...state.theme }
        vm.$vuetify.theme.themes.dark = { ...state.theme.themes.dark }
        vm.$vuetify.theme.themes.light = { ...state.theme.themes.light }
        vm.$vuetify.theme.applyTheme()
      } else {
        // theme will be set by the orgStore
      }
      commit('syncState', sessionState)
    },

    /* specific actions */

    updateMode({ commit }, { isDark, vm }) {
      vm.$vuetify.theme.dark = isDark
      updateRootMode(isDark)
      commit('setIsDark', isDark)
    },

    updateAutoMode({ state, commit }, { autoMode }) {
      if (typeof window !== 'undefined' && window.matchMedia) {
        // monitor and reflect user changes to browser preference
        if (!state.isAutoMode && autoMode) {
          handlers.darkModeHandler = (e) => e.matches && commit('themeStore/setIsDark', true)
          handlers.lightModeHandler = (e) => e.matches && commit('themeStore/setIsDark', false)
          window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', handlers.darkModeHandler)
          window
            .matchMedia('(prefers-color-scheme: light)')
            .addEventListener('change', handlers.lightModeHandler)
        } else if (!autoMode) {
          window.removeEventListener('change', handlers.darkModeHandler)
          window.removeEventListener('change', handlers.lightModeHandler)
        }
        commit('setAutoMode', autoMode)
      }
    },

    updateBranding({ commit }, { brandTheme, vm }) {
      try {
        // org theme is a delta to the overall app theme
        const updatedTheme = { ...appConfig.theme }

        // apply org branding colours
        // (light)
        updatedTheme.themes.light.primary = brandTheme.themes.light.primary
        updatedTheme.themes.light.secondary = brandTheme.themes.light.secondary
        updatedTheme.themes.light.tertiary = brandTheme.themes.light.tertiary
        updatedTheme.themes.light.accent = brandTheme.themes.light.accent
        // (dark)
        updatedTheme.themes.dark.primary = brandTheme.themes.dark.primary
        updatedTheme.themes.dark.secondary = brandTheme.themes.dark.secondary
        updatedTheme.themes.dark.tertiary = brandTheme.themes.dark.tertiary
        updatedTheme.themes.dark.accent = brandTheme.themes.dark.accent

        // apply the theme
        // vm.$vuetify.theme.themes.dark = { ...updatedTheme.themes.dark }
        const dark = updatedTheme.themes.dark
        Object.keys(dark).forEach((colour) => {
          vm.$vuetify.theme.themes.dark[colour] = dark[colour]
        })
        // vm.$vuetify.theme.themes.light = { ...updatedTheme.themes.light }
        const light = updatedTheme.themes.light
        Object.keys(light).forEach((colour) => {
          vm.$vuetify.theme.themes.light[colour] = light[colour]
        })
        vm.$vuetify.theme.applyTheme()

        commit('setTheme', updatedTheme)
      } catch (error) {
        // vm.$logger.logError(err)
        console.error('[Error]: Updating org theme failed.', error)
      }
    }
  }
}
