import Vue from 'vue'

export default function () {
  Vue.filter('date', (value) => {
    const date = new Date(value)
    return date.toLocaleString(['en-US'], {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  })

  Vue.filter('capitalize', (value) => {
    if (!value && value !== 0) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  })

  Vue.filter('truncate', (value, limit) => {
    return value.substring(0, limit)
  })

  Vue.filter('tailing', (value, tail) => {
    return value + tail
  })
}
