var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.device
    ? _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "c-install-popup",
            eager: "",
            fullscreen: _vm.$vuetify.breakpoint.xsAndDown,
            "overlay-opacity": "0.2",
            transition: "scale-transition",
            width: "80%",
            "max-width": "600px",
            "min-width": "300px",
          },
          on: {
            "click:outside": _vm.cancelPopup,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.cancelPopup.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(_vm._s(_vm.$t("app.install")))]),
              _c("v-card-text", [
                _vm.device === "iPad"
                  ? _c("ul", [
                      _c(
                        "li",
                        [
                          _vm._v("Tap the "),
                          _c("v-icon", [_vm._v("mdi-export-variant")]),
                          _vm._v(
                            " button, which is usually found right beside the browser's address bar."
                          ),
                        ],
                        1
                      ),
                      _c("li", [
                        _vm._v(
                          'A new menu should open. Find the "Add to Home Screen" button and tap it.'
                        ),
                      ]),
                      _c("li", [_vm._v('Tap "Add".')]),
                    ])
                  : _vm._e(),
                _vm.device === "iPhone"
                  ? _c("ul", [
                      _c(
                        "li",
                        [
                          _vm._v("Tap the "),
                          _c("v-icon", [_vm._v("mdi-export-variant")]),
                          _vm._v(" button in the menu bar."),
                        ],
                        1
                      ),
                      _c("li", [
                        _vm._v(
                          'Scroll down to find the "Add to Home Screen" button and tap it.'
                        ),
                      ]),
                      _c("li", [_vm._v('On the next screen, tap "Add".')]),
                    ])
                  : _vm._e(),
              ]),
              _c("v-img", {
                attrs: { height: "250", src: _vm.addToHomeScreenImage },
              }),
              _c("v-divider", { staticClass: "mx-4" }),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.cancelPopup },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("ui.cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }