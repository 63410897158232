var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "progress", staticClass: "c-circular-progress" }, [
    _c(
      "div",
      {
        staticClass: "c-rings d-flex align-center justify-center pa-4",
        class: _vm.isRow ? "flex-row" : "flex-column",
        style: [_vm.backgroundStyle],
      },
      [
        _c(
          "v-progress-circular",
          {
            attrs: {
              color: _vm.colours[2],
              indeterminate: "",
              rotate: 0,
              size: _vm.ringSizes[2],
              width: 5,
            },
          },
          [
            _c(
              "v-progress-circular",
              {
                attrs: {
                  color: _vm.colours[1],
                  indeterminate: "",
                  rotate: 90,
                  size: _vm.ringSizes[1],
                  width: 5,
                },
              },
              [
                _c(
                  "v-progress-circular",
                  {
                    attrs: {
                      color: _vm.colours[0],
                      indeterminate: "",
                      rotate: 180,
                      size: _vm.ringSizes[0],
                      width: 5,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.logoSize > 16,
                            expression: "logoSize > 16",
                          },
                        ],
                      },
                      [
                        _c("v-img", {
                          staticClass: "c-logo",
                          attrs: {
                            src: _vm.orgLogo,
                            contain: "",
                            height: _vm.logoSize,
                            width: _vm.logoSize,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.message
          ? _c("div", { staticClass: "text-h4 my-4 px-4" }, [
              _vm._v(" " + _vm._s(_vm.message) + " "),
            ])
          : _vm._e(),
        _vm._t("append"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }