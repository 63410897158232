var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { rounded: "" } },
    [
      _c("v-subheader", { staticClass: "c-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("model.scenes").toUpperCase()) + " "),
      ]),
      _c(
        "v-list-item-group",
        {
          staticClass: "c-list-item-group px-2 pb-2",
          attrs: { "active-class": "c-list-item--active", mandatory: "" },
          on: {
            change: function ($event) {
              return _vm.$emit("sync", _vm.selectedSceneId)
            },
          },
          model: {
            value: _vm.selectedSceneId,
            callback: function ($$v) {
              _vm.selectedSceneId = $$v
            },
            expression: "selectedSceneId",
          },
        },
        [
          _vm._l(_vm.scenes, function (scene, index) {
            return [
              scene.header
                ? _c(
                    "v-subheader",
                    {
                      key: scene[_vm.locale].name,
                      staticClass: "c-subheader",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(scene[_vm.locale].name.toUpperCase()) + " "
                      ),
                    ]
                  )
                : scene.divider
                ? _c("v-divider", {
                    key: index,
                    staticClass: "c-divider mb-1 mt-3",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  })
                : _c(
                    "v-list-item",
                    {
                      key: scene.id,
                      attrs: { dense: "", value: scene.id },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$emit("select", scene.id)
                        },
                      },
                    },
                    [
                      scene.icon
                        ? _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(_vm._s(scene.icon))])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(scene[_vm.locale].name)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }