var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PortalWindow",
    {
      staticClass: "c-portal",
      attrs: { path: _vm.jitsiPath, title: _vm.$t("meeting.docTitle") },
      on: { closed: _vm.endMeeting, reset: _vm.endMeeting },
      model: {
        value: _vm.portal,
        callback: function ($$v) {
          _vm.portal = $$v
        },
        expression: "portal",
      },
    },
    [_c("div", [_vm._v(" " + _vm._s("hello") + " ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }