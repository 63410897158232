// import client from '@client'
import client from '@/services/real'

class PushService {
  constructor() {
    this.type = 'mongodb'
  }

  fetchKey = async () => {
    const key = await client.fetchKey()
    return key
  }

  saveSubscription = async (subscription) => {
    const result = await client.saveSubscription(subscription)
    return result
  }

  deleteSubscription = async (subscription) => {
    const result = await client.deleteSubscription(subscription)
    return result
  }
}

export default PushService
export const pushService = new PushService()
