var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "d-flex justify-left flex-wrap", attrs: { elevation: "0" } },
    [
      _c("v-img", {
        staticClass: "c-logo mr-4",
        attrs: {
          contain: "",
          "lazy-src": require("@/assets/images/avatars/avatar.png"),
          src: _vm.company.logo,
        },
      }),
      _c("v-card-title", { staticClass: "px-0 c-name" }, [
        _vm._v(" " + _vm._s(_vm.company.name) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }