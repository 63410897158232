var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-carousel" },
    [
      _c(
        "v-carousel",
        {
          staticClass: "c-slides",
          attrs: {
            continuous: false,
            height: "100%",
            "hide-delimiter-background": "",
            "show-arrows": !_vm.isReallyMobile,
            "show-arrows-on-hover": !_vm.isReallyMobile,
          },
          model: {
            value: _vm.currentSlide,
            callback: function ($$v) {
              _vm.currentSlide = $$v
            },
            expression: "currentSlide",
          },
        },
        _vm._l(_vm.slides, function (slide, i) {
          return _c(
            "v-carousel-item",
            { key: i, staticClass: "c-slide" },
            [
              _c(
                "v-sheet",
                {
                  attrs: {
                    color: _vm.backgroundColour,
                    height: "100%",
                    tile: "",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "c-slide-wrapper fill-height",
                      attrs: { align: "center", justify: "center" },
                      on: { dblclick: _vm.toggleThumbs },
                    },
                    [_vm._t("slide", null, { src: slide })],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-slide-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasThumbs,
              expression: "hasThumbs",
            },
          ],
          staticClass: "c-thumbs d-flex justify-center",
          class: { "c-thumbs--visible": _vm.showThumbs },
          attrs: { "active-class": "c-active-thumb", "show-arrows": "" },
          model: {
            value: _vm.currentSlide,
            callback: function ($$v) {
              _vm.currentSlide = $$v
            },
            expression: "currentSlide",
          },
        },
        _vm._l(_vm.thumbs, function (thumb, i) {
          return _c("v-slide-item", {
            key: i,
            staticClass: "d-flex",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ active: isSelected, toggle }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "c-thumb-wrapper mx-2",
                          class: isSelected
                            ? "c-active-thumb"
                            : "c-inactive-thumb",
                          on: { click: toggle },
                        },
                        [_vm._t("thumb", null, { src: thumb })],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }