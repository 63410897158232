var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-youtube-player" },
    [
      _c("youtube", {
        attrs: {
          width: "100%",
          height: "100%",
          "player-vars": _vm.playerVars,
          "video-id": _vm.videoId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }