var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.revealBack
    ? _c(
        "v-card",
        {
          staticClass:
            "c-card--back c-card--reveal g-skinny-scrollbars transition-fast-in-fast-out",
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                { staticClass: "align-self-start py-0" },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.$t("person.aboutMe")) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "align-self-start mt-3",
                  attrs: { icon: "", large: "", color: "teal accent-4" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("click:reveal", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("PersonBanner", { attrs: { person: _vm.person } }),
              _c("ClampedPortableText", {
                staticClass: "mt-4",
                attrs: { "localized-blocks": _vm.person.bio },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }