<template>
  <v-card class="c-card d-flex flex-column align-center justify-center pa-4">
    <!-- avatar -->
    <PersonAvatar
      clickable
      colour="secondary"
      :person="person"
      :size="avatarSize"
      @click="revealBack = true"
    />

    <!-- headline -->
    <v-card-text class="text-center py-2 pb-0">
      {{ personName }}
    </v-card-text>
    <v-card-text v-if="person.headline" class="text-center py-2 pb-0">
      {{ person.headline && person.headline[locale] }}
    </v-card-text>

    <v-spacer />

    <!-- actions -->
    <v-card-actions v-if="showContactInfo" class="py-5">
      <v-spacer />
      <v-btn icon @click=";`mailto:${person.email}`">
        <v-icon color="accent" size="60"> mdi-email </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon :href="person.linkedIn">
        <v-icon color="#0072b1" size="60"> mdi-linkedin </v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <!-- flip card -->
    <v-scroll-x-reverse-transition>
      <PersonCardBack v-model="revealBack" :person="person" />
    </v-scroll-x-reverse-transition>
  </v-card>
</template>

<script>
import PersonAvatar from '@/components/base/PersonAvatar'
import PersonCardBack from '@/components/person/PersonCardBack'

export default {
  name: 'PersonCard',

  components: {
    PersonAvatar,
    PersonCardBack
  },

  props: {
    person: {
      type: Object,
      required: true
    },

    size: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      id: this.person.id,
      revealBack: false
    }
  },

  computed: {
    avatarSize() {
      const sizes = {
        xs: 150,
        sm: 150,
        md: 150,
        lg: 200,
        xl: 250
      }
      return this.size || sizes[this.$vuetify.breakpoint.name] || 200
    },

    hasAvatar() {
      return !!this.person.avatar
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    personName() {
      return (
        this.person.displayName ||
        (this.person.honourific
          ? `${this.person.honourific} ${this.person.fullName} ${this.person.lastName}`
          : `${this.person.fullName} ${this.person.lastName}`)
      )
    },

    showContactInfo() {
      return this.$feature('contactInfo')
    }
  },

  methods: {
    emailPerson(_person) {},

    callPerson(_person) {},

    textPerson(_person) {},

    zoomPerson(_person) {}
  }
}
</script>

<style lang="css" scoped>
.c-avatar {
  cursor: pointer;
}

.c-card {
  margin: auto;
  height: 100%;
  min-width: 275px;
  max-width: min(400px, 100%);
  border-radius: 16px;
}
</style>
