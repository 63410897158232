var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-app-install" },
    [
      _vm.showInstallButton
        ? _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { fab: "", small: "" },
              on: { click: _vm.onInstall },
            },
            [
              _c("v-icon", { attrs: { color: "accent" } }, [
                _vm._v(" mdi-tray-arrow-down "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showInstallPopup
        ? _c("AppInstallPopup", {
            attrs: { device: _vm.iosDeviceType },
            on: {
              cancel: function ($event) {
                _vm.showInstallPopup = $event
              },
            },
            model: {
              value: _vm.showInstallPopup,
              callback: function ($$v) {
                _vm.showInstallPopup = $$v
              },
              expression: "showInstallPopup",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }