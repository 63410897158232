var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { rounded: "" } },
    [
      _c("v-subheader", { staticClass: "c-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("model.layers").toUpperCase()) + " "),
      ]),
      _c(
        "v-list-item-group",
        {
          staticClass: "c-list-item-group",
          attrs: { "active-class": "", multiple: "" },
          on: {
            change: function ($event) {
              return _vm.$emit("sync", _vm.selectedLayerIds)
            },
          },
          model: {
            value: _vm.selectedLayerIds,
            callback: function ($$v) {
              _vm.selectedLayerIds = $$v
            },
            expression: "selectedLayerIds",
          },
        },
        _vm._l(_vm.layers, function (layer) {
          return _c("v-list-item", {
            key: layer.id,
            attrs: { dense: "", value: layer.id },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("select", layer.id)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ active }) {
                    return [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-checkbox", {
                            attrs: { "input-value": active },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(layer.name))])],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }