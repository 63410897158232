<template>
  <div class="c-player-layout">
    <transition name="fade">
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PlayerLayout',

  components: {},

  data: function () {
    return {}
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.c-player-layout {
  min-height: 100vh;
  min-height: 100dvh;
}
</style>
