<template>
  <v-card class="c-content-tabs">
    <v-tabs
      v-model="currentTab"
      background-color="sheet"
      center-active
      fixed-tabs
      show-arrows
      :vertical="tabs.length > 2"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        class="c-tab"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.name"
      >
        <v-card flat>
          <v-card-text class="g-text-oversized">
            <RichText :blocks="tab.body" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import RichText from '@/components/base/RichText'

export default {
  name: 'ContentTabs',

  components: {
    RichText
  },

  props: {
    tabs: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      currentTab: 0
    }
  }
}
</script>

<style lang="css" scoped>
.c-tab {
  font-size: 1.2rem;
  letter-spacing: 0;
}
.v-tabs > :deep(.v-tabs-bar) {
  border-bottom: solid 1px var(--v-outline-base);
}
.v-tabs-bar .v-tab {
  border: solid 1px var(--v-outline-base);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.v-tabs-bar .v-tab.v-tab--active {
  border-color: var(--v-primary-base);
}
.v-tabs-bar .v-tab.v-tab--active::before {
  opacity: 0.15;
}
.v-tabs-bar .v-tab.v-tab--active:hover::before {
  opacity: 0.25;
}

.v-tabs-bar .v-tab:not(.v-tab--active) {
}
</style>
