<template>
  <v-system-bar
    v-if="enabled"
    app
    :color="message.type"
    dark
    height="50"
    width="100%"
    window
  >
    <v-alert
      v-model="enabled"
      class="c-sysalert"
      close="mdi-close-circle"
      dismissible
      elevation="0"
      :icon="icon"
      tile
      :type="message.type"
    >
      {{ message.text[$store.state.i18nStore.locale] }}
    </v-alert>
  </v-system-bar>
</template>

<script>
export default {
  name: 'AppSystemBar',

  components: {},

  props: {
    message: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },

  data: function () {
    return {
      enabled: false
    }
  },

  computed: {
    icon() {
      return this.message.icon || undefined
    }
  }
}
</script>

<style scoped>
.c-sysalert {
  font-size: 1rem;
  margin: 0;
  padding: 4px;
  font-style: italic;
  color: var(--v-primary--text);
  width: 100%;
}
</style>
