import { channelService } from '@/services/channelService'

export default {
  /* common actions */

  restoreState({ state, commit }, { sessionState }) {
    commit('syncState', { ...state, ...sessionState })
  },

  /* specific actions */

  updateActiveChannel({ commit }, { channel }) {
    commit('setActiveChannel', channel)
  },

  async resyncChannels({ commit, getters }) {
    const filter = getters.activePortal?.channelFilter
    const promise = channelService.fetchChannels({ filter })
    commit('setLoading', { loading: true, promise })
    const channels = await promise
    commit('setChannels', channels)
    commit('setLoading', { loading: false })
    return channels
  },

  async fetchChannels({ state, commit, getters }) {
    const filter = getters.activePortal?.channelFilter

    if (!state.fetched) {
      // needs a refresh from the CMS
      if (!state.loading) {
        // first one in turns on the lights
        try {
          console.warn(`[channelStore]: Fetching channels from CMS.`)
          const promise = channelService.fetchChannels({ filter })
          commit('setLoading', { loading: true, promise })
          const channels = await promise
          commit('setChannels', channels)
          commit('setFetched', true)
          return channels
        } catch (error) {
          console.warn(`[channelStore]: ...Fetch failed. Using cache.`, error)
          return state.channels || []
        } finally {
          commit('setLoading', { loading: false })
          commit('setPrimed', true)
        }
      } else {
        // a close second waits for the first to do all the work
        try {
          console.warn('[channelStore]: Use pre-existing promise.')
          const channels = await state.promise
          return channels
        } catch (error) {
          console.warn('[channelStore]: ...Pre-existing fetch failed.', error)
          return state.channels || []
        }
      }
    } else {
      // use cached values (no resync; already fetched)
      const count = state.channels.length
      console.warn(`[channelStore]: Fetching channels from cache (${count}).`)
      return state.channels
    }
  },

  async fetchChannel({ commit, getters }, { channelId = null, channelSlug = null }) {
    const channel = getters.getChannel({ channelId, channelSlug })

    if (channel) {
      return channel
    } else {
      try {
        const fetchedChannel = await channelService.fetchChannel({ channelId, channelSlug })
        commit('setChannel', fetchedChannel)
        return fetchedChannel
      } catch (error) {
        console.error('[channelStore]:', error)
        return null
      }
    }
  }
}
