export class MemoryStorage {
  constructor() {
    this.cache = {}
  }

  getItem(cacheKey, defaultValue) {
    return this.cache[cacheKey] || defaultValue
  }

  setItem(cacheKey, data) {
    this.cache[cacheKey] = data
  }

  removeItem(cacheKey) {
    this.cache[cacheKey] = undefined
  }

  clear() {
    Object.keys(this.cache).forEach((k) => delete this.cache[k])
  }

  get length() {
    return Object.keys(this.cache).length
  }

  key(index) {
    if (arguments.length === 0) {
      throw new TypeError('[MemoryStorage]: "key()" requires at least one argument.')
    }
    return this.cache[Object.keys(this.cache)[index]]
  }
}

export const memoryStorage = new MemoryStorage()
