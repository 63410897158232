var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-unity-app" }, [
    _vm.showLoadingScreen
      ? _c(
          "div",
          { staticClass: "c-loading-screen" },
          [_c("UnityAppLoadingScreen", { attrs: { progress: _vm.progress } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "resize",
            rawName: "v-resize",
            value: _vm.resizeCanvas,
            expression: "resizeCanvas",
          },
        ],
        staticClass: "c-container",
        attrs: { id: _vm.containerId },
      },
      [
        _c("canvas", {
          ref: "unityCanvas",
          staticClass: "c-canvas",
          attrs: { id: "unity-canvas" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }