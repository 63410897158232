<template>
  <RichText :blocks="copy"/>
</template>

<script>
import RichText from '@/components/base/RichText'

export default {
  name: 'AdCopy',

  components: {
    RichText
  },

  props: {
    copy: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {}
  }
}
</script>

<style lang="css" scoped></style>
