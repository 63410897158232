var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSwitch
    ? _c(
        "div",
        { staticClass: "d-flex justify-end align-center" },
        [
          _c("v-icon", { attrs: { color: "amber darken-4", large: "" } }, [
            _vm._v(" mdi-white-balance-sunny "),
          ]),
          _c("v-switch", {
            staticClass: "ml-4",
            attrs: {
              color: "accent",
              "input-value": _vm.currentMode,
              inset: "",
            },
          }),
          _c(
            "v-icon",
            {
              attrs: {
                color: _vm.isDark ? "grey lighten-2" : "grey darken-2",
                large: "",
              },
            },
            [_vm._v(" mdi-moon-waxing-crescent ")]
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                plain: !_vm.activator,
                icon: !_vm.activator,
                small: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.activate.apply(null, arguments)
                },
              },
            },
            [
              (_vm.reverse ? !_vm.isDark : _vm.isDark)
                ? _c(
                    "v-icon",
                    { attrs: { color: "amber darken-4", large: "" } },
                    [_vm._v(" mdi-white-balance-sunny ")]
                  )
                : _c("v-icon", { attrs: { color: "white", large: "" } }, [
                    _vm._v(" mdi-moon-waxing-crescent "),
                  ]),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }