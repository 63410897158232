var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "WidgetWrapper",
    {
      staticClass: "c-people-widget",
      attrs: { closeable: false, title: _vm.title },
    },
    _vm._l(_vm.people, function (person) {
      return _c(
        "div",
        { key: person.id, staticClass: "c-person d-flex flex-wrap mb-2" },
        [
          _vm.useBanner
            ? _c("PersonBanner", {
                staticClass: "c-person-banner",
                attrs: { person: person, size: 72 },
              })
            : _c("PersonCard", {
                staticClass: "c-person-card",
                attrs: { person: person },
              }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }