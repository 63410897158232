var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": "",
        "close-on-content-click": "",
        "max-height": "80%",
        "offset-y": "",
        top: "",
        transition: "slide-y-reverse-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("model.controls.scenes"),
                          expression: "$t('model.controls.scenes')",
                        },
                      ],
                      staticClass: "c-btn",
                      attrs: {
                        "active-class": "c-btn--active",
                        fab: "",
                        small: "",
                        text: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isOpen ? "mdi-close" : "mdi-sitemap") +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("SceneList", {
        attrs: { scenes: _vm.scenes },
        on: {
          select: function ($event) {
            return _vm.$emit("select", $event)
          },
          sync: function ($event) {
            return _vm.syncScene($event)
          },
        },
        model: {
          value: _vm.selectedSceneId,
          callback: function ($$v) {
            _vm.selectedSceneId = $$v
          },
          expression: "selectedSceneId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }