<template>
  <v-dialog
    v-model="showPopup"
    :hide-overlay="!frame"
    max-width="300"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="c-link ma-2"
        v-bind="attrs"
        color="primary"
        :outlined="!isMobile"
        v-on="on"
        @click="show()"
      >
        {{ isMobile ? $t('quiz.action.view') : label }}
      </v-btn>
    </template>
    <iframe v-if="frame === true" :src="src"></iframe>
  </v-dialog>
</template>

<script>
export default {
  name: 'PopupWindow',

  components: {},

  props: {
    frame: {
      type: Boolean,
      required: false,
      default: true
    },

    label: {
      type: String,
      required: true
    },

    src: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      showPopup: false
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },

  methods: {
    show() {
      const ratio = window.screen.availWidth / window.screen.availHeight
      const width = Math.min(500, window.screen.availWidth)
      const height = width / ratio
      this.frame
        ? (this.showPopup = true)
        : window.open(this.src, '_blank', `width=${width}, height=${height}`)
    }
  }
}
</script>

<style lang="css" scoped>
.c-link {
  height: unset !important;
  min-height: 36px;
}
.c-link :deep(.v-btn__content) {
  white-space: pre-wrap;
  width: 100%;
}
</style>
