var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "WidgetWrapper",
    { staticClass: "c-quiz-widget", attrs: { title: _vm.$t("content.quiz") } },
    [
      _vm.quizContent
        ? _c("QuizCard", { attrs: { content: _vm.quizContent } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }