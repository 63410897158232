var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-model-player" },
    [
      _vm.isUnity
        ? _c("UnityApp", {
            ref: "unityApp",
            attrs: { poster: _vm.poster, src: _vm.src },
            on: {
              loaded: function ($event) {
                _vm.isLoaded = true
              },
              error: function ($event) {
                return _vm.$emit("error:handled", $event)
              },
            },
          })
        : _vm._e(),
      _vm.isGLB
        ? _c("GLBViewer", {
            ref: "viewerApp",
            attrs: {
              "cache-size": _vm.scenes.length,
              options: _vm.options,
              poster: _vm.poster,
              src: _vm.src,
            },
            on: {
              loaded: function ($event) {
                _vm.isLoaded = true
              },
              error: function ($event) {
                return _vm.$emit("error:loading", $event)
              },
            },
          })
        : _vm._e(),
      _c(
        "SceneInfo",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInfo,
              expression: "showInfo",
            },
          ],
          staticClass: "c-info",
          attrs: { title: _vm.sceneInfo.title },
          model: {
            value: _vm.showInfo,
            callback: function ($$v) {
              _vm.showInfo = $$v
            },
            expression: "showInfo",
          },
        },
        [_vm._v(" " + _vm._s(_vm.sceneInfo.description) + " ")]
      ),
      !_vm.hideControls && _vm.isLoaded
        ? _c(
            "v-footer",
            {
              staticClass: "c-footer d-flex mb-1",
              attrs: {
                absolute: "",
                color: "rgba(0,0,0,0)",
                inset: "",
                padless: "",
                tile: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-none d-sm-flex ml-1" },
                [
                  _c("v-img", {
                    staticClass: "c-invisible d-inline-block",
                    attrs: {
                      contain: "",
                      src: _vm.orgLogo,
                      "max-height": "48px",
                      "max-width": "48px",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column align-center justify-center",
                },
                [
                  _vm.isLoaded
                    ? _c("ModelControls", {
                        attrs: {
                          help: _vm.isGLB,
                          rerender: _vm.rerender,
                          scenes: _vm.scenes,
                          "initial-scene-id": _vm.initialSceneId,
                          "zero-zoom": _vm.isGLB,
                        },
                        on: {
                          info: function ($event) {
                            _vm.sceneInfo = $event
                          },
                          message: function ($event) {
                            return _vm.sendMessage($event)
                          },
                        },
                        model: {
                          value: _vm.showInfo,
                          callback: function ($$v) {
                            _vm.showInfo = $$v
                          },
                          expression: "showInfo",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "d-none d-sm-flex mr-1" },
                [
                  _c("v-img", {
                    staticClass: "d-inline-block",
                    attrs: {
                      contain: "",
                      src: _vm.orgLogo,
                      "max-height": "48px",
                      "max-width": "48px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }