export default {
  // Flag Icons: https://www.countries-ofthe-world.com/flags-of-the-world.html
  // Note: icon path is relative to @/assets

  // English locales
  en: {
    code: 'en',
    lang: 'en',
    avatar: 'En',
    name: 'English',
    icon: 'UK.png'
  },

  // French locales
  fr: {
    code: 'fr',
    lang: 'fr',
    avatar: 'Fr',
    name: 'Français',
    icon: 'France.png'
  }
}
