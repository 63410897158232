var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.revealBack
    ? _c(
        "v-card",
        {
          staticClass:
            "c-card--back c-card--reveal g-skinny-scrollbars transition-fast-in-fast-out",
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                { staticClass: "align-self-start py-0" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "px-0 text-h5 text-left" },
                    [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
                  ),
                  _c(
                    "v-card-subtitle",
                    { staticClass: "px-0 pb-0 text-h6 text-left" },
                    [_c("i", [_vm._v(_vm._s(_vm.item.subtitle))])]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "align-self-start mt-3",
                  attrs: { icon: "", large: "", color: "accent" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click:reveal", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "text-left" },
            [
              _c("ClampedPortableText", {
                attrs: { blocks: _vm.summaryBlocks },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _vm.hasParent
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", large: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("view:parent")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("content.action.viewParent")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.hasAuthors
            ? _c(
                "v-card-text",
                [
                  _c("v-subheader", { staticClass: "pl-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("content.authors").toUpperCase()) +
                        " / " +
                        _vm._s(_vm.$t("content.advisors").toUpperCase()) +
                        " (" +
                        _vm._s(_vm.item.authors.length) +
                        ") "
                    ),
                  ]),
                  _c(
                    "v-list",
                    { staticClass: "pl-0" },
                    _vm._l(_vm.item.authors, function (author) {
                      return _c(
                        "v-list-item",
                        {
                          key: author.lastName,
                          staticClass: "mb-3 pl-0 text-left",
                        },
                        [
                          author.avatar
                            ? _c("v-avatar", { staticClass: "mr-1" }, [
                                _c("img", {
                                  attrs: {
                                    "lazy-src": require("@/assets/images/avatars/avatar.png"),
                                    src: author.avatar,
                                  },
                                }),
                              ])
                            : _c(
                                "v-avatar",
                                {
                                  staticClass: "mr-1",
                                  attrs: { color: "purple" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "white--text text-h5" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getAuthorInitials(author)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatAuthorName(author, true)) +
                              " "
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasSponsor
            ? _c(
                "v-card-text",
                [
                  _c("v-subheader", { staticClass: "pl-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("content.sponsor").toUpperCase()) +
                        " "
                    ),
                  ]),
                  _c("CompanyAvatar", { attrs: { sponsor: _vm.item.sponsor } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }