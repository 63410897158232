import Vue from 'vue'
import Vuex from 'vuex'
import { commentService } from '@/services/commentService'

Vue.use(Vuex)

const initialState = {
  // persisted state
  comments: {},
  // storage
  storage: 'indexDB'
}

// recursively traverse comment tree and "yield" every comment
const traverse = function* (comment) {
  yield comment
  if (comment.replies?.length > 0) {
    for (const reply of comment.replies) {
      yield* traverse(reply)
    }
  }
}

export const findParentComment = (comment, parentId) => {
  if (comment.id === parentId) {
    return comment
  }

  for (const comment of comment.replies) {
    const parentComment = findParentComment(comment, parentId)

    if (parentComment) {
      return parentComment
    }

    return null
  }
}

const makeRoot = function ({ targetId, comments }) {
  return {
    id: '0',
    parentId: '0',
    targetId: targetId,
    replies: comments
  }
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    getComments(state) {
      return (targetId) => state.comments[targetId]?.replies || []
    },

    getCommentsByParent(state) {
      return ({ targetId, parentId }) => {
        for (const comment of traverse(state.comments[targetId])) {
          if (comment.id === parentId) {
            return comment.replies || []
          }
        }
        return []
      }
    },

    getComment(state) {
      return ({ targetId, commentId }) => {
        for (const comment of traverse(state.comments[targetId])) {
          if (comment.id === commentId) {
            return comment
          }
        }
        return null
      }
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.comments = { ...newState.comments }
    },

    /* specific mutations */

    setRootComment(state, { targetId, comments }) {
      targetId in state.comments
        ? (state.comments[targetId] = makeRoot({ targetId, comments })) // replace (reactively)
        : (state.comments = {
            ...state.comments,
            [targetId]: makeRoot({ targetId, comments })
          }) // append (reactively)
    },

    insertOrUpdate(state, newComment) {
      const commentId = newComment.id
      const parentId = newComment.parentId
      const targetId = newComment.targetId

      // check if *any* comments exist for the target
      if (targetId in state.comments) {
        // search for the parent comment to modify its replies
        for (const comment of traverse(state.comments[targetId])) {
          if (comment.id === parentId) {
            const index = comment.replies.findIndex((c) => c.id === commentId)
            index === -1
              ? comment.replies.unshift(newComment) // insert at beginning
              : comment.replies.splice(index, 1, {
                  ...comment.replies[index],
                  ...newComment // override existing comment
                })
            break
          }
        }
      } else {
        // first comment - add new targetId property (reactively)
        state.comments = {
          ...state.comments,
          [targetId]: makeRoot({ targetId, comments: [newComment] })
        }
      }
    },

    delete(state, deletedComment) {
      const commentId = deletedComment.id
      const parentId = deletedComment.parentId
      const targetId = deletedComment.targetId

      if (targetId in state.comments) {
        // search for the comment
        for (const comment of traverse(state.comments[targetId])) {
          if (comment.id === parentId) {
            const filtered = comment.replies.filter((c) => c.id !== commentId)
            if (filtered.length !== comment.replies.length) {
              comment.replies = filtered
            }
            break
          }
        }
      }
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* fetch actions */

    async fetchComments({ state, commit }, { targetId, refresh = true }) {
      try {
        if (refresh) {
          const comments = await commentService.fetchComments(targetId)
          commit('setRootComment', { targetId, comments })
          return comments
        } else {
          return state.comments[targetId]?.replies || []
        }
      } catch (error) {
        console.error(`[commentStore]: Fetch failed for target '${targetId}'.`, error)
        return state.comments[targetId]?.replies || []
      }
    },

    /* specific actions */
    async addComment({ commit }, { comment }) {
      try {
        const addedComment = await commentService.addComment(comment)
        commit('insertOrUpdate', addedComment)
        return addedComment
      } catch (error) {
        console.error('[commentStore]: Error adding comment.', error)
      }
    },

    async modifyComment({ commit }, { comment }) {
      try {
        const modifiedComment = await commentService.modifyComment(comment)
        commit('insertOrUpdate', modifiedComment)
        return modifiedComment
      } catch (error) {
        console.error('[commentStore]: Error modifying comment.', error)
      }
    },

    async removeComment({ commit }, { comment }) {
      try {
        const modifiedComment = await commentService.removeComment(comment)
        modifiedComment.id ? commit('insertOrUpdate', modifiedComment) : commit('delete', comment)
        return modifiedComment
      } catch (error) {
        console.error('[commentStore]: Error deleting comment.', error)
      }
    }
  }
}
