import tenants from './tenants.json'
import { fakeFetch } from '@/services/mock/fakeFetch'

export const fetchTenants = () => {
  return fakeFetch(tenants, 500) // wait 500ms before returning
}

export const fetchTenant = (tenantKey) => {
  const tenant = tenants.find((tenant) => tenant.key === tenantKey)
  if (!tenant) throw new Error(`Cannot find tenant with key=${tenantKey}.`)
  return fakeFetch(tenant, 500) // wait 500ms before returning
}
