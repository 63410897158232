// import client from '@client'
import client from '@/services/real'

class QuizService {
  constructor() {
    this.type = ''
  }

  fetchQuizzes = async () => {
    const quizzes = await client.fetchQuizzes()
    return quizzes
  }

  fetchQuizBySlug = async (quizSlug) => {
    const quiz = await client.fetchQuizBySlug(quizSlug)
    return quiz
  }
}

export default QuizService
export const quizService = new QuizService()
