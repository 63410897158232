var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PortableText", {
    attrs: {
      "class-name": "c-rich-text",
      blocks: _vm.blocks,
      serializers: _vm.serializers,
      "project-id": _vm.blockOptions.projectId,
      dataset: _vm.blockOptions.dataset,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }