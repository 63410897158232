<template>
  <v-img
    v-if="src"
    :key="src"
    ref="img"
    class="c-ad-image c-image"
    :class="{ 'c-cta': hasCTA }"
    :aspect-ratio="optimize ? aspectRatio : undefined"
    contain
    :position="optimize ? 'top' : 'center center'"
    :src="src"
    width="100%"
    @click="$emit('select', ad)"
    @load="$emit('load', $refs.img.$el)"
  >
    <v-btn
      v-if="closeable"
      class="c-btn-close"
      color="rgba(0, 0, 0, 0.2)"
      fab
      @click.stop="$emit('close')"
    >
      <v-icon color="white" small> mdi-close </v-icon>
    </v-btn>
  </v-img>
</template>

<script>
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'AdImage',

  components: {},

  mixins: [mobileMixin],

  props: {
    ad: {
      type: Object,
      required: true
    },

    closeable: {
      type: Boolean,
      required: false,
      default: true
    },

    optimize: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    aspectRatio() {
      return this.isReallyMobile || this.isViewportSmall
        ? this.orientation === 'portrait'
          ? 4 / 1
          : 6 / 1
        : 10 / 1
    },

    hasCTA() {
      return this.ad.adItem?.cta?.href
    },

    isMobile() {
      return this.mobileMixin_isMobile
    },

    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    isViewportSmall() {
      return this.mobileMixin_isViewportSmall
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    orientation() {
      return this.mobileMixin_viewportOrientation
    },

    mobileLandscapeImage() {
      return this.ad.adItem?.mobileLandscapeImage || ''
    },

    mobilePortraitImage() {
      return this.ad.adItem?.mobilePortraitImage || ''
    },

    mainImage() {
      return this.ad.adItem?.image
    },

    src() {
      const url =
        this.optimize && (this.isReallyMobile || this.isViewportSmall)
          ? this.orientation === 'portrait'
            ? this.mobilePortraitImage
            : this.mobileLandscapeImage
          : this.mainImage

      return this.ad.adItem?.asset || url || this.mainImage || ''
    }
  }
}
</script>

<style lang="css" scoped>
.c-btn-close {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  width: 20px;
}
.c-btn-close i:hover {
  transform: scale(1.25);
}

.c-cta {
  cursor: pointer;
}

.c-image {
  background-color: var(--v-background-base);
  width: 100%;
  height: auto;
}
</style>
