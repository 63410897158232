var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    {
      staticClass: "c-footer",
      attrs: { app: "", fixed: "", height: "48", inset: "" },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center pa-0" },
        [
          _c(
            "v-row",
            {
              staticClass: "mx-auto my-0",
              attrs: { align: "center", justify: "space-around" },
            },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-auto",
                      attrs: { text: "", rounded: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("footer.contact")) + " ")]
                  ),
                ],
                1
              ),
              _c("v-col", { staticClass: "pa-0" }, [
                _c("p", { staticClass: "ma-auto d-inline" }, [
                  _vm._v(" ©" + _vm._s(new Date().getFullYear()) + "  "),
                  _c("strong", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.orgLink,
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.orgName) + " ")]
                    ),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-auto",
                      attrs: { text: "", rounded: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("footer.problem")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }