import { render, staticRenderFns } from "./TagList.vue?vue&type=template&id=504cc50a"
import script from "./TagList.vue?vue&type=script&lang=js"
export * from "./TagList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('504cc50a')) {
      api.createRecord('504cc50a', component.options)
    } else {
      api.reload('504cc50a', component.options)
    }
    module.hot.accept("./TagList.vue?vue&type=template&id=504cc50a", function () {
      api.rerender('504cc50a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/TagList.vue"
export default component.exports