<template>
  <v-dialog
    v-model="showPopup"
    content-class="c-popup"
    overlay-opacity="0.75"
    transition="dialog-top-transition"
    :width="width"
    @keydown.esc="closePopup()"
  >
    <slot name="ad" :ad="ad" :index="0"/>
  </v-dialog>
</template>

<script>
export default {
  name: 'AdPopup',

  components: {},

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    ad: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: function () {
    return {
      showPopup: true
    }
  },

  computed: {
    width() {
      return '300px'
    }
  },

  methods: {
    closePopup() {
      this.showPopup = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.c-popup {
  background: var(--v-background-base);
  height: 75%;
  overscroll-behavior: contain;
  overflow-y: auto; /* enables overscroll setting */
}
</style>
