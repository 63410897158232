var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      ref: "grid",
      staticClass: "c-grid-wrapper g-skinny-scrollbars",
    },
    [
      _c(
        "v-container",
        { staticClass: "c-grid-container pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "c-grid g-hide-scrollbar ma-0",
              attrs: { align: "stretch", justify: "start" },
            },
            _vm._l(_vm.itemList, function (item) {
              return _c(
                "v-col",
                {
                  key: item.id,
                  ref: "card",
                  refInFor: true,
                  attrs: { cols: _vm.cols },
                },
                [_vm._t("card", null, { item: item })],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasMoreItems,
              expression: "hasMoreItems",
            },
          ],
          ref: "more",
          staticClass: "c-progress text-center my-4",
        },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "", size: 50, width: 8 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }