<template>
  <v-card class="c-source-card pt-4 px-4" elevation="4">
    <v-card-title class="text-h6 text-left pa-0 pb-2">
      {{ $t('quiz.source') }}
    </v-card-title>

    <v-card-text>
      <ReferenceCard :reference="source"/>
    </v-card-text>
  </v-card>
</template>

<script>
import ReferenceCard from '@/components/base/ReferenceCard'
import viewMixin from '@/mixins/viewMixin'

export default {
  name: 'QuizSourceCard',

  components: {
    ReferenceCard
  },

  mixins: [viewMixin],

  props: {
    source: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      thumbnailAspectRatio: 4 / 3
    }
  },

  methods: {
    viewSource(source) {
      this.viewMixin_viewReference(source)
    }
  }
}
</script>

<style lang="css" scoped>
.c-source-card {
  width: 100%;
}
</style>
