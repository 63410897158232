import TOOLNAMES from './names'
import AbstractHandler from './abstractHandler'

export default class FreehandHandler extends AbstractHandler {
  constructor() {
    super()
    this.name = TOOLNAMES.freehand
  }

  // manage context

  getContextAttributes() {
    const attributes = super.getContextAttributes()
    return {
      ...attributes,
      lineCap: 'round'
    }
  }

  setContextAttributes(options) {
    super.setContextAttributes(options)
    for (const ctx of [this.mainContext, this.tempContext]) {
      ctx.lineCap = 'round'
    }
  }

  // manage drawing

  mousedown(e) {
    super.mousedown(e)
  }

  mousemove(e) {
    const { newX, newY } = this.getCoordinates(e)

    this.draw(this.mainContext, this.x, this.y, newX, newY)

    this.segmentLog.push([this.x, this.y, newX, newY])

    this.x = newX
    this.y = newY
  }

  mouseup(e) {
    const { newX, newY } = this.getCoordinates(e)

    this.draw(this.mainContext, this.x, this.y, newX, newY)

    this.segmentLog.push([this.x, this.y, newX, newY])
    const segments = this.segmentLog
    this.segmentLog = []

    this.x = newX
    this.y = newY

    return {
      tool: this.name,
      attributes: this.getContextAttributes(),
      segments: segments
    }
  }

  draw(ctx, x1, y1, x2, y2) {
    this.isEraseMode
      ? (ctx.globalCompositeOperation = 'destination-out')
      : (ctx.globalCompositeOperation = 'source-over')
    this.renderSegment(ctx, x1, y1, x2, y2)
  }

  renderSegment(ctx, x1, y1, x2, y2) {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
    ctx.closePath()
  }

  redraw({ ctx, segments }) {
    ctx.beginPath()
    for (const segment of segments) {
      ctx.moveTo(segment[0], segment[1])
      ctx.lineTo(segment[2], segment[3])
      ctx.stroke()
    }
    ctx.closePath()
  }
}

export const freehandHandler = new FreehandHandler()
