const initialState = {
  activeChannel: null,
  channels: [],
  // transient state
  fetched: false,
  loading: undefined,
  promise: undefined,
  primed: false
}

export default () => initialState
