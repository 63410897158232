import tools from '@/services/tools'

export const colours = [
  { name: 'black', colour: 'black', value: 'black' },
  { name: 'white', colour: 'white', value: 'white' },
  { name: 'blue', colour: 'blue', value: 'blue' },
  { name: 'green', colour: 'green', value: 'green' },
  { name: 'orange', colour: 'orange', value: 'orange' },
  { name: 'red', colour: 'red', value: 'red' },
  { name: 'yellow', colour: 'yellow', value: 'yellow' },
  { name: 'purple', colour: 'purple', value: 'purple' }
]

export const fonts = [
  { name: 'regular', icon: 'mdi-format-text', value: '32px Arial' },
  { name: 'bold', icon: 'mdi-format-bold', value: 'bold 32px Arial' },
  { name: 'italic', icon: 'mdi-format-italic', value: 'italic 32px Arial' }
]

export const shapes = [
  { name: 'freehand', icon: 'mdi-gesture', value: tools.freehand.name },
  { name: 'line', icon: 'mdi-chart-line-variant', value: tools.line.name },
  { name: 'circle', icon: 'mdi-circle-outline', value: tools.circle.name },
  { name: 'rectangle', icon: 'mdi-rectangle-outline', value: tools.rectangle.name }
]

export const widths = [
  { name: 'pen', icon: 'mdi-fountain-pen', value: '3' },
  { name: 'sharpie', icon: 'mdi-pen', value: '5' },
  { name: 'crayon', icon: 'mdi-lead-pencil', value: '12' },
  { name: 'hilighter', icon: 'mdi-marker', value: '18' },
  { name: 'brush', icon: 'mdi-brush-variant', value: '28' }
]

export const defaultControls = {
  colour: {
    dark: colours[1],
    light: colours[0]
  },
  font: fonts[0],
  shape: shapes[0],
  width: widths[1]
}

export const defaultTool = defaultControls.shape.value

export const defaultOptions = {
  colour: {
    dark: defaultControls.colour.dark.value,
    light: defaultControls.colour.light.value
  },
  font: defaultControls.font.value,
  shape: defaultControls.shape.value,
  width: defaultControls.width.value
}

export const defaultContext = {
  points: [],
  fillStyle: 'rgba(0,0,0,0)',
  textarea: find('code-text'),
  globalAlpha: 1,
  globalCompositeOperation: 'source-over',
  lineCap: 'round',
  font: '15px "Arial"',
  lineJoin: 'round'
}
