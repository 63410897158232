var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-card mx-auto" },
    [
      _c("ClientFavourite", {
        attrs: { favourite: _vm.favourite },
        on: {
          "add:favourite": function ($event) {
            return _vm.addToFavourites(_vm.company.id)
          },
          "remove:favourite": function ($event) {
            return _vm.removeFromFavourites(_vm.company.id)
          },
        },
      }),
      _c(
        "v-card-title",
        [
          _c(
            "v-avatar",
            {
              staticClass: "ma-auto",
              attrs: { color: "indigo", fab: "", size: _vm.logoSize },
            },
            [
              _vm.company.logo
                ? _c("v-img", { attrs: { src: _vm.company.logo } })
                : _c("v-icon", [_vm._v(" mdi-domain ")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c("v-icon", { attrs: { color: "primary", large: "" } }, [
                    _vm._v(" mdi-domain "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "text-h4" }, [
                    _vm._v(" " + _vm._s(_vm.company.name) + " "),
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(_vm.company.industry) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.callCompany(_vm.company)
                        },
                      },
                    },
                    [_vm._v(" mdi-phone ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [_vm._v(" " + _vm._s(_vm.company.phone) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c("v-icon", { attrs: { color: "primary", "x-large": "" } }, [
                    _vm._v(" mdi-web "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.company.webSite,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.company.webSite) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { attrs: { "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "accent", "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.showMap(_vm.company)
                        },
                      },
                    },
                    [_vm._v(" mdi-map-marker ")]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [_vm._v(" " + _vm._s(_vm.company.address) + " ")]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.company.city) +
                          " " +
                          _vm._s(_vm.company.province) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    {
                      staticClass:
                        "text-sm-body-1 text-sm-subtitle-2 text-md-subtitle-1 text-lg-h6 text-xl-h5",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.company.country) +
                          " " +
                          _vm._s(_vm.company.postalCode) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }