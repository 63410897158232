<template>
  <v-bottom-navigation
    v-model="selectedItem"
    class="c-bottom-navigation g-hide-scrollbar pt-1"
    :app="false"
    :background-color="isDark ? 'background' : '#f0f0f0'"
    color="accent"
    fixed
    grow
    height="76"
    hide-on-scroll
    :input-value="show"
  >
    <v-btn
      v-for="menuItem in menuItems"
      :key="menuItem.key"
      class="c-menu-item px-1"
      active-class="c-selected-item"
      :to="to(menuItem)"
      :value="menuItem.key"
      @click.stop="selectItem(menuItem)"
    >
      <v-icon x-large>
        {{ menuItem.icon }}
      </v-icon>
      <span>{{
        menuItem.translated
          ? menuItem.title[locale] || menuItem.title['en']
          : $t(`${menuItem.title}`)
      }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
/*
 * Note:
 * The "app" attribute adds padding to v-main, however,
 * this causes another scrollbar to appear (at <html>)
 */
export default {
  name: 'AppBottomBar',

  components: {},

  props: {
    show: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      selectedItem: null
    }
  },

  computed: {
    menuItems() {
      return this.$store.state.menuStore.menuItems
    },

    isDark() {
      return this.$store.state.themeStore.isDark
    },

    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  created: function () {
    this.selectedItem =
      this.menuItems
        .filter((menuItem) => menuItem.key === this.$route.name)
        .map((menuItem) => menuItem.key)?.[0] || 'home'
  },

  methods: {
    selectItem(menuItem) {
      this.selectedItem = menuItem.key
    },

    to(menuItem) {
      return menuItem.to ? menuItem.to : { name: menuItem.route }
    }
  }
}
</script>

<style lang="css" scoped>
/* menu bar */
.c-bottom-navigation {
  background-color: var(--v-background-base);
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.c-bottom-navigation .c-menu-item:first-child {
  margin-left: auto;
}
.c-bottom-navigation .c-menu-item:last-child {
  margin-right: auto;
}

/* menu items */
.c-menu-item {
  justify-content: flex-start;
}
.c-menu-item.c-selected-item {
  color: var(--v-accent-base) !important;
}
.c-menu-item :deep(.v-btn__content) {
  flex-direction: column !important; /* default is column-reverse */
}
.c-menu-item .c-btn--active {
  color: var(--v-accent-base);
}
</style>
