import colors from 'vuetify/lib/util/colors'
import { version } from '@@/package.json'

// eslint-disable-next-line no-unused-vars
const parse = (value, fallback) => {
  if (typeof value === 'undefined') {
    return fallback
  }

  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value)
    case 'number':
      return JSON.parse(value)
    default:
      return value
  }
}

export const avatarBaseURL = 'https://seccdn.libravatar.org/avatar'
export const appName = 'Lumenii'
export const appVersion = `V${version} (2024-09-30)`
export const appEnv = process.env.NODE_ENV || 'development'
export const appRoot = process.env.BASE_URL || '/'
export const libraryCollectionKey = 'library'
export const playerBarHeight = 48
export const trackingId = 'GTM-P2RNH3S'

export default {
  // branding
  appLogo: 'lumenii-logo.png',
  appName: appName,

  // configuration
  appVersion: appVersion,
  appRoot: '/',
  appEnv: appEnv,

  // window history
  historyStart: 0,

  // third party services
  avatarBaseURL: avatarBaseURL,

  // i18n
  defaultLocale: 'en',

  // dark mode
  defaultIsDark: true,

  // default theme
  theme: {
    themes: {
      light: {
        // branding colours
        primary: colors.blueGrey.lighten2,
        secondary: colors.grey.lighten1,
        tertiary: colors.lightBlue.lighten2,
        accent: colors.cyan.accent3,
        // digital colours
        anchor: colors.blue.darken3,
        background: colors.grey.lighten4,
        fab: colors.grey.darken2,
        icon: colors.grey.darken3,
        outline: colors.grey.lighten2,
        sheet: colors.grey.lighten2,
        text: colors.shades.black,
        // notification colours
        info: colors.blue.darken4,
        success: colors.green.darken4,
        warning: colors.amber.darken4,
        error: colors.red.darken4
      },
      dark: {
        // branding colours
        primary: colors.blueGrey.darken1,
        secondary: colors.grey.darken1,
        tertiary: colors.lightBlue.darken3,
        accent: colors.cyan.accent3,
        // digital colours
        anchor: colors.blue.lighten3,
        background: colors.blueGrey.darken4,
        fab: colors.grey.lighten2,
        icon: colors.shades.white,
        outline: colors.grey.darken2,
        sheet: colors.blueGrey.darken3,
        text: colors.shades.white,
        // notification colours
        info: colors.blue.darken4,
        success: colors.green.darken4,
        warning: colors.amber.darken4,
        error: colors.red.darken4
      }
    }
  },

  // content
  libraryCollectionKey: libraryCollectionKey,

  // calendar
  defaultCalendarOverlapMode: 'column',

  // tracking
  trackingId: trackingId,

  // end of object
  endOfConfig: ''
}
