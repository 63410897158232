<template>
  <v-card v-if="revealBack" class="c-card--back c-card--reveal g-skinny-scrollbars transition-fast-in-fast-out">
    <!-- header -->
    <v-list-item>
      <!-- title -->
      <v-list-item-content class="align-self-start py-0">
        <v-card-title>
          {{ $t('person.aboutMe') }}
        </v-card-title>
      </v-list-item-content>
      <!-- close button -->
      <v-btn
        class="align-self-start mt-3"
        icon
        large
        color="teal accent-4"
        @click.stop="$emit('click:reveal', false)"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-list-item>

    <v-card-text>
      <PersonBanner :person="person" />
      <ClampedPortableText class="mt-4" :localized-blocks="person.bio" />
    </v-card-text>
  </v-card>
</template>

<script>
import ClampedPortableText from '@/components/base/ClampedPortableText'
import PersonBanner from '@/components/person/PersonBanner'

export default {
  name: 'PersonCardBack',

  components: {
    ClampedPortableText,
    PersonBanner
  },

  model: {
    prop: 'reveal',
    event: 'click:reveal'
  },

  props: {
    reveal: {
      type: Boolean,
      required: true
    },

    person: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      revealBack: this.reveal
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    },

    defaultLocale() {
      return this.$store.state.i18nStore.defaultLocale
    }
  },

  watch: {
    reveal: {
      immediate: false, // initial value captured via data()
      handler: function (newVal, _oldVal) {
        this.revealBack = newVal
      }
    }
  },

  methods: {
    formatPersonName(person, isFull = true) {
      const suffixes = isFull ? person.suffixes?.join(', ') : ''
      const fullName = [person.honourific, person.firstName, person.lastName, suffixes].join(' ')
      return fullName
    },

    getPersonInitials(person) {
      return person.firstName[0] + person.lastName[0]
    }
  }
}
</script>

<style lang="css" scoped>
.c-card--back {
  border-radius: 16px;
  height: 100%;
  overflow-y: auto;
}
.c-card--reveal {
  top: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
