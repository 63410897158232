import TOOLNAMES from './names'
import AbstractHandler from './abstractHandler'

export default class RectangleHandler extends AbstractHandler {
  constructor() {
    super()
    this.name = TOOLNAMES.rectangle
  }

  // manage context

  getContextAttributes() {
    const attributes = super.getContextAttributes()
    return {
      ...attributes,
      lineJoin: 'miter'
    }
  }

  setContextAttributes(options) {
    super.setContextAttributes(options)
    for (const ctx of [this.mainContext, this.tempContext]) {
      ctx.lineJoin = 'miter'
    }
  }

  // manage drawing

  mousedown(e) {
    super.mousedown(e)
  }

  mousemove(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)
    this.draw(this.tempContext, this.x, this.y, newX, newY)
  }

  mouseup(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.isEraseMode
      ? this.erase(this.mainContext, this.x, this.y, newX, newY)
      : this.draw(this.mainContext, this.x, this.y, newX, newY)
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)

    return {
      tool: this.name,
      attributes: this.getContextAttributes(this.options),
      segments: [[this.x, this.y, newX, newY]]
    }
  }

  draw(ctx, x1, y1, x2, y2) {
    if (this.isEraseMode) {
      ctx.fillStyle = 'rgba(255,192,203, 0.4)'
      ctx.fillRect(x1, y1, x2 - x1, y2 - y1)
    } else {
      ctx.strokeRect(x1, y1, x2 - x1, y2 - y1)
    }
  }

  erase(ctx, x1, y1, x2, y2) {
    ctx.clearRect(x1, y1, x2 - x1, y2 - y1)
  }

  redraw({ ctx, segments, fill }) {
    const x1 = segments[0][0]
    const y1 = segments[0][1]
    const x2 = segments[0][2]
    const y2 = segments[0][3]

    fill ? ctx.fillRect(x1, y1, x2 - x1, y2 - y1) : ctx.strokeRect(x1, y1, x2 - x1, y2 - y1)
  }
}

export const rectangleHandler = new RectangleHandler()
