export default {
  activePortal(state, getters, rootState) {
    return rootState.portalStore.activePortal
  },

  areChannelsPrimed(state) {
    return state.primed
  },

  areChannelsLoading(state) {
    return state.loading == undefined ? false : state.loading
  },

  getChannel(state) {
    return (channelSlug) => state.channels.find((channel) => channel.slug === channelSlug)
  }
}
