import VueI18n from 'vue-i18n'
import Vue from 'vue'
import Vuex from 'vuex' // Vue plugin

import { loadMessages } from '@/locales/utils.js'
import appConfig from '@/config/appConfig.js'
import defaultCustomMessages from '@/locales/en.json'
import defaultVuetifyMessages from 'vuetify/lib/locale/en.js'

const locale = appConfig.defaultLocale
const getMessages = (locale) => {
  if (locale === 'en') {
    return { ...defaultCustomMessages, $vuetify: defaultVuetifyMessages }
  } else {
    return (async (locale) => await loadMessages(locale))(locale)
  }
}
const messages = getMessages(locale)

Vue.use(VueI18n) // defines Vue.prototype.$i18n, Vue.prototype.$t

// must call Vue.use(VueI18n) *before* calling new VueI18n()
export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: { [locale]: messages }
})

export class LocalePlugin {
  static install(Vue, { store: _store }) {
    // i18n is a VueI8n object
    Vue.prototype.$_i18n = i18n
    Vuex.Store.prototype.$_i18n = i18n
  }
}
