var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "card",
      staticClass:
        "c-quiz-card d-flex flex-column justify-start align-center mx-auto",
    },
    [
      _c("QuizToolbar", {
        attrs: {
          current: _vm.currentQuestion,
          questions: _vm.quiz.questions,
          "show-progress": _vm.isActive,
          title: _vm.title || _vm.quiz.title,
        },
        on: {
          select: function ($event) {
            _vm.currentQuestion = $event
          },
        },
      }),
      _vm.hasDescription
        ? _c(
            "v-card-text",
            [
              _c("ClampedText", [
                _vm._v(" " + _vm._s(_vm.quiz.description) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.quizLength > 1
        ? _c("QuizProgressCard", {
            staticClass: "mb-4",
            attrs: {
              questions: _vm.quizLength,
              answers: _vm.quizLength - _vm.unanswered(),
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "c-transition" },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName, mode: _vm.transitionMode } },
            [
              _c(
                "div",
                { key: _vm.currentQuestion, staticClass: "c-carousel" },
                [
                  _vm.showPrologue
                    ? _c("QuizPrologueCard", {
                        attrs: { quiz: _vm.quiz },
                        on: {
                          start: function ($event) {
                            return _vm.start()
                          },
                        },
                      })
                    : _vm.isActive
                    ? _c("QuizQuestionCard", {
                        key: _vm.currentQuestion,
                        staticClass: "c-question-card g-skinny-scrollbars",
                        attrs: {
                          qno: _vm.currentQuestion + 1,
                          question:
                            _vm.quiz.questions[_vm.currentQuestion].question,
                          choices:
                            _vm.quiz.questions[_vm.currentQuestion].responses,
                          answer: _vm.userResponses[_vm.currentQuestion],
                          first: _vm.isFirst,
                          last: _vm.currentQuestion === _vm.quizLength - 1,
                          review: _vm.isReview,
                        },
                        on: {
                          next: function ($event) {
                            return _vm.goForward($event)
                          },
                          prev: function ($event) {
                            return _vm.goBack($event)
                          },
                          exit: function ($event) {
                            _vm.isReview = false
                          },
                        },
                      })
                    : _c("QuizResultCard", {
                        staticClass: "c-result-card",
                        attrs: {
                          quiz: _vm.quiz,
                          references: _vm.references,
                          source: _vm.source,
                          "user-responses": _vm.userResponses,
                        },
                        on: {
                          retake: function ($event) {
                            return _vm.onRetake()
                          },
                          review: function ($event) {
                            return _vm.onReview()
                          },
                        },
                      }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }