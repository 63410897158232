<template>
  <v-dialog
    v-model="showSettings"
    content-class="c-settings-dialog"
    eager
    :fullscreen="$vuetify.breakpoint.xsAndDown"
    overlay-opacity="0.2"
    transition="scale-transition"
    width="80%"
    max-width="600px"
    min-width="300px"
    @click:outside="closeSettings"
    @keydown.esc="closeSettings"
  >
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img :src="$auth.getUserAvatar()" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $auth.getUserDisplayName() }}</v-list-item-title>
            <v-list-item-subtitle>{{ $auth.getUserEmail() }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn :class="{ 'red--text': isFavourite }" icon @click="isFavourite = !isFavourite">
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="tooltips" inset color="success" />
          </v-list-item-action>
          <v-list-item-title>{{ $t('settings.tooltips') }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="hints" inset color="success" />
          </v-list-item-action>
          <v-list-item-title>{{ $t('settings.connect') }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="cancelSettings">
          {{ $t('ui.cancel') }}
        </v-btn>
        <v-btn color="primary" text @click="saveSettings">
          {{ $t('ui.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AccountSettings',

  components: {},

  model: {
    prop: 'show',
    event: 'close'
  },

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      isFavourite: true,
      showSettings: false,
      message: false,
      hints: true,
      tooltips: false
    }
  },

  computed: {},

  watch: {
    show: {
      immediate: false, // initial response handled via data()
      handler: function (newValue, _oldValue) {
        this.showSettings = newValue
      }
    }
  },

  mounted: function () {
    this.showSettings = this.show
  },

  methods: {
    cancelSettings() {
      this.closeSettings()
    },

    saveSettings() {
      // write settings to both Vuex and Mongo user registry
      this.closeSettings()
    },

    closeSettings() {
      this.showSettings = false
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="css" scoped>
.c-settings-dialog {
  border-color: var(--v-accent-base);
  border-style: solid;
}
</style>
