var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-source-card pt-4 px-4", attrs: { elevation: "4" } },
    [
      _c("v-card-title", { staticClass: "text-h6 text-left pa-0 pb-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("quiz.source")) + " "),
      ]),
      _c(
        "v-card-text",
        [_c("ReferenceCard", { attrs: { reference: _vm.source } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }