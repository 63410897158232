<template>
  <ThreeColumnLayout
    class="c-content-layout g-skinny-scrollbars"
    :header="showTopAds"
    :left="showLeftPanel"
    :right="showSideAds"
    :fullscreen="isFullscreen"
    :top="fullscreen ? 0 : top"
    :scroll="true"
  >
    <!-- header -->
    <template #header>
      <AdLeaderboard
        v-show="showAds"
        v-model="showTopAds"
        :hero="isArticle"
        @close="showAds = false"
      />
    </template>

    <!-- left side -->
    <template #left>
      <PeopleWidget
        v-if="hasAuthors"
        :people="sortedAuthors"
        :title="$t('content.authors') + '/' + $t('content.advisors')"
      />
      <QuizWidget
        v-if="hasQuiz"
        :qid="quizId"
      />
    </template>

    <!-- middle -->
    <template #middle>
      <slot></slot>
    </template>

    <!-- right side -->
    <template #right>
      <AdWidget
        v-show="showAds"
        v-model="showSideAds"
        zone="side"
        @empty="showSideAds = false"
        @close="showAds = false"
      />
    </template>
  </ThreeColumnLayout>
</template>

<script>
import AdLeaderboard from '@/components/ad/AdLeaderboard'
import AdWidget from '@/components/ad/AdWidget'
import PeopleWidget from '@/components/person/PeopleWidget'
import QuizWidget from '@/components/quiz/QuizWidget'
import ThreeColumnLayout from '@/components/base/ThreeColumnLayout'
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'ContentLayout',

  components: {
    AdLeaderboard,
    AdWidget,
    PeopleWidget,
    QuizWidget,
    ThreeColumnLayout
  },

  mixins: [mobileMixin],

  props: {
    adFree: {
      type: Boolean,
      required: false,
      default: false
    },

    authors: {
      type: Array,
      required: false,
      default: () => []
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Array,
      required: false,
      default: () => []
    },

    top: {
      type: Number,
      required: false,
      default: 48
    },

    type: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      // ads
      noAds: false,
      showAds: true,
      showHeroAds: true,
      showSideAds: false,
      showTopAds: false,
      showLeftPanel: false,
      // widgets
      showAuthors: true,
      showQuiz: true
    }
  },

  computed: {
    isArticle() {
      return this.type === 'article'
    },

    isViewportLarge() {
      return this.mobileMixin_isViewportLarge
    },

    isViewportMedium() {
      return this.mobileMixin_isViewportMedium
    },

    isLandscape() {
      return this.mobileMixin_isLandscape
    },

    isFullscreen() {
      return this.fullscreen
    },

    /* widgets */

    sortedAuthors() {
      return this.authors.slice().sort((a, b) => a.lastName > b.lastName)
    },

    hasAuthors() {
      return this.authors && this.authors.length > 0
    },

    quizId() {
      return this.params?.find((param) => param.name === 'quiz')?.value || ''
    },

    hasQuiz() {
      return !!this.quizId
    }
  },

  watch: {
    isLandscape: {
      immediate: true,
      handler: function (isLandscape, _oldValue) {
        // show side ads when landscape mode
        this.showSideAds = this.showAds && !this.adFree && isLandscape
        // show top ads when portrait mode
        this.showTopAds = this.showAds && !this.adFree && (!isLandscape || this.isArticle)
      }
    }
  },

  created: function () {
    // ThreeColumnLayout hides left panel when width < 1080px
    this.showLeftPanel = this.isArticle
  }
}
</script>

<style lang="css" scoped>
.c-content-layout {
  --c-content-viewport-height: calc(var(--c-viewport-height) - var(--c-header-height));
}
.c-side-ad {
}
.c-side-ad.c-fullscreen {
  display: none;
}
</style>
