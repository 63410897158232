<template>
    <v-list rounded>
      <v-subheader class="c-header">
        {{ $t('model.layers').toUpperCase() }}
      </v-subheader>
      <v-list-item-group
        v-model="selectedLayerIds"
        class="c-list-item-group"
        active-class=""
        multiple
        @change="$emit('sync', selectedLayerIds)"
      >
        <v-list-item
          v-for="layer in layers"
          :key="layer.id"
          dense
          :value="layer.id"
          @click.stop="$emit('select', layer.id)"
        >
          <template #default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ layer.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
  name: 'LayerList',

  components: {},

  model: {
    prop: 'ids',
    event: 'sync'
  },

  props: {
    ids: {
      type: Array,
      required: true
    },

    layers: {
      type: Array,
      required: true
    }
  },

  data: function () {
    return {
      selectedLayerIds: [...this.ids]
    }
  },

  computed: {
    locale() {
      return this.$store.state.i18nStore.locale
    }
  },

  watch: {
    ids: {
      immediate: false,
      handler: function (newIds, _oldIds) {
        if (newIds.length > 0) this.selectedLayerIds = [...newIds]
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-header {
  font-weight: 600;
}

.c-list-item-group.theme--light {
  background-color: white;
}
.c-list-item-group.theme--dark {
  background-color: black;
}
.c-list-item--active {
  background-color: var(--v-accent-base);
}
</style>
