var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-app-update" },
    [
      _c(
        "v-snackbar",
        {
          staticClass: "c-snack",
          attrs: {
            "content-class": "c-snack__content",
            app: "",
            bottom: "",
            right: "",
            dark: _vm.isDark,
            light: !_vm.isDark,
            timeout: -1,
            value: _vm.updateAvailable,
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { fab: "", small: "" },
                        on: { click: _vm.activateUpdate },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _c(
                        "v-icon",
                        { staticClass: "c-icon", attrs: { color: "accent" } },
                        [_vm._v(" mdi-cellphone-arrow-down ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" " + _vm._s(_vm.$t("app.update")) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "c-snack",
          attrs: {
            "content-class": "c-snack__content",
            app: "",
            bottom: "",
            right: "",
            dark: _vm.isDark,
            light: !_vm.isDark,
            timeout: -1,
            value: _vm.reloadRequired,
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { fab: "", small: "" },
                        on: { click: _vm.reloadApp },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _c(
                        "v-icon",
                        { staticClass: "c-icon", attrs: { color: "accent" } },
                        [_vm._v(" mdi-reload-alert ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" " + _vm._s(_vm.$t("app.reload")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }