<template>
  <v-card-title class="justify-end mb-n5">
    <v-btn icon color="yellow" large @click="toggleFavourite()">
      <v-icon>
        {{ isFavourite ? 'mdi-star' : 'mdi-star-outline' }}
      </v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: 'ClientFavourite',

  components: {},

  props: {
    favourite: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {
      isFavourite: false
    }
  },

  computed: {},

  created: function () {
    this.isFavourite = this.favourite
  },

  methods: {
    toggleFavourite() {
      this.isFavourite = !this.isFavourite
      this.isFavourite ? this.$emit('add:favourite') : this.$emit('remove:favourite')
    }
  }
}
</script>

<style lang="css" scoped></style>
