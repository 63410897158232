import TOOLNAMES from './names'
import AbstractHandler from './abstractHandler'

export default class LineHandler extends AbstractHandler {
  constructor() {
    super()
    this.name = TOOLNAMES.line
  }

  // manage context

  getContextAttributes() {
    const attributes = super.getContextAttributes()
    return {
      ...attributes,
      lineCap: 'butt'
    }
  }

  setContextAttributes(options) {
    super.setContextAttributes(options)
    for (const ctx of [this.mainContext, this.tempContext]) {
      ctx.lineCap = 'butt'
    }
  }

  // manage drawing

  mousedown(e) {
    super.mousedown(e)
  }

  mousemove(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)
    this.draw(this.tempContext, this.x, this.y, newX, newY)
  }

  mouseup(e) {
    const { newX, newY } = this.getCoordinates(e)
    this.isEraseMode
      ? this.erase(this.mainContext, this.x, this.y, newX, newY)
      : this.draw(this.mainContext, this.x, this.y, newX, newY)
    this.tempContext.clearRect(0, 0, this.tempContext.canvas.width, this.tempContext.canvas.height)

    return {
      tool: this.name,
      attributes: this.getContextAttributes(),
      segments: [[this.x, this.y, newX, newY]]
    }
  }

  draw(ctx, x1, y1, x2, y2) {
    this.isEraseMode
      ? (ctx.strokeStyle = 'rgba(255,192,203, 0.4)')
      : (ctx.strokeStyle = this.options.colour)
    this.renderLine(ctx, x1, y1, x2, y2)
  }

  erase(ctx, x1, y1, x2, y2) {
    ctx.globalCompositeOperation = 'destination-out'
    this.renderLine(ctx, x1, y1, x2, y2)
  }

  renderLine(ctx, x1, y1, x2, y2) {
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
    ctx.closePath()
  }

  redraw({ ctx, segments }) {
    const x1 = segments[0][0]
    const y1 = segments[0][1]
    const x2 = segments[0][2]
    const y2 = segments[0][3]

    this.renderLine(ctx, x1, y1, x2, y2)
  }
}

export const lineHandler = new LineHandler()
