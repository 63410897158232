import crypto from 'crypto'
import uuid from 'uuid-random'
import Vue from 'vue'

import authConfig from '@/config/authConfig'
import { AUTH_STYLE_POPUP, AUTH_STYLE_REDIRECT, AUTH_STYLE_SSO } from '@/config/authConfig'
import AuthzService from '@/services/authzService.js'
import { avatarBaseURL } from '@/config/appConfig.js'
import { createAuth0Agent } from '@/services/agents/auth0/auth0Agent.js'
import { createMsalAgent } from '@/services/agents/msal/msalAgent.js'

const EVENT_NAME = '$auth:ready'

// user is defintely not authorized or an error prevented verification
class AuthnService {
  constructor() {
    // initialize agent
    this.agent = null
    this.ready = false
    this.error = null
    this.ok = false
    this.timer = null

    // initialize services
    this.authzService = new AuthzService()
    this.$bus = new Vue()
    this.$t = (msg, ...args) => Vue.prototype.$_i18n.t(msg, ...args)

    // setup authentication state
    this.authStyle = authConfig.authStyle
    this.user = null

    // tokens (all are JWT-based)
    this.accessToken = null // from IdP (to call /userinfo endpoint)
    this.idToken = null // from IdP
    this.idTokenClaims = {}
  }

  /*
   * Service Management Methods
   */

  async initialize(tenant) {
    console.debug('[authnService]: Initialization begins...')

    this.ready = false
    this.ok = false
    this.tenant = tenant

    try {
      // initialize the authorization service
      await this.authzService.initialize(tenant)

      // initialize the authentication agent
      const dynamicConfig = {
        authorizationParams: {
          domainHint: tenant.domain,
          scopes: authConfig.scopes
        }
      }

      this.agent =
        authConfig.authAgent === 'msal'
          ? await createMsalAgent(dynamicConfig)
          : await createAuth0Agent(dynamicConfig)

      this.ok = true
      return true
    } catch (e) {
      console.error('[authnService]: Unable to initialize.', e)
      this.error = e.message
      this.ok = false
      throw e
    } finally {
      this.ready = true
      this.$bus.$emit(EVENT_NAME, this.ok)
      console.debug('[authnService]: Initialization complete! ok=', this.ok)
    }
  }

  isReady() {
    return this.ready
  }

  isOK() {
    return this.ok
  }

  getError() {
    return this.error
  }

  async waitUntilReady() {
    return new Promise((resolve, reject) => {
      // check before using a timer
      if (this.ready) {
        this.ok ? resolve(true) : reject(new Error(this.error))
      }

      // start a timer (which is cancelled when $auth is ready)
      const timeout = 15000 // 15 seconds
      this.timer = setTimeout(() => {
        // if the timeout occurs, then $auth took too long to initialize
        const errMsg = '[authnService]: Timed out waiting for $auth to be ready!'
        // remove the event bus listener (stop waiting for $auth to be ready)
        this.$bus.$off(EVENT_NAME)
        // final check before timeout
        if (this.ready) {
          // check whether initialization succeeded
          this.ok ? resolve(true) : reject(new Error(this.error))
        } else {
          // reject the promise (because $auth took too long)
          reject(new Error(errMsg))
        }
      }, timeout)

      // declare the event bus listener (that runs if the EVENT_NAME event is received)
      const onAuthReady = (_event) => {
        // clear the timer
        clearTimeout(this.timer)
        // remove the event bus listener
        this.$bus.$off(EVENT_NAME)
        // resolve the promise (because $auth is ready!)
        this.ok ? resolve(true) : reject(new Error(this.error))
      }

      // wait for $auth to be ready (and clear the timer/bus when it is)
      this.ready ? onAuthReady() : this.$bus.$on(EVENT_NAME, onAuthReady)
    })
  }

  cancel(release) {
    // cancel timeout timer (so no timeout error occurs)
    if (this.timer) {
      clearTimeout(this.timer)
    }

    // optionally, artificially wakeup the service
    if (release) {
      this.isReady = true
      this.ok = false
      this.error = '[authnService]: Cancelled.'

      this.$bus.$emit(EVENT_NAME, this.ok)
    }
  }

  /*
   * Response Handling Methods
   */

  async handleRedirect() {
    const state = await this.agent.handleRedirect()
    return state
  }

  /*
   * SignIn / SignOut Methods
   */

  async signIn(options = {}) {
    let retry = true
    while (retry) {
      if (this.isSSOLogin()) {
        options.prompt = 'none'
        retry = await this.agent.loginWithSSO(options)
      } else if (this.isPopupLogin()) {
        options.prompt = 'select_account'
        retry = await this.agent.loginWithPopup(options)
      } else {
        options.prompt = 'select_account'
        retry = await this.agent.loginWithRedirect(options)
      }
    }
  }

  async signOut(options = {}) {
    this.authzService.clearAuthorization()
    await this.agent.logout(options)
  }

  isAuthenticated() {
    return this.agent.isAuthenticated()
  }

  async authenticate({ locale, loginParams, redirectURI }) {
    // ensure $auth is initialized (see authPlugin)
    console.log('[authnService]: waiting for agent to be be ready...')
    await this.waitUntilReady()

    // authenticate if necessary
    console.debug('[authnService]: Checking authentication...', this.isAuthenticated())
    if (!this.isAuthenticated()) {
      const options = {
        state: {
          redirectURI: redirectURI || '/'
        },
        prompt: loginParams?.prompt || 'none', // likely overriden by login methods
        loginHint: loginParams?.login_hint || '',
        domainHint: loginParams?.domain_hint || this.tenant.domain,
        locale: locale,
        extraQueryParams: loginParams?.eqp || {} // passed to IdP
      }

      // note: login may result in a redirect, hence processing ends here
      await this.signIn(options)
    }

    return this.getUserId()
  }

  /*
   * Login/Logout Methods
   */

  isRedirectLogin() {
    return this.authStyle === AUTH_STYLE_REDIRECT
  }

  isPopupLogin() {
    return this.authStyle === AUTH_STYLE_POPUP
  }

  isSSOLogin() {
    return this.authStyle === AUTH_STYLE_SSO
  }

  /*
   * User Methods
   */

  getUserId() {
    return this.agent.getUserId()
  }

  getUserEmail() {
    // preserve case as entered by user
    return this.agent.getUserEmail()
  }

  getUserDisplayName() {
    return this.agent.getUserDisplayName() || this.$t('account.guest')
  }

  getUserPicture() {
    return this.agent.getUserPicture()
  }

  getUserAvatar() {
    const email = this.getUserEmail().toLowerCase()
    const hash = crypto.createHash('md5').update(email).digest('hex')
    return `${avatarBaseURL}/${hash}?d=wavatar`
  }

  getUserUniqueId() {
    return this.agent.getUserUniqueId() || uuid()
  }

  getUserAccount() {
    return {
      id: this.getUserUniqueId(),
      userId: this.getUserId(),
      subject: this.getUserSubject(),
      name: this.getUserDisplayName(),
      avatar: this.getUserAvatar(),
      email: this.getUserEmail()
    }
  }

  getUserSubject() {
    return this.agent.getUserSubject()
  }

  /*
   * Token Methods
   */

  async getIdTokenClaims() {
    return this.agent.getIdTokenClaims()
  }

  async acquireTokens(options) {
    return this.agent.acquireTokens(options)
  }

  /*
   * Authorization Methods
   */

  async authorize(userId) {
    await this.authzService.authorize(userId)
    return
  }

  clearAuthorization() {
    this.authzService.clearAuthorization()
  }

  isAuthorized() {
    return this.authzService.isUserAuthorized(this.getUserId())
  }
}

export default AuthnService
export const authService = new AuthnService()
