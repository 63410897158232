<template>
  <SectionPanel
    :colour="colour"
    :title="title"
    :count="channelCount"
    :loading="loading"
  >
    <template #toolbar>
      <LayoutToolbar
        v-model="channelLayout"
        :items="channels"
        :filters="channelFilters"
        :layouts="layouts"
        :sort="channelSortOptionName"
        :sorts="channelExtendedSortOptions"
        @results="channelResultSet = $event"
        @update:layout="updatePreferences({ favouriteChannelsLayout: $event })"
        @update:sort="updatePreferences({ favouriteChannelsSort: $event })"
      />
    </template>
    <CardGrid
      v-if="channelLayout === 'grid'"
      :items="loading ? skeletonChannels : channelResultSet"
    >
      <template #card="props">
        <DraggableNode
          :active="areChannelsDraggable"
          drag-type="channel"
          :identifier="props.item.id"
          @drop="moveChannel({ from: $event.dragId, after: $event.dropId })"
        >
          <ChannelCardSkeleton v-if="loading" />
          <ChannelCard
            v-else
            :channel="props.item"
            @share="$emit('share', props.item)"
            @view:channel="$emit('view:channel', props.item)"
          />
        </DraggableNode>
      </template>
    </CardGrid>
    <CardCarousel
      v-else
      title="home.channel"
      :items="loading ? skeletonChannels : channelResultSet"
      :card-width="300"
    >
      <template #card="props">
        <ChannelCardSkeleton v-if="loading" />
        <ChannelCard
          v-else
          :channel="props.item"
          @share="$emit('share', props.item)"
          @view:channel="$emit('view:channel', props.item)"
        />
      </template>
    </CardCarousel>
  </SectionPanel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// channel components
import ChannelCard from '@/components/channel/ChannelCard'
import ChannelCardSkeleton from '@/components/channel/ChannelCardSkeleton'

// base components
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import DraggableNode from '@/components/base/DraggableNode'
import SectionPanel from '@/components/base/SectionPanel'
import LayoutToolbar from '@/components/base/LayoutToolbar'

import channelMixin from '@/components/channel/channelMixin.js'
import mobileMixin from '@/mixins/mobileMixin.js'
import { dummyChannels } from '@/components/channel/dummyChannels.js'

export default {
  name: 'ChannelPanel',

  components: {
    // channel components
    ChannelCard,
    ChannelCardSkeleton,
    // base components
    CardCarousel,
    CardGrid,
    DraggableNode,
    LayoutToolbar,
    SectionPanel
  },

  mixins: [channelMixin, mobileMixin],

  props: {
    channels: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['carousel']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      channelResultSet: [],
      channelLayout: 'carousel',
      skeletonChannels: dummyChannels().slice(0, 4)
    }
  },

  computed: {
    ...mapGetters('userStore', ['getPreference']),

    areChannelsDraggable() {
      // only allow dragging if all favourites are visible (unfiltered or excluded via search)
      // and the sort order is "as is"
      return (
        this.channelResultSet.length === this.channels.length &&
        this.channelSortOptionName === 'sortByAsIs'
      )
    },

    channelCount() {
      return this.channelResultSet.length
    },

    channelFilters() {
      return this.channelMixin_filters
    },

    channelExtendedSortOptions() {
      return [
        ...this.channelMixin_sorts,
        {
          text: this.$t('ui.sortOptions.sortByAsIs'),
          value: 'sortByAsIs'
        }
      ]
    },

    channelSortOptionName() {
      return this.getPreference('favouriteChannelsSort')
    }
  },

  created: function () {
    this.channelLayout = this.mobileMixin_isReallyMobile
      ? 'carousel'
      : this.getPreference('favouriteChannelsLayout')
  },

  methods: {
    ...mapActions('userStore', ['updatePreferences', 'moveChannel'])
  }
}
</script>

<style lang="css" scoped></style>
