var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-left pl-3" },
    _vm._l(_vm.tags, function (tag, i) {
      return _c(
        "v-chip",
        {
          key: i,
          staticClass: "mr-2",
          attrs: { label: "", color: "grey lighten-1", "text-color": "black" },
        },
        [_vm._v(" " + _vm._s(tag.label) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }