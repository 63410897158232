import store from '@/store'
import { authService } from '@/services/authnService'

export const uploadFile = async (file) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...file, userEmail: authService.getUserEmail() })
    }
    const response = await fetch(`/api/tenant/${tenantKey}/upload`, request)
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[fileService]:', error)
    throw error
  }
}
