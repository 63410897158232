var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DraggableDiv", {
    staticClass: "c-popup c-resizable",
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function ({ start }) {
            return [
              _c(
                "v-toolbar",
                { staticClass: "c-popup-toolbar", attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "c-dragger",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                      nativeOn: {
                        mousedown: function ($event) {
                          return start.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-drag")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "px-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.hasTitle ? _vm.title : _vm.$t("model.info")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "c-close",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:show", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                {
                  staticClass: "c-popup-body g-skinny-scrollbars",
                  attrs: { flat: "" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-2 text-left" },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }